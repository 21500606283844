import { InView } from "react-intersection-observer";

import { serverURL } from "./utils";
import { useUser } from "../context/useuser";

const Tracker = ({ itemtype, id, itemid, children }) => {
  const { user, token } = useUser();

  const seen = () => {
    let url = `${serverURL}/api/itemseen.php?type=${itemtype}&profileid=${user?.id ?? 0}&id=${id ?? itemid}`
    url = url.replace(/#/g, '%23');
    
    // Fire and forget
    fetch(
      url,
      {
        method: "POST",
        headers: { token: token || "", "Content-Type": "application/json" },
        body: JSON.stringify({ profileid: user?.id ?? 0, id }),
      }
    );
  };

  return (
    <InView
      key={id ?? itemid}
      onChange={(inView, entry) => {
        if (inView) {
          seen();
        }
      }}
    >
      {children}
    </InView>
  );
};

export default Tracker;
