import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Form,
  Button,
  InputGroup,
  ButtonGroup,
} from "react-bootstrap";
import { serverURL, imageURL } from "../../components/utils";
import SelectLocationModal from "../../components/selectlocationmodal";
import { useUser } from "../../context/useuser";
import { useToast } from "../../context/usetoast";
import BackButton from "../../components/buttons/backbutton";

const FormItem = (props) => {
  const [pic] = useState("");
  const [selectedFile, setSelectedFile] = useState("");
  const [preview, setPreview] = useState("");
  const [fileData, setFileData] = useState(null);

  const { user, token, isAdmin } = useUser();
  const { item, setItem, pagePath } = props;
  const isMyLocation = pagePath === "#mylocations";
  const { successToast, errorToast } = useToast();

  const [editItem, setEditItem] = useState(
    item ?? {
      altbio: "",
      altimage: "",
      altname: "",
      expires: "",
      lat: "",
      lng: "",
      loctype: pagePath.includes("event") ? 7 : user.accountlevel,
      num: "",
      profileid: user.id,
      subtype: 0,
      anonymous: 0,
    }
  );

  let cansave = true;
  if (pagePath.includes("event")) {
    cansave =
      editItem.altname.length > 0 &&
      // selectedFile &&
      editItem.expires.length > 0;
  }

  const back = () => {
    setItem();
    window.location.hash = pagePath;
  };

  const uploadImage = () => {
    return new Promise((resolve, reject) => {
      let data = {
        userid: 0,
        img: fileData.substring(fileData.indexOf("base64,") + 7),
      };

      fetch(serverURL + "/api/uploadimage.php?pre=LOC", {
        method: "POST",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
          token: token,
        },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then(async (response) => {
          setEditItem({
            ...editItem,
            altimage: response.file,
            image: response.file,
          });
          resolve(response);
        })
        .catch((err) => {
          console.error("News Image Upload error", err);
          reject();
        });
    });
  };

  const encodeImageFileAsURL = (file) => {
    let reader = new FileReader();
    reader.onloadend = function () {
      setFileData(reader.result);
      // console.log('AsDataURL RESULT', reader.result)
    };
    reader.readAsDataURL(file);
  };

  // create a preview as a side effect, whenever selected file is changed
  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);
    encodeImageFileAsURL(selectedFile);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  const onSelectFile = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined);
      return;
    }

    // I've kept this example simple by using the first image instead of multiple
    setSelectedFile(e.target.files[0]);
  };

  const saveItem = async () => {
    let res;
    // If an image was uploaded then save it first
    if (fileData) {
      res = await uploadImage();
    }

    let body = { ...editItem };
    if (res?.file) {
      body.image = res.file;
      body.altimage = res.file;
    }
    let options = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token: token,
      },
    };
    if (editItem.id) {
      options.method = "PUT";
    }
    fetch(
      `${serverURL}/api/myevents/api.php/myevents${
        editItem.id ? `/${editItem.id}` : ""
      }`,
      {
        ...options,
        body: JSON.stringify(body),
      }
    )
      .then(async (resp) => {
        if (resp.status === 200) {
          return resp.text();
        } else {
          let data = await resp.json();
          throw new Error(data?.message || "Unknown error");
        }
      })
      .then((res) => {
        let tempeditItem = { ...editItem };
        if (!editItem.id) {
          tempeditItem.id = res;
          setEditItem(tempeditItem);
        }
        setItem(tempeditItem);
        successToast("Item Saved");
      })
      .catch((err) => {
        console.error("Error saving item", err);
        errorToast("Error saving item." + err || "Unknown error");
      });
  };

  const selectLocation = (position) => {
    setEditItem((editItem) => ({
      ...editItem,
      lat: position.lat.toFixed(3),
      lng: position.lng.toFixed(3),
    }));
  };

  const valid = (fieldname, value) => {
    if (pagePath.includes("event")) {
      if (!value || value.length === 0) {
        return false;
      }
    }
    return true;
  };

  return (
    <Form>
      <Row className="mb-3">
        <Col xs={9}></Col>
        <Col xs={3}>
          <BackButton right="true" onClick={() => back()} />
        </Col>
      </Row>
      {isMyLocation ? (
        <Row className="mb-3">
          <InputGroup>
            <InputGroup.Text id="date-edit">Location Type</InputGroup.Text>
            <Form.Select
              aria-label="Select location type"
              value={editItem.loctype}
              disabled={!isAdmin}
              onChange={(e) => {
                setEditItem((editItem) => ({
                  ...editItem,
                  loctype: e.target.value,
                }));
              }}
            >
              <option value="5">Teacher</option>
              <option value="6">DJ</option>
              <option value="7">Event</option>
              <option value="8">Venue</option>
              <option value="9">Supplier</option>
            </Form.Select>
          </InputGroup>

          {isAdmin && (
            <Form.Text className="foradmins">
              Admin can create locations of any type
            </Form.Text>
          )}
        </Row>
      ) : null}
      <InputGroup className="mb-3">
        <InputGroup.Text id="name-edit">Name</InputGroup.Text>
        <Form.Control
          placeholder="Blank name shows actual name"
          value={editItem.altname}
          className={valid("name", editItem.altname) ? "" : "is-invalid"}
          onChange={(e) => {
            setEditItem((editItem) => ({
              ...editItem,
              altname: e.target.value,
            }));
          }}
        />
      </InputGroup>
      <InputGroup className="mb-3">
        <InputGroup.Text id="date-edit">Expires</InputGroup.Text>
        <Form.Control
          type="date"
          placeholder="Expires"
          value={editItem.expires}
          className={!editItem.expires ? "is-invalid" : ""}
          onChange={(e) => {
            setEditItem((editItem) => ({
              ...editItem,
              expires: e.target.value,
              altdate: e.target.value,
            }));
          }}
        />
      </InputGroup>
      {user.admin >= 1 ? (
        <>
          <InputGroup className="mb-3">
            <InputGroup.Text id="date-edit">Anonymous / Global</InputGroup.Text>
            <Form.Select
              aria-label="Anonymous Event Details"
              value={editItem.anonymous}
              onChange={(e) => {
                setEditItem((editItem) => ({
                  ...editItem,
                  anonymous: e.target.value,
                }));
              }}
            >
              <option value={0}>No</option>
              <option value={1}>Yes</option>
            </Form.Select>
          </InputGroup>
          {!isMyLocation && (
            <InputGroup className="mb-3">
              <InputGroup.Text id="date-edit">Manage Bookings</InputGroup.Text>
              <Form.Select
                aria-label="Should bookings be managed?"
                value={editItem.managebookings}
                onChange={(e) => {
                  setEditItem((editItem) => ({
                    ...editItem,
                    managebookings: e.target.value,
                  }));
                }}
              >
                <option value={0}>No</option>
                <option value={1}>Yes</option>
              </Form.Select>
            </InputGroup>
          )}
        </>
      ) : null}

      <InputGroup className="mb-3">
        <InputGroup.Text id="name-edit">Body</InputGroup.Text>
        <Form.Control
          as="textarea"
          type="text"
          placeholder="Description"
          value={editItem.altbio}
          // className={!editItem.blurb || editItem.blurb === "" ? "is-invalid" : ""}
          onChange={(e) => {
            setEditItem((editItem) => ({
              ...editItem,
              altbio: e.target.value,
            }));
          }}
        />
      </InputGroup>
      <InputGroup className="mb-3">
        <InputGroup.Text id="loc-edit">Location</InputGroup.Text>
        <Form.Control
          type="text"
          placeholder="Latitude"
          value={editItem.lat || ""}
          // className={!editItem.editItemlat || editItem.editItemlat === "" ? "is-invalid" : ""}
          onChange={(e) => {
            setEditItem((editItem) => ({
              ...editItem,
              lat: e.target.value,
            }));
          }}
        />
        <Form.Control
          type="text"
          placeholder="Longitude"
          value={editItem.lng || ""}
          // className={!editItem.editItemlng || editItem.editItemlng === "" ? "is-invalid" : ""}
          onChange={(e) => {
            setEditItem((editItem) => ({
              ...editItem,
              lng: e.target.value,
            }));
          }}
        />
        <SelectLocationModal onSelectLocation={selectLocation} />
      </InputGroup>

      <InputGroup>
        <Form.Group as={Col} md={6} controlId="formFile" className="mb-3">
          <Form.Label>Upload an image</Form.Label>
          <Form.Control
            type="file"
            // className={
            //   selectedFile === "" && !editItem.image ? "is-invalid" : ""
            // }
            // className={valid("image", selectedFile) ? "" : "is-invalid"}
            value={""}
            onChange={onSelectFile}
            accept="image/png,image/jpeg,video/mp4,video/x-m4v,video/*"
          />
        </Form.Group>
        <Form.Group as={Col} md={5} controlId="formFile" className="mb-3 ms-3">
          {selectedFile ? (
            <>
              <Form.Label>Preview</Form.Label>
              <br />
              <Form.Label>
                <img
                  alt="User Profle Preview"
                  style={{ minWidth: "50%", maxWidth: "75%" }}
                  src={preview}
                />
              </Form.Label>
            </>
          ) : pic ? (
            <>
              <Form.Label>Preview</Form.Label>
              <br />
              <Form.Label>
                <img
                  alt="Preview"
                  style={{ minWidth: "50%", maxWidth: "75%" }}
                  src={`${imageURL}${pic}`}
                />
              </Form.Label>
            </>
          ) : editItem.image ? (
            <>
              <Form.Label>Preview</Form.Label>
              <br />
              <Form.Label>
                <img
                  alt="Preview"
                  style={{ minWidth: "50%", maxWidth: "75%" }}
                  src={`${imageURL}${editItem.image}`}
                />
              </Form.Label>
            </>
          ) : (
            <div>No Image Available, {pic}</div>
          )}
        </Form.Group>
      </InputGroup>
      <ButtonGroup>
        <Button variant="secondary" onClick={() => setItem(null)}>
          Close
        </Button>
        <Button
          variant="primary"
          onClick={() => saveItem()}
          disabled={!cansave}
        >
          Save
        </Button>
      </ButtonGroup>
    </Form>
  );
};

export default FormItem;
