import React from "react";
import { Button, OverlayTrigger, Popover } from "react-bootstrap";
import { JuztDance } from "../../components/utils";

const DutchRevolution = () => {
  return (
    <div
      style={{
        marginTop: "30px",
        borderRadius: "20px",
        border: "1px solid purple",
        padding: "20px",
      }}
    >
      <span>
        You qualify for the <JuztDance /> rate!! R500 for a FULL pass.{" "}
      </span>
      <OverlayTrigger
        trigger="click"
        placement="top"
        overlay={
          <Popover
            id="popover-positioned-top"
            style={{ background: "lightgreen" }}
          >
            <Popover.Header
              as="h3"
              style={{ background: "green", color: "white" }}
            >
              Buy Tickets
            </Popover.Header>
            <Popover.Body style={{ fontSize: "16px" }}>
              Bank details.
              <br />
              Bank: FNB
              <br />
              Name: Durban Red Salsa
              <br />
              Account number: 62756699347
              <br />
              Branch name: Overport City
              <br />
              Branch code: 222826
              <br />
              <br />
              Price R500 per person. Please use "J.D + your name" as your
              reference when you do an EFT/cash deposit
            </Popover.Body>
          </Popover>
        }
      >
        <Button variant="primary">Buy Tickets</Button>
      </OverlayTrigger>
    </div>
  );
};

export default DutchRevolution;
