import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { Map } from "react-bootstrap-icons";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  useMapEvents,
} from "react-leaflet";
import { useUser } from "../context/useuser";
import MapBar from "./mapbar";

/*
# USAGE

  <SelectLocationModal onSelectLocation={selectLocation}/>

  const selectLocation = (position) => {
    setTeacherToEdit((teacher) => ({
      ...teacher,
      teacherlat: position.lat.toFixed(3),
      teacherlng: position.lng.toFixed(3),
    }));
  }
*/

const SelectLocationModal = ({ onSelectLocation }) => {
  const [show, setShow] = useState(false);

  const { user } = useUser();
  const [position, setPosition] = useState([user.teacherlat ?? user.lat, user.teacherlng ?? user.lng]);
  const [center, setCenter] = useState([user.teacherlat ?? user.lat, user.teacherlng ?? user.lng]);

  const [map, setMap] = useState(null);

  useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        setCenter([position.coords.latitude, position.coords.longitude]);
      });
    }
  }, []);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleClick = (e) => {
    // console.log("**** Handleclick", e.latlng)
    setPosition(e.latlng);
  };

  const selectLocation = () => {
    if (onSelectLocation) {
      onSelectLocation(position);
      setShow(false);
    }
  };

  const LocationFinder = () => {
    useMapEvents({
      click(e) {
        // console.log(e.latlng);
        handleClick(e);
      },
    });
    return null;
  };

  const goHome = () => {
    map.flyTo([user.lat, user.lng]);
  };
  const goFound = (latlng) => {
    console.log("goFound", latlng)
    map.flyTo(latlng);
  };

  const goHere = () => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(function (position) {
        map.flyTo([position.coords.latitude, position.coords.longitude]);
      });
    }
  };

  return (
    <>
      <Button variant="outline-primary" onClick={handleShow}>
        <Map />
      </Button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton closeVariant="white">
          <Modal.Title>Select Location</Modal.Title>
        </Modal.Header>

        <Modal.Body style={{ height: "100%", width: "100%" }}>
          <MapBar goHome={goHome} goHere={goHere} allowSearch={true} allowFindPins={false} foundLocation={goFound} startOpen={true} />
          <MapContainer
            center={center}
            zoom={13}
            scrollWheelZoom={true}
            whenCreated={setMap}
          >
            <LocationFinder />
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {position && (
              <Marker position={position}>
                <Popup>Select this location.</Popup>
              </Marker>
            )}
          </MapContainer>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={selectLocation}>
            Select
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SelectLocationModal;
