import { Row, Col, Container } from "react-bootstrap";
import { JuztDance, Dance2 } from "../components/utils"

const SplashPage = () => {
    return (
        <Container>
        <Row className="align-items-center">
            <Col md={3} />
            <Col md={6} className="justify-content-md-center">
                <Dance2 color="purple"  />
            </Col>
            <Col md={3} />
        </Row>
        <Row className="align-items-center">
            <Col md={5} xs={3}/>
            <Col md={2} xs={6}>
                <div style={{ margin:"0 auto", textAlign:"center",fontSize:"2rem"}}>
                <JuztDance />
                </div>
            </Col>
            <Col md={5} xs={3} />
        </Row>
        <Row className="align-items-center">
            <Col md={3} xs={2}/>
            <Col md={6} xs={8}>
                <div style={{ margin:"0 auto", textAlign:"center",fontSize:"1.5rem"}}>
                Never be without your dance fix again!
                </div>
            </Col>
            <Col md={3} xs={2} />
        </Row>
        <div className="align-items-center" style={{position:"absolute",bottom:"10px", left: "0px", right:"0px"}}>
            {process.env.REACT_APP_VERSION}
        </div>
        </Container>
    )
}

export default SplashPage;