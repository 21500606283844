import React from "react";
import { Button } from "react-bootstrap";
import { ArrowLeftSquare } from "react-bootstrap-icons";
import useIsMobile from "../../hooks/useismobile";

const BackButton = ({ onClick, right = "false" }) => {
  const isMobile = useIsMobile();
  const style = {};
  if (right === "true") {
    style.float = "right";
  }
  return (
    <Button style={style} variant="outline-primary" onClick={onClick}>
      {isMobile ? <ArrowLeftSquare /> : <>Back</>}
    </Button>
  );
};

export default BackButton;
