import React, { useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import Form from "react-bootstrap/Form";
import useData from "../hooks/usedata";
import "./userselect.scss";
import { serverURL } from "./utils";
import { CaretDown } from "react-bootstrap-icons";

// The forwardRef is important!!
// Dropdown needs access to the DOM node in order to position the Menu
const CustomToggle = React.forwardRef(
  ({ children, onClick, ...props }, ref) => (
    <div
      style={{ flex: "1 1 auto" }}
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </div>
  )
);

// forwardRef again here!
// Dropdown needs access to the DOM of the Menu to measure it
const CustomMenu = React.forwardRef(
  ({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
    const [value, setValue] = useState("");

    return (
      <span
        ref={ref}
        style={style}
        className={className}
        aria-labelledby={labeledBy}
      >
        <Form.Control
          autoFocus
          className="mx-3 my-2 w-auto"
          placeholder="Type to filter..."
          onChange={(e) => setValue(e.target.value.toLowerCase())}
          value={value}
        />
        <ul className="list-unstyled">
          {React.Children.toArray(children).filter(
            (child) =>
              !value || child.props.children?.toLowerCase().includes(value)
          )}
        </ul>
      </span>
    );
  }
);

const UserSelect = ({ item, onSelect, className }) => {
  const users = useData(`${serverURL}/api/users/api.php/users`, [
    "username",
    "id",
  ]);
  console.log("ITEM", item);
  return (
    <Dropdown className="form-control">
      <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
        <div className={`${className} selectvalue form-control`}>
          {item?.name ?? "Select a User"}
          <div style={{float:"right"}}>
          <CaretDown />
          </div>
        </div>
      </Dropdown.Toggle>

      <Dropdown.Menu as={CustomMenu}>
        {users.data.map((user) => (
          <Dropdown.Item
            key={user.id}
            eventKey={user.id}
            onClick={() => onSelect(user.id, user.name)}
          >
            {user.name}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default UserSelect;
