import { useEffect, useContext } from "react";
import { Navbar, Container, Nav,  Offcanvas, CloseButton } from "react-bootstrap";
import TeacherProfile from "./profile";
import TeacherOfferings from "./offerings";

import LocationSelection from "./location";
import TeacherRefer from "./refer";
import { Shoe2 } from "../components/utils";
import { AuthenticationContext } from "../context/authentication";
import TeacherSubs from "./subscriptions/subs";
import TeacherReport from "./report";


function Teacher({ page, params }) {
	const { user } = useContext(AuthenticationContext);

	useEffect(() => {
		if (!user) {
			window.location.hash = "login";
		}
	}, [user]);

	return (
		<Offcanvas placement={"bottom"} className='location' show={true} style={{height:"90vh"}}>
			<Offcanvas.Header style={{ padding: "0rem", paddingRight: "1rem", borderBottom: "1px solid lightgrey" }}>
				<Navbar collapseOnSelect expand='lg' bg='primary' variant='dark'>
					<Container>
						<Navbar.Brand href='#home'>
							{page === "#profile" ? (
								<h3>
									<Shoe2 width='45px' fill='white' /> <span className='d-lg-none'>Profile</span>
								</h3>
							) : null}
							{page === "#profile/profile" ? (
								<h3>
									<Shoe2 width='45px' fill='white' /> <span className='d-lg-none'>Profile</span>
								</h3>
							) : null}
							{page === "#profile/offering" ? (
								<h3>
									<Shoe2 width='45px' fill='white' /> <span className='d-lg-none'>Offerings</span>
								</h3>
							) : null}
							{page === "#profile/subs" ? (
								<h3>
									<Shoe2 width='45px' fill='white' /> <span className='d-lg-none'>Subscription</span>
								</h3>
							) : null}
							{page === "#profile/location" ? (
								<h3>
									<Shoe2 width='45px' fill='white' /> <span className='d-lg-none'>Location</span>
								</h3>
							) : null}
							{page === "#profile/refer" ? (
								<h3>
									<Shoe2 width='45px' fill='white' /> <span className='d-lg-none'>Referral</span>
								</h3>
							) : null}
							{page === "#profile/addnews" ? (
								<h3>
									<Shoe2 width='45px' fill='white' /> <span className='d-lg-none'>Add News</span>
								</h3>
							) : null}
						</Navbar.Brand>
						<Navbar.Toggle aria-controls='responsive-navbar-nav' />
						<Navbar.Collapse id='responsive-navbar-nav'>
							<Nav className='me-auto'>
								<Nav.Link href='#profile/profile'>Profile</Nav.Link>
								<Nav.Link href='#profile/location'>Location</Nav.Link>
								{user && user.accountlevel >= 5 ? (
									<>
									<Nav.Link href='#profile/offering'>What I teach/offer</Nav.Link>
									<Nav.Link href='#profile/subs'>Subscription</Nav.Link>
								</>
								) : null }
								<Nav.Link href='#profile/refer'>Refer</Nav.Link>
								<Nav.Link href='#profile/report'>Report</Nav.Link>
								
							</Nav>
						</Navbar.Collapse>
					</Container>
				</Navbar>
				{/* <PatchQuestionFill id="profilehelp" style={{ width: "1.5em", height: "1.5em", marginLeft: "1em", float: "right", position:"absolute", right:"50px", cursor:"pointer" }} onClick={()=>openTour("Profile")}/> */}
			
				<CloseButton variant='white' onClick={() => (window.location.hash = "#")} />
			</Offcanvas.Header>
			<Offcanvas.Body>
				{page === "#profile" ? <TeacherProfile {...params} show={true} /> : null}
				{page === "#profile/profile" ? <TeacherProfile {...params} show={true} /> : null}
				{page === "#profile/offering" ? <TeacherOfferings {...params} show={true} /> : null}
				{page === "#profile/subs" ? <TeacherSubs {...params} show={true} /> : null}
				{page === "#profile/location" ? <LocationSelection {...params} show={true} /> : null}
				{page === "#profile/refer" ? <TeacherRefer {...params} show={true} /> : null}
				{page === "#profile/report" ? <TeacherReport {...params} show={true} /> : null}
			</Offcanvas.Body>
		</Offcanvas>
	);
}

export default Teacher;
