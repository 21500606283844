import { useState, createContext, useEffect } from "react";
import { forceLog, serverURL } from "../components/utils";

export const AuthenticationContext = createContext();

export const AuthenticationProvider = ({ children }) => {
  const [user, setUser] = useState();
  const [toast, setToast] = useState();
  const [toastVariant, setToastVariant] = useState("success");
  const [token, setToken] = useState();
  const [settings, setSettings] = useState([]);

  useEffect(() => {
    if (user?.id) {
      fetch(
        `${serverURL}/api/settings/api.php/mysettings/${user.id}`
      )
        .then((res) => res.json())
        .then((res) => {
          // console.log("Settings",res)
          setSettings(res);
        });
    }
  }, [user]);

  useEffect(() => {
    forceLog("Authcontext.getrememberuser", "");
    const jduser = localStorage.getItem("jduser");
    // console.log("jduser",jduser)
    if (jduser && jduser !== "undefined") {
      forceLog("Authcontext.getrememberuser result", jduser);
      // console.log("set User",JSON.parse(jduser))
      setUser(JSON.parse(jduser));
      // ToDO: Refresh teacher info
      // record the login
      // fetch(serverURL + "/auth/validateToken.php", {
      // 	method: "POST",
      // 	headers: {
      // 		"Accept": "application/json, text/plain, */*",
      // 		"Content-Type": "application/json",
      // 	},
      // 	body: JSON.stringify({"token":JSON.parse(jduser).token}),
      // })
      // .then(data => data.json())
      // .then(data => console.log("Validate Token",data))
    }
  }, [setUser]);

  useEffect(() => {
    if (user) {
      localStorage.setItem("jduser", JSON.stringify(user));
    }
    setToken(user?.token);
  }, [user]);

  const registration = ({
    email,
    password,
    confirm,
    first,
    last,
    refer,
    rtype,
    accountlevel,
    promo,
  }) => {
    // registration done in register.js page
    return new Promise((resolve, reject) => {
      let body = {
        email: email,
        password: password,
        confirm: confirm,
        firstName: first,
        lastName: last,
        refer: refer,
        rtype: rtype,
        accountlevel: accountlevel,
        promo: promo,
      };
      fetch(serverURL + "/auth/registration.php", {
        method: "POST",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.errors) {
            throw res.errors[0];
          }
          // console.log("Registration Data", res);
          setUser(res);
          resolve(res);
        })
        .catch((err) => {
          console.error("Registration Error", err);
          reject(err);
        });
    });
  };

  const login = ({ email, password }) => {
    forceLog("Authcontext.login", email);
    return new Promise((resolve, reject) => {
      //console.log("Login Body", body);
      fetch(`${serverURL}/auth/login.php?email=${email}&password=${password}`)
        .then((res) => res.json())
        .then((res) => {
          if (res.errors) {
            throw res;
          }
          // console.log("Login User",res);
          forceLog("Authcontext.login result", res);
          setUser(res);
          resolve(res);
        })
        .catch((err) => {
          //console.log("ERROR", err);
          reject(err);
        });
    });
  };

  const getTeacher = () => {
    // console.log("Get Teacher", user.id)
    forceLog("Authcontext.getteacher", user.id);
    return new Promise((resolve, reject) => {
      fetch(`${serverURL}/api/getteacher.php?id=${user.id}`)
        .then((res) => res.json())
        .then((res) => {
          // console.log("getTeacher",res)
          if (res.errors) {
            throw res;
          }
          forceLog("Authcontext.getteacher result", res);
          setUser(res);
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  const updateProfile = (params) => {
    let levelChange = user.level !== params.level;
    return new Promise((resolve, reject) => {
      let fields = "";
      fields += "profileid=" + user.id;
      if (params.lat) {
        fields += "&lat=" + params.lat;
      }
      if (params.lng) {
        fields += "&lng=" + params.lng;
      }
      if (params.teacherlat) {
        fields += "&teacherlat=" + params.teacherlat;
      }
      if (params.teacherlng) {
        fields += "&teacherlng=" + params.teacherlng;
      }
      if (params.firstname) {
        fields += "&firstname=" + params.firstname;
      }
      if (params.lastname) {
        fields += "&lastname=" + params.lastname;
      }
      if (params.phonenumber) {
        fields += "&phonenumber=" + params.phonenumber;
      }
      if (params.level) {
        fields += "&level=" + params.level;
      }
      if (params.bio) {
        fields += "&bio=" + params.bio;
      }
      if (params.facebook) {
        fields += "&facebook=" + params.facebook;
      }
      if (params.twitter) {
        fields += "&twitter=" + params.twitter;
      }
      if (params.instagram) {
        fields += "&instagram=" + params.instagram;
      }
      if (params.youtube) {
        fields += "&youtube=" + params.youtube;
      }
      if (params.booking) {
        fields += "&booking=" + params.booking;
      }
      if (params.tiktok) {
        fields += "&tiktok=" + params.tiktok;
      }
      let url = serverURL + "/api/editDancer.php";
      //console.log("Update Profile",url)
      fetch(`${url}?${fields}`)
        .then((res) => res.json())
        .then((res) => {
          if (res.errors) {
            throw res;
          }
          //console.log("EditDance resp",res);
          res = JSON.parse(res);
          // console.log("After save user",res)
          setUser(res);
          if (levelChange) {
            setToast("Your profile type has been updated");
          } else {
            setToast("Your profile has been updated");
          }
          resolve(res);
        })
        .catch((err) => {
          console.error("Update Profile", err);
          window.location.hash = "";
          reject(err);
        });
    });
  };

  const logout = (task) => {
    localStorage.setItem("jduser", "");
    setUser(undefined);
  };

  const forgotPassword = ({ email }) => {
    return new Promise((resolve, reject) => {
      let body = { email: email };
      //console.log("Forgot Body", body);
      fetch(serverURL + "/auth/forgotpassword.php", {
        method: "POST",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      })
        .then((res) => res.json())
        .then((res) => {
          //console.log("Forgot Password done")
          setToast("An email has been sent to your address");
          resolve(res);
        })
        .catch((err) => {
          console.error("Forgot Error", err);
          setToast("Error");
        });
    });
  };

  const forceNewPassword = ({ force, password, confirm }) => {
    return new Promise((resolve, reject) => {
      let body = { force: force, pwd: password, confirm: confirm };
      //console.log("Change password Parameters", body)

      fetch(serverURL + "/auth/forcenewpassword.php", {
        method: "POST",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      })
        .then((res) => res.json())
        .then((res) => {
          //console.log("Forgot Password done");
          setToast("Your Password has been updated");
          resolve();
        })
        .catch((err) => {
          console.error("Cannot change password", err);
          setToast("Error");
          reject();
        });
    });
  };

  return (
    <AuthenticationContext.Provider
      value={{
        user,
        token,
        registration,
        login,
        logout,
        getTeacher,
        updateProfile,
        forgotPassword,
        forceNewPassword,
        toast,
        setToast,
        toastVariant,
        setToastVariant,
        settings,
      }}
    >
      {children}
    </AuthenticationContext.Provider>
  );
};

export default AuthenticationProvider;
