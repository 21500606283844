import React, { useContext } from 'react'
import { AuthenticationContext } from "../context/authentication";
import { serverURL } from "./utils"

const RecordLink = ({ href, teacherid, children }) => {
    const { user } = useContext(AuthenticationContext);

    const recordClick =  (href, teacherid, userid) => {
        fetch(serverURL + `/api/addClick.php?user=${userid}&teacher=${teacherid}&link=${href}`);
    }
    const click = (ev) => {
        ev.preventDefault()
        window.open(href, "_blank");
        recordClick(href,teacherid,user.id);
    }
    return (<span onClick={click}>{children}</span>)
}

export default RecordLink