import React from "react";
import BottomOverlay from "../../components/bottomoverlay";
import ViewQRCode from "./viewqrcode";

const GetQRCode = ({ page, params }) => {
  return (
    <BottomOverlay pageUrl={"viewqrcode"} title="View QR Code" navs={[]}>
      <ViewQRCode />
    </BottomOverlay>
  );
};

export default GetQRCode;
