import { lazy, Suspense, useRef } from "react";
import { Button, Offcanvas, CloseButton, Row, Col } from "react-bootstrap";
import { createLink, imageURL } from "../../components/utils";
import Stars from "../../components/stars";
import Reviews from "../../components/reviews";
import {
  Facebook,
  Instagram,
  Tiktok,
  Twitter,
  Youtube,
} from "react-bootstrap-icons";
import RecordLink from "../../components/recordlink";
import LoveFest from "../specialevents/lovefest";
import DutchRevolution from "../specialevents/dutchrevolution";
import ProfileBadge from "../../components/profilebadge";
import EventBooking from "../pindetails/booking";
import { useUser } from "../../context/useuser";
import { useFeatureFlag } from "../../context/usefeatureflags";
import Tracker from "../../components/tracker";

import Media from "../media";
import FollowButton from "../../components/followbutton";
import MarkdownEditor from "@uiw/react-markdown-editor";

const LastLogin = lazy(() => import("../lastlogin"));

function LocationDetails({ show, handleClose, data }) {
  const { user } = useUser();
  const { isFeatureEnabled } = useFeatureFlag();
  const bookSection = useRef();

  if (!data || !data.name || !data.data) {
    return null;
  }

  data = data.data;
  const itemimage =
    data.altimage && data.altimage !== "" ? data.altimage : data.avatar;

  return (
    <Offcanvas placement={"bottom"} className="location" show={show}>
      <Tracker itemtype="locationDetails" id={data.locid ?? data.id}>
        <Offcanvas.Header style={{ borderBottom: "1px solid lightgrey" }}>
          <Offcanvas.Title>{data.altname ?? data.name}</Offcanvas.Title>
          <CloseButton variant="white" onClick={handleClose} />
        </Offcanvas.Header>
        <Offcanvas.Body style={{ height: "90vh", overflowY: "scroll" }}>
          <Row>
            <Col>
              <ProfileBadge loctype={data.loctype ?? data.accountlevel} />
            </Col>
                <Col>
            {data.loctype === 7 &&
              data.altname &&
              data.altname !== data.name &&
              data.anonymous !== "1" &&
              data.anonymous !== 1 && (
                  <span>Presented by {data.name}</span>
              )}
                  {/* <a href={`#details?id=${data.id}`}>{data.name}</a> */}
                  {data.managebookings > 0 && (
                    <Button
                      onClick={() =>
                        bookSection.current.scrollIntoView({
                          behavior: "smooth",
                          block: "start",
                        })
                      }
                    >
                      Book Now
                    </Button>
                  )}
              
              </Col>
          </Row>

          {data.id === 1241 || data.locid === 1049 ? (
            <LoveFest event={data} />
          ) : (
            <>
              <Row>
                <Col sm={12} md={9} className="mt-3">
                  {/* <Bio info={data.altbio ?? data.teacherbio} /> */}
                  <MarkdownEditor.Markdown
                    source={data.altbio ?? data.teacherbio}
                  />
                </Col>
                <Col sm={12} md={3} className="mt-3">
                  <div className="float-start"><strong>Rated:</strong> <Stars number={data.stars} /></div>
                  <div className="float-end"><FollowButton teacher={data} /></div>
                </Col>
              </Row>

              <Row className="mt-3 mb-3">
                <Col style={{}} sm={6} md={6}>
                  {!!itemimage && (
                    <Media
                      style={{ width: "100%" }}
                      alt={data.header}
                      src={`${imageURL}${itemimage}`}
                    />
                  )}
                </Col>
                <Col>
                  <Row className="pb-3">
                    <Col xs={12} className="mt-3">
                      Phone: {data.phonenumber}
                      <br />
                    </Col>
                    {data.loctype === data.accountlevel && (
                      <Col xs={12} className="mt-3">
                        <strong>Offers</strong>: {data.genre}
                      </Col>
                    )}
                  </Row>

                  {data.facebook ||
                  data.twitter ||
                  data.tiktok ||
                  data.instagram ||
                  data.youtube ? (
                    <Row className="pb-3">
                      <Col xs={12}>My Social Media:</Col>
                    </Row>
                  ) : null}
                  <Row className="pb-3">
                    {data.facebook && (
                      <Col>
                        <RecordLink
                          href={createLink(
                            `https://facebook.com/`,
                            data.facebook
                          )}
                          teacherid={data.id}
                        >
                          <Facebook
                            size={30}
                            style={{
                              marginRight: "10px",
                              color: "purple",
                              cursor: "pointer",
                            }}
                          />
                        </RecordLink>
                      </Col>
                    )}
                    {data.twitter && (
                      <Col>
                        <Twitter
                          size={30}
                          style={{
                            marginRight: "10px",
                            color: "purple",
                            cursor: "pointer",
                          }}
                          onClick={() => window.open(data.twitter)}
                        />
                      </Col>
                    )}
                    {data.tiktok && (
                      <Col>
                        <Tiktok
                          size={30}
                          style={{
                            marginRight: "10px",
                            color: "purple",
                            cursor: "pointer",
                          }}
                          onClick={() => window.open(data.tiktok)}
                        />
                      </Col>
                    )}
                    {data.instagram && (
                      <Col>
                        <Instagram
                          size={30}
                          style={{
                            marginRight: "10px",
                            color: "purple",
                            cursor: "pointer",
                          }}
                          onClick={() => window.open(data.instagram)}
                        />
                      </Col>
                    )}
                    {data.youtube && (
                      <Col>
                        <Youtube
                          size={30}
                          style={{
                            marginRight: "10px",
                            color: "purple",
                            cursor: "pointer",
                          }}
                          onClick={() => window.open(data.youtube)}
                        />
                      </Col>
                    )}
                  </Row>

                  {data.booking && data.managebookings === 99 && (
                    <Row className="pb-3">
                      <Col xs={12}>Make a booking:</Col>
                      <Col xs={12}>
                        <a
                          href={`http://${data.booking}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {data.booking}
                        </a>
                      </Col>
                    </Row>
                  )}

                  <Reviews data={data} />

                  {data.id === 319 ? <DutchRevolution /> : null}
                </Col>
                  
                {![0,"0"].includes(data.managebookings) && (
                  <Tracker itemtype="eventbooking" itemid={data.locid}>
                    <div className="pt-4" style={{ paddingBottom: "50px" }}>
                      <h3 ref={bookSection}>Booking</h3>
                      {user && (
                        <EventBooking event={data} eventid={data.locid} />
                      )}
                      {!user && (
                        <div
                          style={{
                            borderRadius: "10px",
                            border: "2px solid purple",
                          }}
                        >
                          <div>You need to be logged in to make a booking.</div>
                          <Button
                            onClick={() => (window.location.hash = "register")}
                          >
                            Login
                          </Button>
                        </div>
                      )}
                    </div>
                  </Tracker>
                )}
              </Row>
            </>
          )}
          {user?.admin > 0 && isFeatureEnabled("lastlogin") && (
            <Suspense fallback={<></>}>
              <LastLogin data={data} />
            </Suspense>
          )}
        </Offcanvas.Body>
      </Tracker>
    </Offcanvas>
  );
}

export default LocationDetails;
