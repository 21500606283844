export const videoMimeTypes = [
  'video/x-flv',
  'video/x-ms-wmv',
  'video/x-msvideo',
  'video/quicktime',
  'video/3gpp',
  'video/MP2T',
  'application/x-mpegURL',
  'video/mp4',
];

export const imageMimeTypes = [
  'image/webp',
  'image/tiff',
  'image/svg+xml',
  'image/png',
  'image/jpeg',
  'image/vnd.microsoft.icon',
  'image/gif',
  'image/bmp',
];

export const validateExtension = (file, allowedExtensions = []) => {
  return allowedExtensions.includes(file.type);
}

const Media = (props) => {
  const { style, alt, src } = props;

  if (src.includes("mp4")) {
    return (
      <video style={style} controls>
        <source src={src} />
      </video>
    );
  }
  return <img style={style} alt={alt} src={src} />;
};

export default Media;
