import { useState, useEffect, useContext, lazy, Suspense } from "react";
import LocationDetails from "./pages/pindetails/locationDetails";
import LocationListView from "./pages/locationListView";
import DisplayMap from "./components/displayMap";
import Register from "./pages/register";
import Login from "./pages/login";
import Forgot from "./pages/forgot";
import Toasts from "./components/toast";
import View from "./pages/view";
import Force from "./pages/force";
import Teacher from "./teacher/teacher";
import { AuthenticationContext } from "./context/authentication";
import UserDetails from "./pages/userdetails";

import "./App.scss";
import { forceLog, serverURL, isLocalHost } from "./components/utils";
import SplashPage from "./pages/splash";
import DisplayCards from "./components/displayCards";
import MapBar from "./components/mapbar";
import GetQRCode from "./admin/qrcode/getqrcode";
import PullOutTab from "./components/pullouttab";
import { useFeatureFlag } from "./context/usefeatureflags";
import { ExclamationCircle, ExclamationCircleFill, Newspaper } from "react-bootstrap-icons";
import MyItems from "./pages/myitems/items";

const Developer = lazy(() => import("./pages/dev/dev"));
const Admin = lazy(() => import("./admin/admin"));

const MyNews = lazy(() => import("./pages/mynews/mynews"));
const MyEvents = lazy(() => import("./pages/myevents/items"));
const Events = lazy(() => import("./pages/events/events"));
const News = lazy(() => import("./pages/news/news"));
const TabNews = lazy(() => import("./pages/news/tabnews"));
const Quick = lazy(() => import("./quick/quick"));
const Items = lazy(() => import("./pages/myevents/items"));
const StorePage = lazy(() => import("./pages/dev/store/storepage"));
const CartPage = lazy(() => import("./pages/dev/store/cartpage"));

function App() {
  const [page, setPage] = useState("");
  const [params, setParams] = useState({});
  const [splash, setSplash] = useState(true);
  const { user } = useContext(AuthenticationContext);
  const [showCards, setShowCards] = useState(true);
  const { isFeatureEnabled } = useFeatureFlag();

  const loadPage = () => {
    let hash = window.location.hash;
    let p = [];
    if (window.location.href.includes("?")) {
      window.location.href
        .split("?")[1]
        .split("&")
        .map((item) => {
          let val = item.split("=");
          let obj = {};
          obj[val[0]] = val[1];
          p[val[0]] = val[1];
          return obj;
        });
      setParams(p);
      hash = hash.split("?")[0];
    }
    if (p.page) {
      if (p.page === "refer") {
        p.page = "register";
      }
      hash = "#" + p.page;
      let prm = "";
      Object.keys(p).forEach((key) => {
        if (key !== "page") {
          if (prm.length > 0) {
            prm += "&";
          }
          prm += key + "=" + p[key];
        }
      });
      window.location.href = "/#" + p.page + "?" + prm;
    }
    setPage(hash);
  };

  useEffect(() => {
    // console.log("Reset Hash", page);
    if (page && page !== "#force" && page !== "#register") {
      if (showCards) {
        window.location.href = "#";
      }
    }
  }, [showCards, page]);

  useEffect(() => {
    loadPage();
    window.onpopstate = function (event) {
      forceLog("App.onpopstate");
      loadPage();
    };
    window.addEventListener("popstate", function (event) {
      forceLog("App.popstate");
      loadPage();
    });

    window.addEventListener("hashchange", () => {
      forceLog("App.hashchange");
      loadPage();
    });
  }, []);

  useEffect(() => {
    const delay = isLocalHost(window.location.href) ? 500 : 1500;
    let timeout = setTimeout(() => {
      forceLog("Splash", "Close");
      setSplash(false);
    }, delay);
    return () => {
      setSplash(false);
      clearTimeout(timeout);
    };
  }, []);

  useEffect(() => {
    //console.log("PAGE",page)
    forceLog("App.changePage", page);
  }, [page]);

  const [map, setMap] = useState(null);
  const [show, setShow] = useState(false);
  const [showList, setShowList] = useState(false);
  const [marker, setMarker] = useState();

  useEffect(() => {
    console.log("setMap", map)
  }, [map]);

  const handleClose = () => {
    forceLog("App.handleClose");
    setShow(false);
    setShowList(false);
  };
  const handleShow = () => {
    setShow(true);
  };

  const clickMarker = (event, data) => {
    fetch(
      `${serverURL}/api/teacherclick.php?teacherid=${data.data.id}&profileid=${
        user?.id ?? 0
      }`
    );
    setMarker(data);
    window.location.hash = "#details?id=" + data.data.id;
    handleShow();
  };

  const foundLocation = (location) => {
    forceLog("App.foundLocation", location);
    map.flyTo([location.lat, location.lng]);
    handleClose();
  };

  const goHome = () => {
    map.flyTo([user.lat, user.lng]);
  };

  const goHere = () => {
    if ("geolocation" in navigator) {
      forceLog("App.goHere", "");
      navigator.geolocation.getCurrentPosition(function (position) {
        map.flyTo([position.coords.latitude, position.coords.longitude]);
      });
    }
  };

  if (splash) {
    return <SplashPage />;
  }

  if (page === "#force") {
    return <Force {...params} />;
  }

  return (
    <div>
      <div name="main">
        <LocationDetails show={show} handleClose={handleClose} data={marker} />
        <LocationListView show={showList} handleClose={handleClose} map={map} />
        {/* <NextStep /> */}

        <div
          id="sideBar"
          style={{
            position: "absolute",
            top: "0",
            left: "0px",
            width: "100%",
            height: "0px",
            zIndex: "1044",
            right: "10px",
            borderRadius: "30px",
          }}
        >
          <DisplayCards
            onClick={(id) => {}}
            show={showCards}
            setShow={setShowCards}
          />
        </div>

        <MapBar goHome={goHome} goHere={goHere} />

        <div className="leaflet-container">
          <DisplayMap
            clickMarker={clickMarker}
            map={map}
            setMap={setMap}
            setShowList={setShowList}
            foundLocation={foundLocation}
          />
        </div>

        <Suspense fallback={<></>}>
          <Quick page={page} params={params} />
        </Suspense>
        {page ? (
          <>
            {page === "#register" ? <Register {...params} /> : null}
            {page === "#login" ? <Login {...params} /> : null}
            {page === "#forgot" ? <Forgot {...params} /> : null}
            {page === "#force" ? <Force {...params} /> : null}
            {page === "#details" ? <UserDetails params={params} /> : null}
            {page === "#getqrcode" && <GetQRCode page={page} params={params} />}
            {page === "#cards" && <DisplayCards onClick={(id) => {}} />}
            {page === "#news" ? (
              <Suspense fallback={<></>}>
                <News {...params} show={true} map={map} />
              </Suspense>
            ) : null}
             {page.includes("#store") ? (
              <Suspense fallback={<></>}>
                <StorePage page={page} params={params} show={true} map={map} />
              </Suspense>
            ) : null}
             {page.includes("#cart") ? (
              <Suspense fallback={<></>}>
                <CartPage page={page} params={params} show={true} map={map} />
              </Suspense>
            ) : null}
            {page === "#tabnews" ? (
              <Suspense fallback={<></>}>
                <TabNews {...params} show={true} map={map} />
              </Suspense>
            ) : null}
            {page.includes("#events") ? (
              <Suspense fallback={<></>}>
                <Events {...params} show={true} map={map} />
              </Suspense>
            ) : null}
                        {page &&
            (page.includes("#myitems") || page.includes("#mylocations")) ? (
              <Suspense fallback={<></>}>
                <MyItems page={page} params={params} show={true} map={map} />
              </Suspense>
            ) : null}
            {page &&
            (page.includes("#myevents") || page.includes("#mylocations")) ? (
              <Suspense fallback={<></>}>
                <Items page={page} params={params} show={true} map={map} />
              </Suspense>
            ) : null}
            {page && page.includes("#mynews") ? (
              <Suspense fallback={<></>}>
                <MyNews page={page} params={params} show={true} map={map} />
              </Suspense>
            ) : null}

            {page && page.includes("#myevents") ? (
              <Suspense fallback={<></>}>
                <MyEvents page={page} params={params} show={true} map={map} />
              </Suspense>
            ) : null}
            {page === "#view" ? <View {...params} show={true} /> : null}
            {page && page.includes("#profile") ? (
              <Teacher page={page} params={params} />
            ) : null}
            {page && page.includes("#admin") ? (
              <Suspense fallback={<></>}>
                <Admin page={page} params={params} />
              </Suspense>
            ) : null}

            {page.includes("#dev") ? (
              <Suspense fallback={<></>}>
                <Developer page={page} params={params} />
              </Suspense>
            ) : null}
          </>
        ) : null}

        <Toasts />

        {isFeatureEnabled("follow") && (
          <PullOutTab
            Icon={<ExclamationCircle size="25" />}
            style={{
              top: "175px",
            }}
            onClick={()=>{window.location.hash = "#tabnews"}}
          />
        )}
          {/* 
          TODO: Reqrite the card menu to allow showing it in different places
          <PullOutTab
            Icon={<GridFill size="25" />}
            variant="primary"
            style={{
              top: "55px",
            }}
            
            onClick={()=>{window.location.hash = "#cards"}}
          /> */}
      </div>
      {/* <AddToHomeScreen
        appId="Add-to-Homescreen React Modified Behavior Example"
        startAutomatically={true}
        startDelay={0}
        lifespan={30}
        skipFirstVisit={true}
        displayPace={0}
        customPromptContent={{
          title:
            "Do you want to install Add-to-homescreen React Modified Behavior Example on your homescreen?",
          cancelMsg: "No",
          installMsg: "Yes, sure!",
          guidanceCancelMsg: "Dismiss",
          src: "images/ExampleLogo.png",
        }}
        showClass={"AddToHomePage"}
      /> */}
    </div>
  );
}

export default App;
