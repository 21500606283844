import React from "react";
import { serverURL } from "./utils";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    //console.log("PROPS",props)
    this.state = {
      hasError: false,
      user: props.user || "unknown",
      page: props.page || "unknown",
    };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    let e = JSON.stringify(errorInfo);
    while (e.indexOf(":") > 0) {
      e = e.replace(":", "#");
    }
    let err = JSON.stringify(error.message);
    while (err.indexOf(":") > 0) {
      err = err.replace(":", "#");
    }
    let body = {
      user: JSON.stringify(this.state.user),
      page: JSON.stringify(this.state.page),
      error: JSON.stringify(err),
      errorinfo: JSON.stringify(e),
    };
    fetch(serverURL + "/gapi/api.php/errorboundary", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((res) => res.text())
      .then((res) => {
        console.log("Error saved successfully", res);
      })
      .catch((err) => {
        console.log("ERROR", err);
      });
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h1>Something went wrong.</h1>;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
