import { useContext } from "react";
import { AuthenticationContext } from "./authentication";

export const useUser = () => {
  // get the context
  const context = useContext(AuthenticationContext);

  // if `undefined`, throw an error
  if (!context) {
    throw new Error("useUser was used outside of its Provider");
  }
  const { user, token, settings } = context;

  const isAdmin = user && user.admin > 0;

  const getUserProperty = (key, defaultValue) => {
    const setting = settings?.find((item) => {
      return item.keyname === key;
    });
    if (!setting) {
      return defaultValue ?? "0"
    }
    return setting?.val ?? defaultValue;
  };

  return { user, token, isAdmin, getUserProperty };
};
