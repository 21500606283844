import { useState, useEffect, useContext } from "react";
import LocationDetails from "./pindetails/locationDetails";
import { serverURL } from "../components/utils"
import { AuthenticationContext } from "../context/authentication";

function View({id}) {
	const { user } = useContext(AuthenticationContext)
	const [teacher, setTeacher] = useState();

	useEffect(() => {
		fetch(`${serverURL}/gapi/api.php/teacher/${id}`)
				.then((res) => res.json())
				.then((res) => {
					if (res.errors) {
						throw res;
					}
					// console.log("VIEW",res);
					let temp = {
						name: res.name,
						data: res
					}
					setTeacher(temp);
					fetch(`${serverURL}/api/teacherclick?teacherid=${id}&profileid=${user.id}`)
				})
				.catch((err) => {
					console.error("view", err);
					window.location.hash = "";
				});
	},[id, user.id])

	const handleClose = () => {
		window.location.hash = "";
	};

	return (
		<>
			<LocationDetails show={true} handleClose={handleClose} data={teacher} />
		</>
	);
}

export default View;
