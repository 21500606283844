import { useState, createContext, useContext, useEffect } from "react";
import { AuthenticationContext } from "../context/authentication";

export const TourContext = createContext();

export const TourProvider = ({ children }) => {
	const [step, setStep] = useState(0);
	const [tourIsOpen, setIsOpen] = useState(false);
	const [tourMode, setTourMode] = useState("Home");
	const { user } = useContext(AuthenticationContext);

	const TranslateMode = (mode) => {
		if (mode === "Home" && user?.accountlevel) {
			return ["Dancer","","","","","Teacher","DJ","Event","Venue","Supplier"][user.accountlevel];
		}
		return mode;
	}
	const openTour = (mode) => {
		setIsOpen(true);
		if (mode) {
			setTourMode(TranslateMode(mode))
		}
	}
	const setTourStep = (stepNo, mode) => {
		setStep(stepNo);
		if (mode) {			
			setTourMode(TranslateMode(mode))
		}
	}
	const closeTour = (mode) => {
		setIsOpen(false);
		setTourMode(TranslateMode(mode))
	}

	useEffect(() => {
		// console.log("Tour Mode:",tourMode)
	},[tourMode])

	return (
		<TourContext.Provider
			value={{
				step, setTourStep, tourIsOpen, openTour, closeTour, tourMode, setTourMode
			}}>
			{children}
		</TourContext.Provider>
	);
};

export default TourProvider;
