import { useState } from "react";
import { Form, CloseButton } from "react-bootstrap";
import { Search } from "react-bootstrap-icons";

export function MapSearch({ onFound, handleClose, setCenter }) {
  const [autoShow, setAutoShow] = useState(false);
  const [options, setOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [value, setValue] = useState("");
  const [country, setCountry] = useState("South Africa");

  const goto = (location) => {
    setAutoShow(false);
    console.log("onFound", onFound);
    onFound(location);
  };
  const handleSearch = () => {
    const query = value + ", " + country;
    if (!isLoading) {
      setIsLoading(true);
      fetch(
        "https://nominatim.openstreetmap.org/search?format=json&addressdetails=1&limit=50&featureType=settlement&q=" +
          query
      )
        .then((response) => response.json())
        .then((data) => {
          //console.log("RESULT", data);
          const options = data.map((i) => {
			if (i.addresstype === "town") {
				return {name: i.address.town +", "+i.address.state,
				cityname: "",
				countrycode: "",
				lat: i.lat,
				lng: i.lon
			}
			}
			if (i.addresstype === "suburb") {
				return {name: i.address.suburb+", "+i.address.city +", "+i.address.state,
				cityname: "",
				countrycode: "",
				lat: i.lat,
				lng: i.lon
			}
			}
            return {
              name: i.display_name,
              cityname: "",
              countrycode: "",
              lat: i.lat,
              lng: i.lon,
            };
          });

          // console.log("FOUND", data);
          setOptions(options);
          setIsLoading(false);
          setAutoShow(true);
        })
        .catch((err) => console.error("error with " + query, err));
    }
  };
  return (
    <div
      style={{
        width: "100%",
        backgroundColor: "rgba(200, 200, 200, 0.8)",
        position: "absolute",
        borderRadius: "0px 0px 30px 30px",
        height: "7.5em",
      }}
    >
      <div style={{ padding: "0em 1em 1em 1em" }}>
        <Form>
          <Form.Group controlId="formBasicEmail">
            <div className="input-group mb-3">
              <input
                id="mapSearchInput"
                type="text"
                autoFocus
                className="form-control"
                placeholder="country"
                aria-label="Search"
                aria-describedby="search suburb"
                value={country}
                onChange={(ev) => setCountry(ev.target.value)}
              />
            </div>
            <div className="input-group mb-3">
              <input
                id="mapSearchInput"
                type="text"
                autoFocus
                className="form-control"
                placeholder="Suburb"
                aria-label="Search"
                aria-describedby="search suburb"
                value={value}
                onChange={(ev) => setValue(ev.target.value)}
                onKeyPress={(ev) => {
                  if (ev.key === "Enter") {
                    //console.log("ENTER")
                    ev.preventDefault();
                    ev.stopPropagation();
                    handleSearch();
                  }
                }}
              />
              <div className="input-group-append">
                <span className="input-group-text" id="basic-addon2">
                  <Search
                    id="mapSearchGo"
                    style={{
                      width: "1.5em",
                      height: "1.5em",
                    }}
                    onClick={() => handleSearch(value)}
                  />
                  <CloseButton
                    id="mapSearchClose"
                    style={{ paddingLeft: "1em" }}
                    onClick={() => handleClose()}
                  />
                </span>
              </div>
            </div>
          </Form.Group>
        </Form>
      </div>
      {autoShow ? (
        <div id="mapSearchResults" style={{ position: "relative" }}>
          <div
            className={
              options.length > 0
                ? "dropdown-menu searchpop show"
                : "dropdown-menu searchpop"
            }
            aria-labelledby="searchMapOptions"
          >
            {options.map((location, idx) => {
              return (
                <span
                  key={idx}
                  onClick={() => goto(location)}
                  className="dropdown-item"
                >
                  {location.name}
                </span>
              );
            })}
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default MapSearch;
