import React from "react";
import { createRoot } from 'react-dom/client';
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import AuthenticationProvider from "./context/authentication";
import LocationsProvider from "./context/locations";
import ConfigProvider from "./context/config";
import TourProvider from "./context/tour";
import {FeatureFlagProvider} from "./context/featureflag";

const Providers = () => {
	return (
		<React.StrictMode>
		<AuthenticationProvider>
			<FeatureFlagProvider tag="juztdance">
				<LocationsProvider>
					<ConfigProvider>
						<TourProvider>
							<App />
						</TourProvider>
					</ConfigProvider>
				</LocationsProvider>
			</FeatureFlagProvider>
		</AuthenticationProvider>
		</React.StrictMode>
	);
}

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<Providers />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
