import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import Paypal from "../../components/paypal";
import { rootURL, JuztDance } from "../../components/utils";
import { useUser } from "../../context/useuser";

const Invoice = ({ level, price, duration, onPaymentSuccessful }) => {
  const { user } = useUser();
  const [values, setValues] = useState({ subtotal: 0, discount: 0, total: 0 });

  const getPrice = () => {
    let p = price;
    // if (user.admin === 10) {
    //   p = p / 10;
    // }
    if (duration !== 12) {
      duration = 1;
    }
    return duration === 12
      ? p.toFixed(2) //((p * duration - p * duration * 0.2) / 15).toFixed(2)
      : p.toFixed(2); //((p * duration) / 17.5).toFixed(2);
  };

  useEffect(() => {
    console.log("USER", user);
  }, [user]);

  useEffect(() => {
    const calc = (tprice, tduration) => {
      // if (user.admin === 10) {
      //   tprice = tprice / 10;
      // }
      if (tduration !== 12) {
        tduration = 1;
      }
      console.log(user, tprice, tduration);
      let subtotal = tprice * tduration;
      // let discount = tduration >= 12 ? tprice * tduration * 0.2 : 0;
      let discount = (tduration = 12 ? tprice * 2 : 0);
      let total = subtotal - discount;
      setValues({ subtotal: subtotal, discount: discount, total: total });
    };
    calc(price, duration);
  }, [price, duration, user]);

  return (
    <div style={{ minWidth: "" }}>
      <h1>Invoice</h1>
      <JuztDance />
      <hr />
      <Row>
        <Col xs={12} md={6}>
          To: {user.firstname} {user.lastname}
        </Col>
        <Col xs={12} md={6}>
          From: <JuztDance />
          <br />
          <a href={`${rootURL}`}>{rootURL}</a>
        </Col>
      </Row>

      <hr />
      <Row>
        <Col xs={1} md={1}></Col>
        <Col xs={10} md={6}>Order Item</Col>
        <Col xs={6} md={1}>Quantity</Col>
        <Col xs={3} md={2}>Price</Col>
        <Col xs={3} md={2}>Total</Col>

        <Col xs={1} md={1}>1</Col>
        <Col xs={10} md={6}>{level} Subscription</Col>
        <Col xs={6} md={1}>{duration}</Col>
        <Col xs={3} md={2}>$ {price}</Col>
        <Col xs={3} md={2}>$ {values.subtotal}</Col>

        <Col xs={7} md={10}></Col>
        <Col xs={5} md={2}>
          <hr />
        </Col>

        <Col xs={2} md={8}></Col>
        <Col xs={7} md={2}>Discount</Col>
        <Col xs={3} md={2}>
          {duration === 12 ? <span>$ {values.discount}</span> : "$ 0"}
        </Col>
        <Col xs={7} md={10}></Col>
        <Col xs={5} md={2}>
          <hr />
        </Col>

        <Col xs={2} md={8}></Col>
        <Col xs={7} md={2}>Total</Col>
        <Col xs={3} md={2}>$ {values.total}</Col>
      </Row>
      <Row className="mt-5">
        <Col xs={1}></Col>
        <Col xs={10}>
          Pay now using:
          <br />
          <Paypal
            fee={getPrice()}
            profileid={user.id}
            promo=""
            period={duration === 12 ? "12 months" : "1 month"}
            onConfirmed={() => {
              if (onPaymentSuccessful) {
                onPaymentSuccessful();
              }
            }}
          />
        </Col>
        <Col xs={1}></Col>
      </Row>
    </div>
  );
};

export default Invoice;
