import { Dropdown, ButtonGroup } from "react-bootstrap";

const DevMenu = ({ user }) => {
  return (
    <Dropdown
      as={ButtonGroup}
      direction="up"
      style={{
        position: "absolute",
        right: "30px",
        top: "250px",
        zIndex: "999",
      }}
    >
      <Dropdown.Toggle id="dropdown-custom-1" className="rounded-circle">
        Dev Menu
      </Dropdown.Toggle>
      <Dropdown.Menu className="super-colors">
        <Dropdown.Item
          id="Settings"
          eventKey="8"
          onClick={() => {
            window.location.hash = "#dev/settings";
          }}
        >
          Settings
        </Dropdown.Item>

        <Dropdown.Divider />
        <Dropdown.Item
          eventKey="2"
          onClick={() => {
            window.location.hash = "dev/list";
          }}
        >
          Uploader HOOK (Testing)
        </Dropdown.Item>

        <Dropdown.Item
          eventKey="2"
          onClick={() => {
            window.location.hash = "store";
          }}
        >
          Store
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() => {
            window.location.hash = "myitems";
          }}
        >
          My Events (MyItems)
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() => {
            window.location.hash = "dev/mynews";
          }}
        >
          Dev News (MyNews)
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default DevMenu;
