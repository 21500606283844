import { useState, useEffect, createContext } from "react";
import { serverURL } from "../components/utils"

export const LocationsContext = createContext();

export const LocationsProvider = ({ children }) => {
	const [locations, setLocations] = useState([]);
    const [allLocations, setAllLocations] = useState([])
    const [pos, setPos] = useState()
    const [filter, setFilter] = useState("")
    const [pinType, setPinType] = useState("0")
    const [danceType, setDanceType] = useState("0")

    useEffect(() => {
        // console.log("Filtering");
        const FilterLocation = (item) => {
            let valid = true;
            if (filter)  {
              if (!((item.data.teacherbio||"").toLowerCase().includes(filter) || (item.data.name||"").toLowerCase().includes(filter) || (item.data.genre||"").toLowerCase().includes(filter))){
                valid = false;
              }
            }
            if (valid && pinType !== "0") {
                if (item.data.accountlevel+"" === pinType+"") {
                    valid = true
                } else {
                    valid = false;
                }
            }
            if (valid && danceType !== "0") {
                // console.log("Filter by dance type",item.data)
                if ( item.data.types && item.data?.types.includes(danceType)) {
                    valid = true
                } else {
                    valid = false;
                }
            }
            return valid
        }
        
        // console.log("FILTER",filter,pinType,danceType )
        if (allLocations && (filter || pinType !== "0" || danceType !== "0")) {
            setLocations(allLocations.filter(item => FilterLocation(item)))
        } else {
            setLocations(allLocations)
        }

    },[allLocations, filter, pinType, danceType])

    useEffect(()=>{
        // console.log("Filtered",locations)
    },[filter,locations, pinType,danceType])
	
    let searchTimeout;
	const search = (sw, ne) => {
		clearTimeout(searchTimeout)
        if (!(sw&&ne)) return setLocations([])
        let newPos = `nwlat=${ne.lat}&nwlng=${sw.lng}&selat=${sw.lat}&selng=${ne.lng}`;
        if (newPos === pos) return null;
        setPos(newPos)
        searchTimeout = setTimeout(() => {
            let url = `${serverURL}/api/findteacher.php?${newPos}`;
            fetch(url)
                .then(response => response.json())
                .then(data => { 
                    let marks = [];
                    data.locations.forEach(item => {
                        let mark = { position: [item.lat, item.lng], name: item.altname ?? item.name, data: item}
                        marks.push(mark)
                    })
                    setAllLocations(marks);
                })
        }, 500)
	}

	return (
		<LocationsContext.Provider
			value={{
				locations,
				search,
                filter, setFilter,
                pinType, setPinType,
                danceType, setDanceType
			}}>
			{children}
		</LocationsContext.Provider>
	);
};

export default LocationsProvider;
