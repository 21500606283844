import {
  Row,
  Col,
  Card,
  Form,
  Table,
  Button,
  InputGroup,
} from "react-bootstrap";
import useData from "../../hooks/usedata";
import { useUser } from "../../context/useuser";
import { FileEarmarkExcel, ArrowLeftSquare} from "react-bootstrap-icons";
import { exportToExcel } from "react-easy-export";
import useIsMobile from "../../hooks/useismobile";
import { serverURL } from "../../components/utils";

const MyEventsAttending = ({ pagePath, params }) => {
  const { token } = useUser();

  const events = useData(
    `${serverURL}/api/events/api.php/attending/` + params["id"],
    ["name", "email", "phonenumber"],
    { headers: { token: token } }
  );
  const stats = useData(
    `${serverURL}api/events/api.php/stats/` + params["id"],
    [],
    { headers: { token: token } }
  );
  const isMobile = useIsMobile();

  const updateStatus = (booking, status) => {
    fetch(`${serverURL}api/booking/api.php/booking/` + booking.id, {
      method: "PUT",
      body: JSON.stringify({ ...booking, status: status }),
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.text())
      .then((data) => {
        // console.log("**** Change Status Success", data);
        events.mutate("id", booking.id, { ...booking, status: status });
      });
  };

  // console.log("**** MYEVENTSLIST", events.data)

  const getStatus = (status) => {
    switch (status) {
      case "0":
      case 0:
        return "Interested";
      case "1":
      case 1:
        return "Booked";
      case "2":
      case 2:
        return "Deposit";
      case "3":
      case 3:
        return "Paid";
      case "99":
      case 99:
        return "Cancelled";
      default:
        return "Unknown";
    }
  };

  const exportData = () => {
    let data = [
      [
        "Name",
        "Email",
        "Phone",
        "Status",
        "Lead",
        "Lead Level",
        "Follow",
        "Follow Level",
      ],
    ];

    events.rawData.forEach((item) => {
      // item.data = item.data.replace(/'/g, '"')
      const jsonString = item.data;
      const cleanedString = jsonString.replace(/\\/g, "");
      const parsedJson = JSON.parse(cleanedString);
      item.data = parsedJson;
      let info = [
        item.name,
        item.email,
        item.phonenumber,
        getStatus(item.status),
        parsedJson.lead ? "Yes" : "No",
        parsedJson.leadlevel || "-",
        parsedJson.follow ? "Yes" : "No",
        parsedJson.followlevel || "-",
      ];
      data.push(info);
    });

    exportToExcel(data, "Attending.xls");
  };

  const HeaderButtons = () => {
    return (
      <Col xs={6} md={3} >
        <div style={{float: "right"}}>
        <Button
          variant="outline-primary"
          onClick={() => (window.location.hash = pagePath)}
        >
          {isMobile ? <ArrowLeftSquare /> : <>Back</>}
        </Button>
        <Button variant="outline-primary" className="ms-2" onClick={exportData}>
          <FileEarmarkExcel />
        </Button>
        </div>
      </Col>
    );
  };

  return (
    <div>
      <Row>
        <Col xs={6} md={5} className="mb-3">
          <h3>Attending</h3>
        </Col>
        {isMobile && <HeaderButtons />}
        <Col xs={12} md={4} style={{ textAlign: "right" }}>
          <InputGroup className="mb-3">
            <InputGroup.Text id="inputGroup-sizing-default">
              Search
            </InputGroup.Text>
            <Form.Control
              value={events.filterValue}
              onChange={(ev) => events.setFilterValue(ev.target.value)}
            />
            <Button variant="outline-primary">X</Button>
          </InputGroup>
        </Col>
        {!isMobile && <HeaderButtons />}
      </Row>
      <div className="m" />
      <Row className="p-1 mb-2">
        {stats.data.map((item) => {
          return (
            <Col className="pt-2 pb-2">
              <Card border="primary" className="p-2">
                {getStatus(item.status)}: {item.cnt}
              </Card>
            </Col>
          );
        })}
      </Row>
      <div className="m" />
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>
              <Button
                variant=""
                onClick={() => {
                  events.setSortField("name");
                }}
              >
                Name
              </Button>
            </th>
            <th>
              <Button
                variant=""
                onClick={() => {
                  events.setSortField("email");
                }}
              >
                Email
              </Button>
            </th>

            <th>
              <Button
                variant=""
                onClick={() => {
                  events.setSortField("phonenumber");
                }}
              >
                Phone
              </Button>
            </th>
            <th>
              <Button
                variant=""
                onClick={() => {
                  events.setSortField("status");
                }}
              >
                Status
              </Button>
            </th>
          </tr>
        </thead>
        <tbody>
          {(!events || !events.data || events.data.length === 0) && (
            <tr>
              <td colSpan={4}>No Bookings yet</td>
            </tr>
          )}
          {events.data &&
            events.data.map((row) => {
              return (
                <tr key={row.id}>
                  <td>{row.name}</td>
                  <td>{row.email}</td>
                  <td>{row.phonenumber}</td>
                  <td>
                    <Form.Select
                      aria-label="Status"
                      value={row.status + ""}
                      onChange={(e) => {
                        updateStatus(row, e.target.value);
                      }}
                    >
                      <option value="0">Interested</option>
                      <option value="1">Booked</option>
                      <option value="2">Deposit</option>
                      <option value="3">Paid</option>
                      <option value="99">Cancelled</option>
                    </Form.Select>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </Table>
    </div>
  );
};

export default MyEventsAttending;
