import { useState, useContext, useEffect } from "react";
import { AuthenticationContext } from "../context/authentication";
import { Row, Col, Card } from "react-bootstrap";
import JDPieChart from "./reportpie";
import { serverURL } from "../components/utils";
import Tracker from "../components/tracker";

const defaultColors = ['#8f2880' ,'#e94057', '#f27121', '#465ca9', '#009984', '#ec008c']

const defaultData = {
  dancers: [
    {
      count: "..",
    },
  ],
  teachers: [
    {
      count: "..",
    },
  ],
  genre: [],
  style: [],
  search: [
    {
      count: "..",
    },
  ],
  clicks: [
    {
      count: "..",
    },
  ],
};
const TeacherReport = () => {
  let { user } = useContext(AuthenticationContext);
  console.log("USER", user);

  const [data, setdata] = useState(defaultData);
  const [style, setstyle] = useState(0);

  useEffect(() => {
    console.log("Teacher Report Data", data);
    console.log("Teacher count", data?.teachers[0]?.count);
  }, [data]);

  useEffect(() => {
    fetch(`${serverURL}/api/getteacherreport.php?lat=-26&lng=28&id=${user.id}`)
      .then((res) => res.json())
      .then((res) => setdata(res));
  }, [user.id]);

  return (
    <Tracker itemtype="teacher-report" itemid={user.id}>
    <div class="report">
      <Row className="mb-3">
        <Col xs={12} sm={6}>
          <Card className="jdcard">
            <Card.Header>
              <h2>Market</h2>
            </Card.Header>
            <Card.Body className="text-center fs-1">
              {data.dancers[0].count}
            </Card.Body>
            <Card.Footer className="fs-7">
              Registered dancers within 25km of your location
            </Card.Footer>
          </Card>
        </Col>

        
        <Col xs={12} sm={6}>
          <Card className="jdcard">
            <Card.Header>
              <h2>Search</h2>
            </Card.Header>
            <Card.Body className="text-center fs-1">
              {data.search[0].count}
            </Card.Body>
            <Card.Footer className="fs-7">
              Number of times your pin has appeared in a search
            </Card.Footer>
          </Card>
        </Col>

        <Col xs={12} sm={6}>
          <Card className="jdcard">
            <Card.Header>
              <h2>Clicks</h2>
            </Card.Header>
            <Card.Body className="text-center fs-1">
              {data.clicks[0].count}
            </Card.Body>
            <Card.Footer className="fs-7">
              Number of times someone has clicked on your pin
            </Card.Footer>
          </Card>
        </Col>
        <Col xs={12} sm={6}>
          <Card className="jdcard">
            <Card.Header>
              <h2>Competition</h2>
            </Card.Header>
            <Card.Body className="text-center fs-1">
              {data.teachers[0].count}
            </Card.Body>
            <Card.Footer className="fs-7">
              Registered teachers within 25km of your location
            </Card.Footer>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <Card className="jdcard">
            <Card.Header>
              <h2>Dance styles</h2>
            </Card.Header>
            <Card.Body className="text-center ">
              <Row>
                <Col xs={12} sm={6}>
                  <JDPieChart 
                    selected={style}
                    data={data.style.map((item,index) => {
                      return { title: "", value: item.count, color: defaultColors[index % 6] }
                    })} />
                </Col>
                <Col xs={12} sm={6}>
                  {data.style.map((item, index) => {
                    return (
                      <Row className={index === style ? "activestyle" : null} onClick={(e)=>{setstyle(index)}}>
                        <Col style={{textAlign:"left"}}>{item.name}</Col>
                        <Col style={{textAlign:"right"}}>{item.count}</Col>
                      </Row>
                    );
                  })}
                  <em>Click a style to see which genres are offered</em>
                </Col>
              </Row>
            </Card.Body>
            <Card.Footer className="fs-7">
              Offered within 25km of your location
            </Card.Footer>
          </Card>
        </Col>
      </Row>
      {(style || style === 0) && (
        <Row>
          <Col xs={12}>
            <Card className="jdcard">
              <Card.Header>
                <h2>Genres {data.style[style] && `(${data.style[style].name})`}</h2>
              </Card.Header>
              <Card.Body className="text-center">
                <Row>
                  <Col xs={12} sm={6}>
                    <JDPieChart data={data.genre.filter(item => item.name === data.style[style].name).map((item,index) => {
                    return { title: "", value: item.count, color: defaultColors[index % 6] }
                  })}
                  />
                  </Col>
                  <Col xs={12} sm={6}>
                    {data.genre.filter(item => item.name === data.style[style].name).map((item, index) => {
                      return (
                        <Row onClick={(e)=>{setstyle(index)}}>
                          <Col style={{textAlign:"left"}}>{item.genre}</Col>
                          <Col style={{textAlign:"right"}}>{item.count}</Col>
                        </Row>
                      );
                    })}
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer className="fs-7">
                Offered within 25km of your location
              </Card.Footer>
            </Card>
          </Col>
        </Row>
      )}
      <p />
      <p className="mb-5" />
    </div>
    </Tracker>
  );
};

export default TeacherReport;
