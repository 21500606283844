import { useState, useContext, useEffect } from "react";
import { renderToStaticMarkup } from "react-dom/server";
import { MapContainer, Marker, TileLayer, useMapEvents, useMap } from "react-leaflet";
import { AuthenticationContext } from "../context/authentication";
import { Pin } from "../components/pin";
import { Tooltip, Button } from "react-bootstrap";
import { divIcon } from "leaflet";
import { HouseFill, PinMapFill } from "react-bootstrap-icons";
import { serverURL } from "../components/utils";
import Tracker from "../components/tracker";
import { useUser } from "../context/useuser";

const LogError = (error, e) => {
	let body = {
		user: "this.state.user",
		page: "teacher\\location.js",
		error: error,
		errorinfo: JSON.stringify(e),
	};
	fetch(serverURL + "/gapi/api.php/errorboundary", {
		method: "POST",
		headers: {
			"Accept": "application/json",
			"Content-Type": "application/json",
		},
		body: JSON.stringify(body),
	})
		.then((res) => res.text()) 
		.then((res) => {
			// console.log("Error saved successfully", res);
		})
		.catch((err) => {
			console.error("ERROR", err);
		});
}

function LocationMarker({ position, setPosition, setChanged }) {
	const { user } = useContext(AuthenticationContext);

	useEffect(() => {
		if (user && (user.lat || user.teacherlat)) {
			setPosition([user.teacherlat || user.lat, user.teacherlng || user.lng]);
		}
	}, [user, setPosition]);

	useMapEvents({
		click: (e) => {
			try {
				setPosition(e.latlng);
				if (setChanged) {
					setChanged(true);
				}
			} catch (err) {
				LogError("useMapEvents.click",err)
			}
		},
	});

	const getRequiredSVGPinByCategory = (category, myStyle) => {
		let pin;
				pin = <Pin {...myStyle} />;
		const iconMarkup = renderToStaticMarkup(pin);
		return divIcon({
			html: iconMarkup,
			className: "custom-icon",
			iconSize: [40, 40],
			iconAnchor: [20, 40],
		});
	};

	return position === null ? null : (
		<Marker key={1} id='1' position={position} icon={getRequiredSVGPinByCategory("", { fill: "purple" })}>
			<Tooltip>Home Location</Tooltip>
		</Marker>
	);
}

function ChangeView({ center, zoom }) {
	const [done, setDone] = useState();

	const map = useMap();
	if (center && !done) {
		map.setView(center, zoom);
		setDone(true);
	}
	return null;
}

function SelectLocation({message}) {
	let [center, setCenter] = useState([-26.20227, 28.04363]);
	let [changed, setChanged] = useState(false);
	const [position, setPosition] = useState(null);
	const [map, setMap] = useState(null);
	const { user } = useContext(AuthenticationContext);

	const { updateProfile } = useContext(AuthenticationContext);

	const saveLocation = (event) => {
		event.preventDefault();
		event.stopPropagation();
		let params = {
			lat: position.lat,
			lng: position.lng,
			teacherlat: position.lat,
			teacherlng: position.lng,
		};
		updateProfile(params);
		setChanged(false);
	};

	useEffect(() => {
		if (!center) {
			if ("geolocation" in navigator) {
				navigator.geolocation.getCurrentPosition((pos) => {
					
					// console.log(pos);
					if (!pos.coords) {
						pos.coords.latitude = -26.20227;
						pos.coords.longitude = 29.04363;
						// console.log("Setting Location Center (B) ")
						setCenter([-26.20227, 29.04363]);
					} else {
					// console.log("Setting Location Center (A) ")
					setCenter([pos.coords.latitude, pos.coords.longitude]);
					// console.log(pos);
					}
				});
			} else {
				console.log("GeoLocation Not Available");

				setCenter([-26.20227, 28.04363]);
			}
		} else {
			// console.log("Teacher: Center exists");
			// console.log(JSON.stringify(center))
		}
	},[center, setCenter]);

    const goHome = () => {
		map.flyTo([user.lat, user.lng]);
	}

	const goHere = () => {
		if ("geolocation" in navigator) {
			navigator.geolocation.getCurrentPosition(function(pos) {
				map.flyTo([ pos.coords.latitude, pos.coords.longitude])
			});
		} 
	}

	// useEffect(() => {
	// 	navigator.permissions.query({ name: 'geolocation' })
	// 	.then(res => {
	// 	  console.log("Teacher: GEOLOCATION PERMISSION")
	// 	  console.log(JSON.stringify(res))
	// 	})
	//   },[])

	return (
		<>
			<div
				style={{
					backgroundColor: "rgba(200,200,200,0.8)",
					position: "absolute",
					top: "110px",
					left: "90px",
					zIndex: "1000",
					right: "60px",
					height: "4em",
					borderRadius: "30px",
				}}>
				<div style={{ padding: "1em 1em 1em 1em" }}>
					{user.lat && user.lng &&
					<HouseFill
						data-tut="goHome"
						style={{
							width: "2em",
							height: "2em",
							marginLeft: "1em",
							float: "right",
							color: "purple",
							cursor: "pointer",
						}}
                        onClick={() => goHome()}
					/>}
					{"geolocation" in navigator ? (
						<PinMapFill
						data-tut="goHere"
							style={{
								width: "2em",
								height: "2em",
								marginLeft: "1em",
								float: "right",
								color: "purple",
								cursor: "pointer",
							}}
                            onClick={() => goHere()}
						/>
					) : null}
					{/* <Filter
							style={{ width: "2em", height: "2em", marginLeft: "1em" }}
							onClick={() => setShowFilter(true)}
						/> */}
				</div>
			</div>
			{changed ? (
				<>
					<Button
						id="locationSave"
						style={{ position: "absolute", left: "90px", top: "180px", zIndex: "999" }}
						className='rounded-circle'
						onClick={(ev) => {
							saveLocation(ev);
						}}>
						Save
					</Button>
				</>
			) : null}
				<MapContainer center={center} zoom={13} whenCreated={setMap}>
					<TileLayer
						attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
						url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
					/>
					<LocationMarker setChanged={setChanged} position={position} setPosition={setPosition} />
					<ChangeView />
				</MapContainer>
		</>
	);
}

const LocationSelection = (props) => {
	const { user } = useUser();
try {
	return (
		
		<Tracker itemtype="teacher-Location" itemid={user.id}>
		<div style={{ height: "100%" }}>
			<SelectLocation {...props} />
		</div>
		</Tracker>
	);
} catch (err) {
	LogError("Location Selection - Main",err)
}
};

export default LocationSelection;
