import { useContext } from "react"
import { PersonFill } from "react-bootstrap-icons"
import { Dropdown, ButtonGroup } from "react-bootstrap"
import { AuthenticationContext } from "../context/authentication";
import { forceLog, landingURL } from "./utils";
import { useFeatureFlag } from "../context/usefeatureflags";

const PersonalMenu = () => {
    //console.log("User",user)
	const { logout } = useContext(AuthenticationContext);
    const { isFeatureEnabled } = useFeatureFlag();
    
		forceLog("Personal Menu","");

    return (
        <Dropdown as={ButtonGroup} direction="up" style={{position:"absolute", right: "30px", bottom:"120px", zIndex:"1000"}}>
            <Dropdown.Toggle id="personalMenu" className="rounded-circle"><PersonFill /> Menu </Dropdown.Toggle>
            <Dropdown.Menu className="super-colors">
            <Dropdown.Item id="menuProfile" eventKey="4" onClick={() => { window.location.hash = "profile/profile"}}>Profile</Dropdown.Item>
            <Dropdown.Item eventKey="2" onClick={() => { window.location.hash = "news"}}>News</Dropdown.Item>
            <Dropdown.Item eventKey="4" onClick={() => { window.location.hash = "events"}}>Events</Dropdown.Item>
            <Dropdown.Item eventKey="3" onClick={() => { window.location.hash = "profile/refer"}}>Refer a Friend</Dropdown.Item>
            {isFeatureEnabled("mynews") && <Dropdown.Item eventKey="5" onClick={() => { window.location.hash = "mynews"}}>My News</Dropdown.Item>}
            
            <Dropdown.Divider />
                <Dropdown.Item eventKey="8" onClick={() => { window.location.href=landingURL}}>About</Dropdown.Item>
                <Dropdown.Item eventKey="9" onClick={() => { logout() }}>Logout</Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    )
}

export default PersonalMenu