import { useContext } from "react";
import { Form, InputGroup, CloseButton } from "react-bootstrap";
import { LocationsContext } from "../context/locations";

function MapFilter({ handleClose }) {
  const { filter, setFilter, pinType, setPinType, danceType, setDanceType } =
    useContext(LocationsContext);

  return (
    <div style={{ padding: "0em 1em 1em 1em" }}>
      <Form>
        <Form.Group className="mb-3">
          <Form.Select
            aria-label="Which pins would you like to see"
            value={pinType}
            onChange={(ev) => {
              setPinType(ev.target.value);
            }}
          >
            <option value="0">Show all Pins</option>
            <option value="5">Teachers only</option>
            <option value="6">DJs only</option>
            <option value="7">Dance Events</option>
            <option value="8">Dance Friendly Venues</option>
            <option value="9">Dance Friendly Suppliers</option>
          </Form.Select>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Select
            aria-label="Filter by dance type"
            value={danceType}
            onChange={(ev) => {
              setDanceType(ev.target.value);
            }}
          >
            <option value="0">Show all dance types</option>
            <option value="Afro-Latin">Afro-Latin</option>
            <option value="Ballroom">Ballroom</option>
            <option value="Ballet">Ballet</option>
            <option value="Jazz">Jazz</option>
            <option value="Modern">Modern</option>
            <option value="Traditional">Traditional</option>
            <option value="Tap">Tap</option>
          </Form.Select>
        </Form.Group>
        <InputGroup className="mb-3">
          <Form.Control
            id="basic-url"
            autoFocus
            aria-label="free text search"
            value={filter}
            onChange={(ev) => setFilter(ev.target.value.toLowerCase())}
            onKeyDown={(ev) => {
              if (ev.key === "Enter") {
                setFilter(ev.target.value.toLowerCase());
				ev.preventDefault();
				ev.stopPropagation();
                handleClose(ev);
              }
            }}
            placeholder="Filter criteria"
          />
          <InputGroup.Text id="free-text">
            <CloseButton
              onClick={(ev) => {
                handleClose(ev);
                setFilter("");
                setPinType("0");
                setDanceType("0");
              }}
            />
          </InputGroup.Text>
        </InputGroup>
      </Form>
    </div>
  );
}

export default MapFilter;
