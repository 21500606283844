import React from 'react';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { serverURL } from "./utils"

export default function Paypal(props) {
    //console.log({props});
    let orderdata, guid;
    return (
        <PayPalScriptProvider
        options={{
          'client-id': 'AdGOB4gGueJVASMKQ6BNXEXlGbSMRnxusVMe88CioUvLZ9VsmZ13ppqEWivptOyXhT6fB81s3f5VAuA6',
          components: 'buttons'
        }}
      >
        
        <PayPalButtons
          createOrder={async (data, actions) => { 
            //console.log("createOrder",data, actions);
            let body = {purchase_units: [ {
              description: "Juzt.Dance subscription",
              profileid: props.profileid,
              user: props.profileid,
              reference_id: "guid", 
              promo: props.promo,
              period: props.period,
              amount:  {
                value: props.fee,
                currency_code: 'USD'
              }
            }]}
            // console.log("Props",props)
            // console.log("body",body)
            await fetch(serverURL + "/paypal/juztdance/initSale.php", {
              method: "POST",
              headers: {
                "Accept": "application/json, text/plain, */*",
                "Content-Type": "application/json",
              },
              body: JSON.stringify(body),
            })
              .then((res) => res.json())
              .then((res) => {
                // console.log("REF",res);
                guid = res.referenceid;
              });
            
            orderdata = {
              intent: "CAPTURE",
              application_context :  { 
                shipping_preference:"NO_SHIPPING"
              },
              purchase_units: [ {
                description: "Juzt.dance teacher subscription",
                user: props.profileid,
                referenceid: guid,
                amount:  {
                  value: props.fee,
                  currency_code: 'USD'
                }
              }]
            };
            // console.log("orderdata",orderdata)
            return actions.order.create(orderdata);
          }}
          onCancel={function noRefCheck(data){ console.log("Cancel",data)}}
          onApprove={async function noRefCheck(data, actions){ 
            // console.log("onApprove",data,orderdata );
            let confirmData = {
              facilitatorAccessToken: data.facilitatorAccessToken,
              orderid: data.orderID,
              payerid: data.payerID,
              referenceid: orderdata.purchase_units[0].referenceid
            };
            //console.log("confirmData",confirmData);
            return actions.order.capture().then((details) => {
              //console.log("DETAILS",details);
              fetch(serverURL + "/paypal/juztdance/confirmSale.php",{
                method: "POST",
                headers: {
                  "Accept": "application/json, text/plain, */*",
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(confirmData)
              })
              .then(function (response) {
                // console.log("confirm response",response)
                props.onPaymentSuccessful();
              })
              .catch(err => {
                console.error("Paypal Error",err)
              })
            })
          }}
          onClick={function noRefCheck(data){ console.log("Click",data)}}
          shippingPreference="NO_SHIPPING"
          style={{
            color: 'gold'
          }}
        />
      </PayPalScriptProvider>)
}