import React, { useState, useContext, useEffect } from "react";
import { Form, Row, Col, Button, Badge } from "react-bootstrap";
import { AuthenticationContext } from "../../context/authentication";
import { serverURL } from "../../components/utils";
import { Envelope, Link, Whatsapp } from "react-bootstrap-icons";

const PackageBooking = ({ event, eventid }) => {
  const { user } = useContext(AuthenticationContext);
  const [pass, setPass] = useState(0);
  const [accomodation, setAccomodation] = useState(2);
  const [booked, setBooked] = useState(false);
  const [bookedData, setBookedData] = useState({});
  const [price, setPrice] = useState(0);

  useEffect(() => {
    fetch(
      `${serverURL}/api/booking/getbooking.php?eventid=${eventid}&profileid=${user.id}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.errors) {
          throw res;
        }
        if (res.data.length > 0) {
          setBooked(true);
          setBookedData(res.data[0]);
          console.log("Booked Data", res.data[0]);
          const jsonString = res.data[0].data;
          const cleanedString = jsonString.replace(/\\/g, "");
          const data = JSON.parse(cleanedString);
          console.log("Booked Data values", data);
          setAccomodation(data.accomodation);
          setPass(data.pass);
        }
      })
      .catch((err) => {
        setBooked(false);
        console.error("ERROR Admin Teacher Load", err);
      });
  }, [eventid, user]);

  const FestivalPackages = [
    {
      id: 0,
      name: "Full Festival",
      price: 560,
      description:
        "All Workshops, All Parties",
    },
    {
      id: 1,
      name: "Weekend Package",
      price: 450,
      description: "Friday-Sunday Workshops, Friday–Sunday  Parties",
    },
    {
      id: 2,
      name: "Party Package",
      price: 350,
      description: "No workshops, All Parties",
    },
  ];
  const AccomodationPackages = [
    {
      id: 0,
      name: "Residencial Horizonte 2",
      price: 650,
      description: "",
    },
    {
      id: 1,
      name: "La Casa Guest House",
      price: 1075,
      description: "",
    },
    {
        id: 2,
        name: "Angel Guest House",
        price: 660,
        description: "",
    },
    {
      id: 3,
      name: "None, I'll book my own",
      price: 0,
      description: "",
    },
  ];

  useEffect(() => {
    const passPrice = FestivalPackages.find((item) => item.id === pass).price;
    const accomodationPrice = AccomodationPackages.find(
      (item) => item.id === accomodation
    ).price;
    setPrice(passPrice + accomodationPrice);
  }, [pass, accomodation]);

  const book = () => {
    const data = {
      eventid: eventid,
      profileid: user.id,
      status: 1,
      data: JSON.stringify({ pass: pass, accomodation: accomodation }),
    };
    fetch(serverURL + "/api/booking/api.php/booking", {
      method: "POST",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((res) => {
        setBooked(true);
      })
      .catch((err) => {
        console.error("Upload error", err);
      });
  };

  if (booked) {
    return (
      <div
        style={{
          width: "95%",
        }}
      >
        <h1>Thank you for booking</h1>
        <div>
          Tô secure your spot please make the full payment of <strong>R {price}</strong> to:
          <br />
          <div className="ms-5">
            Account name: ATUM CREAT11ONS
            <br />
            Bank name: Standard Bank
            <br />
            Branch code: 7205
            <br />
            Account number: 20 028 058 9<br />
            SWIFT code: SBZAZAJJ
            <br />
            Reference: Your name
            <br />
          </div>
          Please Send proof of payment to dana@juzt.dance
          <br />
        </div>
      </div>
    );
  }

  if (!user) {
    return (
      <div
        className="p-3 m-3"
        style={{
          width: "95%",
          borderRadius: "10px",
          border: "2px solid purple",
        }}
      >
        <h1>Please login to book</h1>
      </div>
    );
  }

  return (
    <div style={{ width: "95%" }}>
      <Row className="mb-2">
        <Col>Full Name</Col>
        <Col>{user.firstname + " " + user.lastname}</Col>
      </Row>
      <Row>
        <h1>Festival Packages</h1>
      </Row>
      <Form>
        {FestivalPackages.map((item, index) => {
          return (
            <Row className="mt-1" key={item.id}>
              <Col xs={8} md={10}>
                <Form.Check
                  className="radio-dark"
                  key={index}
                  label={<strong>{item.name}</strong>}
                  name="pass"
                  type={"radio"}
                  id={`inline-${index}`}
                  onChange={(e) => {
                    setPass(item.id);
                  }}
                />
              </Col>
              <Col xs={4} md={2} className="float-end">
                <Badge>R {item.price}</Badge>
              </Col>
              <Col className="ps-xs-5">{item.description}</Col>
            </Row>
          );
        })}
      </Form>
      <Row className="mt-2">
        <h1>Accomodation </h1>
      </Row>
      <Form>
        {AccomodationPackages.map((item, index) => {
          return (
            <Row className="mt-1" key={item.id}>
              <Col xs={8} md={10}>
                <Form.Check
                  className="radio-dark"
                  key={index}
                  label={<strong>{item.name}</strong>}
                  name="accomodation"
                  type={"radio"}
                  onChange={(e) => {
                    setAccomodation(item.id);
                  }}
                  id={`inline-${index}`}
                />
              </Col>
              {item.price > 0 && (
                <Col xs={4} md={2} className="float-end">
                  <Badge>R {item.price}</Badge>
                </Col>
              )}
              {/* <Col className="ps-xs-5">{item.description}</Col> */}
            </Row>
          );
        })}
      </Form>
      <Row>
        <Col>
          <Button
            variant="primary"
            onClick={() => {
              book();
            }}
          >
            Book
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default PackageBooking;
