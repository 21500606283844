import { Collapse } from "react-bootstrap";
import React, { useState, useContext } from "react";
import { AuthenticationContext } from "../context/authentication";
import {
  Search,
  HouseFill,
  PinMapFill,
  ChevronLeft,
  ChevronRight,
  Map,
} from "react-bootstrap-icons";
import MapFilter from "./mapFilter";
import MapSearch from "./mapSearch";

const MapBar = (props) => {
  const [showFilter, setShowFilter] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [show, setshow] = useState(props.startOpen ?? false);

  const allowSearch = props.allowSearch ?? false;
  const allowFindPins = props.allowFindPins ?? true;

  const { user } = useContext(AuthenticationContext);

  return (
    <div
      id="mapBar"
      style={{
        position: "absolute",
        top: "0",
        width: "100%",
        height: "0px",
        zIndex: "1024",
        right: "10px",
        borderRadius: "30px",
      }}
    >
      {!show && (
        <div
          style={{
            position: "absolute",
            top: "15px",
            right: "20px",
            height: "65px",
            width: "60px",
            backgroundColor: "lightgray",
            backgroundImage: "none",
            borderRadius: "20px",
            zIndex: "1025",
          }}
          className=""
          onClick={() => {
            setshow((p) => !p);
          }}
        >
          <h2 style={{ padding: "10px", marginLeft: "1px" }}>
            <ChevronLeft />
          </h2>
        </div>
      )}
      <Collapse in={show} dimenstion="width" appear={true}>
        <div
          id="mapBar"
          style={{
            backgroundColor: "rgba(200,200,200,0.8)",
            position: "absolute",
            top: "1em",
            left: "60px",
            zIndex: "1000",
            right: "10px",
            height: showSearch ? "7.5em" : showFilter ? "14.5em" : "4em",
            borderRadius: "30px",
          }}
        >
          <div style={{ padding: "1em 1em 1em 1em" }}>
            {/* <List
						id="mapListView"
							style={{
								width: "2em",
								height: "2em",
								marginLeft: "1em",
								color: "purple",
								cursor: "pointer",
							}}
							onClick={() => props.setShowList(true)}
						/> */}

            {allowSearch && (
              <Map
                id="mapSearch"
                style={{
                  width: "2em",
                  height: "2em",
                  color: "purple",
                  cursor: "pointer",
                  marginLeft: "1em",
                }}
                onClick={() => {
                  setShowSearch(true);
                  setShowFilter(false);
                }}
              />
            )}
			{allowFindPins && (
            <Search
              id="mapFilter"
              style={{
                width: "2em",
                height: "2em",
                marginLeft: "2em",
                color: "purple",
                cursor: "pointer",
              }}
              onClick={() => {
                setShowFilter(true);
                setShowSearch(false);
              }}
            />)};
            <span
              onClick={() => {
                setshow((p) => !p);
              }}
            >
              <h2
                style={{ float: "right", marginLeft: "1em", padding: "00px" }}
              >
                <ChevronRight />
              </h2>
            </span>
            {user ? (
              <HouseFill
                id="mapCenterOnHome"
                style={{
                  width: "2em",
                  height: "2em",
                  marginLeft: "1em",
                  float: "right",
                  color: "purple",
                  cursor: "pointer",
                }}
                onClick={() => props.goHome()}
              />
            ) : null}
            {"geolocation" in navigator ? (
              <PinMapFill
                id="mapCenterOnLocation"
                style={{
                  width: "2em",
                  height: "2em",
                  marginLeft: "1em",
                  float: "right",
                  color: "purple",
                  cursor: "pointer",
                }}
                onClick={() => props.goHere()}
              />
            ) : null}
          </div>
          {showSearch ? (
            <MapSearch
              handleClose={() => setShowSearch(false)}
              onFound={props.foundLocation}
            />
          ) : null}

          {showFilter ? (
            <MapFilter handleClose={() => setShowFilter(!showFilter)} />
          ) : null}
        </div>
      </Collapse>
    </div>
  );
};

export default MapBar;
