import { useState, useContext, useEffect } from "react";
import {
  Modal,
  Button,
  Form,
  Row,
  Col,
  InputGroup,
  Alert,
} from "react-bootstrap";
import { AuthenticationContext } from "../context/authentication";
import { EyeFill } from "react-bootstrap-icons";
import { redirectWithParams } from "../components/utils";

function Login(props) {
  const [validated, setValidated] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState(false);
  const { login } = useContext(AuthenticationContext);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    setError(false);
    event.preventDefault();
    event.stopPropagation();
    if (form.checkValidity()) {
      // console.log("Do Login")
      login({ email: email, password: password })
        .then((user) => {
          if (props.t === "10") {
            window.location.hash = "view?id=" + props.refer;
          } else {
            window.location.hash = "";
          }
        })
        .catch((err) => {
          console.error("ERROR", err);
          setError(err.errors[0]);
        });
    }

    setValidated(true);
  };

  const handleClose = () => {
    window.location.hash = "";
  };

  useEffect(() => {
    setError(false);
  }, [email, password]);

  return (
    <Modal
      show={true}
      onHide={handleClose}
      id="loginform"
      data-tut="tourLoginForm"
    >
      <Modal.Header closeButton closeVariant="white">
        <Modal.Title>Login</Modal.Title>
        {/* <PatchQuestionFill
          id="loginhelp"
          style={{
            width: "1.5em",
            height: "1.5em",
            marginLeft: "1em",
            float: "right",
            position: "absolute",
            right: "50px",
            cursor: "pointer",
          }}
          onClick={() => openTour()}
        /> */}
      </Modal.Header>

      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Modal.Body>
          <Row className="mb-3">
            <Form.Group as={Col} xs={12} data-tut="tourLoginEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                required
                id="loginemail"
                type="email"
                placeholder="Enter email" 
                autoComplete="email"
                value={email}
                onChange={(ev) => {
                  setEmail(ev.target.value);
                }}
              />
              <Form.Text className="text-muted">
                We'll never share your email with anyone else.
              </Form.Text>
              <Form.Control.Feedback type="invalid">
                Please enter your email address.
              </Form.Control.Feedback>
            </Form.Group>

            <InputGroup className="mb-3" data-tut="tourLoginPassword">
              <Form.Control
                required
                placeholder="Password"
                id="loginpassword"
                aria-label="Password"
                aria-describedby="Password" 
                autoComplete="current-password"
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={(ev) => {
                  setPassword(ev.target.value);
                }}
              />
              <Button
                variant={showPassword ? "outline-primary" : "outline-info"}
                id="loginshow"
                onClick={() => {
                  setShowPassword(!showPassword);
                }}
              >
                <EyeFill />
              </Button>
              <Form.Control.Feedback type="invalid">
                Password is required.
              </Form.Control.Feedback>
            </InputGroup>
            {/* <div className="ms-3">
              <Form.Check
                type="checkbox"
                id="loginremember"
                label="Remember me"
                value={rememberMe}
                onChange={(ev) => setRememberMe(ev.target.value)}
              />
            </div> */}
          </Row>
          <Row>
            {error ? (
              <Alert variant="danger">
                <Alert.Heading>{error.message}</Alert.Heading>
              </Alert>
            ) : null}
          </Row>
          
          {/* <Row className="ps-2 pe-2">
            {rememberMe ? (
              <Alert variant="info">
                To remember you on this device we store data on your device that can identify you. By clicking "remember me" you give us permission to store this token.
              </Alert>
            ) : null}
          </Row> */}
        </Modal.Body>

        <Modal.Footer>
        <Button
            type="submit"
            variant="primary"
            id="loginsubmit"
            data-tut="tourLoginBtnLogin"
          >
            Login
          </Button>
          <Button
            variant="outline-secondary"
            onClick={() => {
              window.location.hash = "#forgot";
            }}
            data-tut="tourLoginBtnForgot"
          >
            Forgot Password
          </Button>
          <Button
            id="loginRegisterButton"
            variant="outline-secondary"
            onClick={() => {
              redirectWithParams("register", props);
            }}
            data-tut="tourLoginBtnRegister"
          >
            Register
          </Button>
          
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default Login;
