import React from "react";
import {
  Offcanvas,
  Navbar,
  Container,
  Nav,
  CloseButton,
  Row,
  Col,
} from "react-bootstrap";
import Tracker from "./tracker";

const BottomOverlay = (props) => {
  const { pageUrl, title = "No Title", navs = [], children, onClose } = props;

  return (
    <Tracker itemtype={"pageView"} id={pageUrl}>
      <Offcanvas placement={"bottom"} className="location" show={true}>
        <Offcanvas.Header
          style={{
            padding: "0rem",
            paddingRight: "1rem",
            borderBottom: "1px solid lightgrey",
          }}
        >
          <Navbar
            collapseOnSelect
            direction="up"
            expand="lg"
            bg="primary"
            variant="dark"
          >
            <Container>
              <Navbar.Brand href="#home">
                <h3>{title}</h3>
              </Navbar.Brand>
              {navs.length > 0 && (
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
              )}
              {navs.length > 0 && (
                <Navbar.Collapse id="responsive-navbar-nav">
                  <Nav className="me-auto">
                    {navs &&
                      navs.map((nav, index) => {
                        if (nav.onClick) {
                          return (
                            <Nav.Item
                              key={`${pageUrl}/${nav.url}`}
                              onClick={nav.onClick}
                            >
                              {nav.label}
                            </Nav.Item>
                          );
                        }
                        return (
                          <Nav.Link
                            key={`${pageUrl}/${nav.url}`}
                            href={`${pageUrl}/${nav.url}`}
                          >
                            {nav.label}
                          </Nav.Link>
                        );
                      })}
                  </Nav>
                </Navbar.Collapse>
              )}
            </Container>
          </Navbar>

          <CloseButton
            variant="white"
            onClick={() => {
              if (onclose) {
                onClose();
                return;
              }
              window.location.hash = "#";
            }}
          />
        </Offcanvas.Header>
        <Offcanvas.Body className="p-xs-1">
          {children}
        </Offcanvas.Body>
      </Offcanvas>
    </Tracker>
  );
};

const OverlayHeader = ({ children }) => {
  return (
    <Row>
      <Col>{children}</Col>
    </Row>
  );
};

BottomOverlay.Header = OverlayHeader;

export default BottomOverlay;
