import React, { createContext, useEffect, useState } from "react";
import { useUser } from "./useuser";
import { serverURL } from "../components/utils";

const FeatureFlagContext = createContext({ featureFlags: []});

const FeatureFlagProvider = ( props ) => {
  const { tag, children } = props;

  const [featureFlags, setFeatureFlags] = useState([]);
  const { user } = useUser();

  useEffect(() => {
    const body = {
      "userid": user?.id,
      "accountlevel": user?.accountlevel,
      "admin": user?.admin,
    }
    fetch(`${serverURL}/api/flags/getfeatureflags.php?tag=${tag}`, 
    {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(body),
    })
      .then((res) => res.json())
      .then((data) => {
        setFeatureFlags(data.result);
      })
  }, [tag, user]);

  return (
    <FeatureFlagContext.Provider value={{featureFlags}}>
      {children}
    </FeatureFlagContext.Provider>
  );
};

export { FeatureFlagContext, FeatureFlagProvider }