import React from "react";
import { Badge } from "react-bootstrap";
import { RoleLevel, RoleVariant } from "./utils";

const ProfileBadge = (props) => {
  return (
    <Badge bg={RoleVariant(props.loctype)} style={{ marginRight: "50px" }}>
        {RoleLevel(props.loctype)}
    </Badge>
  );
};

export default ProfileBadge;
