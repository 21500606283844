export function Pin(props) {
	return (
		<svg
			version='1.0'
			xmlns='http://www.w3.org/2000/svg'
			width='40'
			height='40'
			viewBox='0 0 260.000000 260.000000'
			preserveAspectRatio='xMidYMid meet'>
			<g transform='translate(0.000000,260.000000) scale(0.100000,-0.100000)' {...props} stroke='none'>
				<path
					d='M1185 2594 c-318 -52 -568 -270 -667 -584 -19 -64 -23 -96 -23 -215
0 -211 4 -223 244 -695 395 -777 556 -1090 561 -1090 5 0 166 313 561 1090
240 472 244 484 244 695 0 118 -4 152 -24 215 -87 281 -300 489 -576 564 -60
17 -263 29 -320 20z m315 -365 c212 -101 323 -317 276 -537 -57 -270 -330
-445 -591 -377 -378 99 -499 573 -212 831 104 93 193 125 342 121 103 -2 115
-4 185 -38z'
				/>
				
			</g>
		</svg>
	);
}
