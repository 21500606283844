import React from "react";
import { Button } from "react-bootstrap";
import { useFeatureFlag } from "../context/usefeatureflags";
import { useUser } from "../context/useuser";
import useFetch from "../hooks/usefetch";
import { localURL, serverURL } from "./utils";

const FollowButton = ({ teacher }) => {
  const { isFeatureEnabled } = useFeatureFlag();
  const { user } = useUser();

  const { data, mutate, remove } = useFetch(
    `${serverURL}/api/users/getfollows.php?profileid=${user?.id}&teacherid=${teacher?.id}`
  );

  if (!user) {
    return (
      <Button
        disabled
        variant="outline-secondary"
      >
        {`Follow ${teacher.name}`}
      </Button>
    );
  }

  const getFollowItem = () => {
    if (data && data.length > 0) {
      return data[0];
    }
    return null;
  };
  let followItem = getFollowItem();

  let isFollowing = !!followItem;

  if (!isFeatureEnabled("follow")) {
    return null;
  }

  const onClick = () => {
    if (isFollowing) {
      fetch(`${serverURL}/api/users/api.php/follow/${followItem.id}`, {
        method: "DELETE",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
      }).then(() => {
        remove("id", followItem.id);
      });
    } else {
      fetch(`${serverURL}/api/users/api.php/follow`, {
        method: "POST",
        body: JSON.stringify({ teacherid: teacher.id, profileid: user.id }),
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
      })
        .then((res) => res.text())
        .then((data) => {
          mutate("id", data, {
            id: data,
            teacherid: teacher.id,
            profileid: user.id,
          });
        });
    }
  };

  return (
    <Button
      variant={isFollowing ? "outline-secondary" : "primary"}
      onClick={onClick}
    >
      {isFollowing ? "Following" : `Follow ${teacher.name}`}
    </Button>
  );
};

export default FollowButton;
