import React, { useState, useEffect, useCallback } from "react";
import {
  Offcanvas,
} from "react-bootstrap";
import useData from "../../hooks/usedata";
import { useUser } from "../../context/useuser";
import ListItems from "./listitems";
import FormItem from "./formitem";
import MyEventsAttending from "./attending";
import useFetch from "../../hooks/usefetch";
import { serverURL } from "../../components/utils";
import BottomOverlay from "../../components/bottomoverlay";


const MyItems = ({ page, params }) => {
  let pagePath = "#myevents";
  let url = `${serverURL}/api/myevents/api.php/myevents`;
  if (page.includes("#mylocations")) {
    pagePath = "#mylocations";
    url = `${serverURL}/api/myevents/api.php/mylocations`;
  }

  const { user, token } = useUser();

  const [item, setItem] = useState();

  useEffect(() => {
    if (item) {
      if (item?.id) {
        window.location.hash = pagePath + "/edit/" + item?.id;
      } else {
        window.location.hash = pagePath + "/add";
      }
    } else {
      window.location.hash = pagePath;
    }
  }, [item, pagePath]);

  const items = useData(url, ["altname"], { headers: { token: token } });

  const itemCount = useFetch(
    `${serverURL}/api/myevents/api.php/pins/${user.id}`
  );
  const count = itemCount?.data?.length > 0 ? itemCount.data[0].count : 0;
  // const count = itemCount.data[0];

  const itemChanged = useCallback((item) => {
    if (item?.id) {
      items.mutate("id", item.id, item);
    }
  }, [items]);

  useEffect(() => {
    itemChanged(item);
  }, [item, itemChanged]);

  return (
    <BottomOverlay
      pageUrl={pagePath}
      title="My Events"
      navs={[
        // { label: "List", url: "/list" },
        // { label: "Add", url: "/add", onClick: ()=>{} },
      ]}
    >
      <Offcanvas.Body>
        {item ? (
          <FormItem
            params={params}
            show={true}
            item={item}
            setItem={setItem}
            pagePath={pagePath}
          />
        ) : (
          <>
            {page === pagePath ? (
              <ListItems
                params={params}
                show={true}
                items={items}
                count={count}
                item={item}
                setItem={setItem}
                pagePath={pagePath}
              />
            ) : null}
            {page === pagePath + "/list" ? (
              <ListItems
                params={params}
                show={true}
                items={items}
                count={count}
                item={item}
                setItem={setItem}
                pagePath={pagePath}
              />
            ) : null}
            {page === pagePath + "/add" ? (
              <FormItem
                params={params}
                count={count}
                show={true}
                item={null}
                setItem={setItem}
                pagePath={pagePath}
              />
            ) : null}
            {page === pagePath + "/edit" ? (
              <FormItem
                params={params}
                show={true}
                count={count}
                item={item}
                setItem={setItem}
                pagePath={pagePath}
              />
            ) : null}
            {page === pagePath + "/attend" ? (
              <MyEventsAttending
                params={params}
                show={true}
                count={count}
                item={null}
                setItem={setItem}
                pagePath={pagePath}
              />
            ) : null}
          </>
        )}
      </Offcanvas.Body>
    </BottomOverlay>
  );
};

export default MyItems;
