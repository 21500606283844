import { useEffect } from "react";
import { Dropdown, ButtonGroup } from "react-bootstrap";

const AdminMenu = ({ user }) => {
  useEffect(() => {
    //console.log("Development User", user);
    //console.log("UserAdmin", user.admin);
    // console.log("ADMIN LEVEL",user.admin)
  }, [user]);

  return (
    <Dropdown
      as={ButtonGroup}
      direction="up"
      style={{
        position: "absolute",
        right: "30px",
        top: "150px",
        zIndex: "999",
      }}
    >
      <Dropdown.Toggle id="dropdown-custom-1" className="rounded-circle">
        Administration
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {/* <Dropdown.Item id="adminNews" eventKey="4" onClick={() => { window.location.hash = "admin/news"}}>News</Dropdown.Item> */}
        <Dropdown.Item
          id="adminTeacher"
          eventKey="5"
          onClick={() => {
            window.location.hash = "admin/grid";
          }}
        >
          Teachers
        </Dropdown.Item>
        <Dropdown.Item
          id="adminRanking"
          eventKey="6"
          onClick={() => {
            window.location.hash = "admin/rankings";
          }}
        >
          Leaderboard
        </Dropdown.Item>
        <Dropdown.Item
          id="adminRegistrations"
          eventKey="7"
          onClick={() => {
            window.location.hash = "admin/registrations";
          }}
        >
          Registrations
        </Dropdown.Item>
        {/* <Dropdown.Item
          id="adminAddEvent"
          eventKey="8"
          onClick={() => {
            window.location.hash = "admin/addevent";
          }}
        >
          Add Event (old)
        </Dropdown.Item>
        <Dropdown.Item
          id="adminAddEvent"
          eventKey="8"
          onClick={() => {
            window.location.hash = "myevents";
          }}
        >
          My Events (old)
        </Dropdown.Item> */}
        <Dropdown.Item
          id="adminMyNews"
          eventKey="8"
          onClick={() => {
            window.location.hash = "mynews";
          }}
        >
          My News
        </Dropdown.Item>
        <Dropdown.Item
            id="myEvents"
            eventKey="8"
            onClick={() => {
              window.location.hash = "myevents";
            }}
          >
            My Events
          </Dropdown.Item>
          <Dropdown.Item
            id="myLocations"
            eventKey="8"
            onClick={() => {
              window.location.hash = "mylocations";
            }}
          >
            My Locations
          </Dropdown.Item>
          
          <Dropdown.Item
            id="getqrcode"
            eventKey="8"
            onClick={() => {
              window.location.hash = "admin/getqrcode";
            }}
          >
            Get QR Code
          </Dropdown.Item>
          <Dropdown.Item
            id="passwords"
            eventKey="8"
            onClick={() => {
              window.location.hash = "admin/passwords";
            }}
          >
            Change Password
          </Dropdown.Item>
          <Dropdown.Divider />
          
          <Dropdown.Item
            id="kanboard"
            eventKey="8"
            onClick={() => {
              window.location = "http://juzt.dance/kanboard";
            }}
          >
            Kanboard
          </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default AdminMenu;
