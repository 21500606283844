import { useState, useContext } from "react";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import { AuthenticationContext } from "../context/authentication";

function Forgot() {
	const [validated, setValidated] = useState(false);
	const [email, setEmail] = useState("");
	const { forgotPassword } = useContext(AuthenticationContext);

	const handleSubmit = (event) => {
		const form = event.currentTarget;
		event.preventDefault();
		event.stopPropagation();
		if (form.checkValidity() === false) {
			console.log("Error in form validation");
		} else {
			doResetPassword();
			handleClose()
		}

		setValidated(true);
	};

	const doResetPassword = () => {
		//console.log("Do Forgot Password");
		forgotPassword({ email: email })
			.then(() => {
				window.location.hash = "";
				// setValidated(false);
			})
			.catch((err) => {
				// setValidated(false);
			});
	};

	const handleClose = () => {
		window.location.hash = "";
	};

	return (
		<Modal show={true} onHide={handleClose}>
			<Modal.Header closeButton>
				<Modal.Title>Forgot Password</Modal.Title>
			</Modal.Header>

			<Form noValidate validated={validated} onSubmit={handleSubmit}>
				<Modal.Body>
					<Row className='mb-3'>
						<Form.Group as={Col} xs={12} controlId='formBasicEmail'>
							<Form.Label>Email address</Form.Label>
							<Form.Control
								required
								type='email'
								placeholder='Enter email'
								value={email}
								onChange={(ev) => {
									setEmail(ev.target.value);
								}}
							/>
							<Form.Text className='text-muted'>
								Please enter your email address and we will send you a link to reset your password.
							</Form.Text>
							<Form.Control.Feedback type='invalid'>
								Please enter a valid email address.
							</Form.Control.Feedback>
						</Form.Group>
					</Row>
				</Modal.Body>

				<Modal.Footer>
					<Button
						variant='outline-secondary'
						onClick={() => {
							window.location.hash = "#login";
						}}>
						Never mind, I remember it
					</Button>
					<Button type='submit' variant='primary'>
						Submit
					</Button>
				</Modal.Footer>
			</Form>
		</Modal>
	);
}

export default Forgot;
