import { useState, useContext, useEffect } from "react"
import { AuthenticationContext } from "../context/authentication";
import { rootURL } from "../components/utils"
import QRCode from "../components/qrcode";
import {CopyToClipboard } from "../components/utils"

const TeacherRefer = () => {
	const [copying, setCopying] = useState(false);
	let { user } = useContext(AuthenticationContext);

	const copyToClipboard = (str) => {
		setCopying(true);
		const el = document.createElement("textarea");
		el.value = str;
		document.body.appendChild(el);
		el.select();
		document.execCommand("copy");
		document.body.removeChild(el);
	};

	useEffect(() => {
		if (copying) {
			setTimeout(() => setCopying(false), 1000);
		}
	}, [copying]);

	let ReferralLink = `${rootURL}#register?refer=` + (user ? user.id : 1);
	//console.log("Admin AdminRefer");
	return (
			<div>Referring friends will earn you rewards! <p/>

				Send this QR code by clicking the icon below and add to a Whatsapp or email.... or let your friend scan it now. 
				<p />
				Your personal QR Code
				<br />
				<div>
					<QRCode link={`${ReferralLink}`} allowCopyToClipboard={true}/>
				</div>
				<p />
				<span onClick={() => copyToClipboard(ReferralLink + "&t=1")}>
					Your Referral link to share: <br />
					{`${ReferralLink}&t=1`} <CopyToClipboard width={16} height={16} color={"purple"} />
				</span>
				{copying ? (
					<span
						style={{
							padding: "5px",
							color: "white",
							backgroundColor: "purple",
							marginLeft: "30px",
							border: "1px solid purple",
							borderRadius: "5px",
						}}>
						<i>Copying link to clipboard</i>
					</span>
				) : null}
				<p />
				The more you refer your unique code,  the more you move up in the rankings. Prizes are  waiting for you!!
				<p />
				<p className='mb-5' />
			</div>
	);
};

export default TeacherRefer;
