import LocationDetails from "./pindetails/locationDetails"
import useFetch from "../hooks/usefetch";
import { serverURL } from "../components/utils";

const UserDetails = ({params}) => {

    const {data} = useFetch(`${serverURL}/gapi/api.php/profile/${params.id}`);

    if (!params || !params.id) {
        return null;
    }
    return (
        <LocationDetails show={true} handleClose={()=>{window.location.hash=""}} data={{name:data.name, data: data}} />
    );
}

export default UserDetails;