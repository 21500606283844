import React, { useContext } from "react";
import { Col, Collapse, Row } from "react-bootstrap";
import {
  ChevronLeft,
  ChevronRight,
  Facebook,
  Instagram,
  Tiktok,
} from "react-bootstrap-icons";
import { AuthenticationContext } from "../context/authentication";
import { LocationsContext } from "../context/locations";
import { Dance2, landingURL } from "./utils";

const DisplayCards = ({ show, setShow }) => {
  const { setPinType } = useContext(LocationsContext);
  const { user } = useContext(AuthenticationContext);
  // const [show, setShow] = useState(true);
  const hide = () => {
    setShow(false);
  };
  // user ? "-20px" : "-1000px",
  return (
    <>
      <div
        style={{
          top: "80px",
          bottom: "0px",
          left: "-20px",
          width: "60px",
        }}
        className="dance badge5"
        onClick={() => {
          setShow((p) => !p);
        }}
      >
        <h2>{show ? <ChevronLeft /> : <ChevronRight />}</h2>

        <div
          style={{
            position: "absolute",
            top: "-80px",
            left: "30px",
            width: "50px",
          }}
        ></div>
      </div>
      {show && (
        <div
          id="noclick"
          style={{
            zIndex: "1100",
            backgroundColor: "white",
            position: "absolute",
            top: "0px",
            bottom: "0px",
            left: "0px",
            right: "0px",
            width: "100vw",
            height: "100vh",
          }}
        ></div>
      )}
      <Collapse in={show} dimension="width" appear={true}>
        <div id="cardMenu" style={{position: "absolute", top: "30px", left: "50px", bottom: "50px", right: "50px", height:"calc(100vh - 85px)", overflow: "hidden"}}>
          <Row style={{ height: "100%" }}>
            <Col>
              <Row style={{ height: "20%" }} className="g-3">
                <Col xs={6} style={{ height: "100%" }}>
                  <div
                    className="dancetile badge1"
                    onClick={() => {
                      setPinType(5);
                      hide();
                    }}
                  >
                    <div className="tilecaption align-middle">
                      Find a Teacher
                    </div>
                  </div>
                </Col>
                <Col xs={6} style={{ height: "100%" }}>
                  <div
                    className="dancetile badge3"
                    onClick={() => {
                      setPinType(6);
                      hide();
                    }}
                  >
                    <div className="tilecaption align-middle">
                      Find a DJ
                    </div>
                  </div>
                </Col>
                <Col xs={6} style={{ height: "100%" }}>
                  <div
                    className="dancetile badge5"
                    style={{height:"100%"}}
                    onClick={() => {
                      setPinType(9);
                      hide();
                    }}
                  >
                    <div className="tilecaption align-middle">
                      Find a Supplier
                    </div>
                  </div>
                </Col>
                <Col xs={6} style={{ height: "100%" }}>
                  <div
                    className="dancetile badge2"
                    onClick={() => {
                      setPinType(8);
                      hide();
                    }}
                  >
                    <div className="tilecaption align-middle">
                      Find a Venue
                    </div>
                  </div>
                </Col>
                <Col xs={6} style={{ height: "100%" }}>
                  <div
                    className="dancetile badge4"
                    onClick={() => {
                      hide();
                    // console.log("Click News")
                    window.location.hash = "events";
                    }}
                  >
                    <div className="tilecaption align-middle">
                      Events
                    </div>
                  </div>
                </Col>
                <Col
                  xs={6}
                  style={{ height: "100%" }}
                  onClick={() => {
                    hide();
                    // console.log("Click News")
                    window.location.hash = "news";
                  }}
                >
                  <div className="dancetile badge7">
                    <div className="tilecaption align-middle">
                      News
                    </div>
                  </div>
                </Col>
                <Col xs={6} style={{ height: "100%" }}>
                  <div
                    className={!user ? "dancetile badge8" : "dancetile badge10"}
                    onClick={() => {
                      hide(0);                      
                      setPinType("0");
                      // console.log("Click Login", user)
                      if (user) {
                        return;
                      }
                      window.location.hash = "register";
                    }}
                  >
                    <div className="tilecaption align-middle">
                      {!user ? "Login" : "Show Map"}
                    </div>
                    <div
                      style={{
                        position: "absolute",
                        bottom: "5px",
                        left: "5px",
                      }}
                    >
                      <Dance2 height={"77px"} />
                    </div>
                  </div>
                </Col>
                <Col xs={6} style={{ height: "100%" }}>
                  <div
                    className="dancetile badge9"
                    onClick={() => {
                      window.location = landingURL;
                    }}
                  >
                    <div className="tilecaption align-middle">
                      About
                    </div>
                  </div>
                </Col>
                <Col xs={4} style={{ height: "100%" }}>
                  <div className="dancetile badgesocial">
                    <div className="socialcard text-center align-middle">
                      <a
                        href="https://www.facebook.com/JuztDotDance"
                        style={{ color: "purple" }}
                      >
                        <Facebook />
                      </a>
                    </div>
                  </div>
                </Col>
                <Col xs={4} style={{ height: "100%" }}>
                  <div className="dancetile badgesocial">
                    <div className="socialcard text-center align-middle">
                      <a
                        href="http://www.instagram.com/juzt.dance/"
                        style={{ color: "purple" }}
                      >
                        <Instagram />
                      </a>
                    </div>
                  </div>
                </Col>
                <Col xs={4} style={{ height: "100%" }}>
                  <div className="dancetile badgesocial">
                    <div className="socialcard text-center align-middle">
                      <a
                        href="https://www.tiktok.com/@juzt.dance"
                        style={{ color: "purple" }}
                      >
                        <Tiktok />
                      </a>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <div className="text-center" style={{ zIndex: "1200" }}>
              {/* ver {process.env.REACT_APP_VERSION} */}
            </div>
          </Row>

          {/* <div
            style={{ top: "0px", cursor: "pointer" }}
            className="dance badge1"
            onClick={() => {
              setPinType(5);
              hide();
            }}
          >
            <h2>Find a Teacher</h2>
          </div>
          <div
            style={{ top: "15px" }}
            className="dance badge3"
            onClick={() => {
              setPinType(6);
              hide();
            }}
          >
            <h2>Find a DJ</h2>
          </div>
          <div
            style={{ top: "30px" }}
            className="dance badge5"
            onClick={() => {
              setPinType(9);
              hide();
            }}
          >
            <h2>Find a Supplier</h2>
          </div>

          <div
            style={{ top: "45px" }}
            className="dance badge2"
            onClick={() => {
              setPinType(8);
              hide();
            }}
          >
            <h2>Find a Venue</h2>
          </div>

          <div
            style={{ top: "60px" }}
            className="dance badge4"
            onClick={() => {
              setPinType(7);
              hide();
            }}
          >
            <h2>Find an Event</h2>
          </div>

          <div
            style={{ top: "75px" }}
            className="dance badge7"
            onClick={() => {
              hide();
              console.log("Click News")
              window.location.hash = "news";
            }}
          >
            <h2>News</h2>
          </div>

          <div
            style={{ top: "90px" }}
            className="dance badge8"
            onClick={() => {
              hide();
              console.log("Click Login")
              window.location.hash = "register";
            }}
          >
            <Row>
              <Col><h2>Login
              
            </h2>
              </Col>
              <Col>
              <div style={{float:"right", marginRight:"30px"}}>
                <Dance2 height={"77px"} />
              </div>
              </Col>
            </Row>
            
          </div> */}
          {/* ver {process.env.REACT_APP_VERSION} */}
        </div>
      </Collapse>
    </>
  );
};
export default DisplayCards;
