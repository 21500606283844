import { useState, useContext, useEffect } from "react";
import { AuthenticationContext } from "../../context/authentication";
import { rootURL } from "../../components/utils";
import QRCode from "../../components/qrcode";
import { CopyToClipboard } from "../../components/utils";
import UserSelect from "../../components/userselect";
import { InputGroup } from "react-bootstrap";

const ViewQRCode = () => {
  const [copying, setCopying] = useState(false);
  let { user } = useContext(AuthenticationContext);
  const [item, setItem] = useState({ id: user.id, name: user.name ?? user.firstname + " " + user.lastname});

  const copyToClipboard = (str) => {
    setCopying(true);
    const el = document.createElement("textarea");
    el.value = str;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
  };

  useEffect(() => {
    if (copying) {
      setTimeout(() => setCopying(false), 1000);
    }
  }, [copying]);

  const select = (id, name) => {
    setItem({ id: id, name: name });
  };

  let ReferralLink = `${rootURL}#register?refer=` + (item ? item.id : 1);
  //console.log("Admin AdminRefer");
  return (
    <div>
      <InputGroup className="mb-3">
        <InputGroup.Text id="name-edit">Name</InputGroup.Text>
        <UserSelect item={item} onSelect={select} />
      </InputGroup>
      <div className="mt-3 mb-3">QR Code for {item.name}</div>
      <div>
        <QRCode link={`${ReferralLink}`} allowCopyToClipboard={true} />
      </div>
      <p />
      <span onClick={() => copyToClipboard(ReferralLink + "&t=1")}>
        Referral link to share: <br />
        {`${ReferralLink}&t=1`}{" "}
        <CopyToClipboard width={16} height={16} color={"purple"} />
      </span>
      {copying ? (
        <span
          style={{
            padding: "5px",
            color: "white",
            backgroundColor: "purple",
            marginLeft: "30px",
            border: "1px solid purple",
            borderRadius: "5px",
          }}
        >
          <i>Copying link to clipboard</i>
        </span>
      ) : null}
      <p />
      <p className="mb-5" />
    </div>
  );
};

export default ViewQRCode;
