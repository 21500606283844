import { useState, useContext, useEffect } from "react";
import { Modal, Button, Form, Row, Col, InputGroup, Alert  } from "react-bootstrap";
import { AuthenticationContext } from "../context/authentication"
import { EyeFill } from "react-bootstrap-icons"

// http://localhost:3000/#force?force=XK0WbM4XcRSVMDpsxvPkqaGae6RIgkCz9wGGCLxVK5uWoqOvfL9BhM8yd5ZVmRslZUoLjw8ZuBMKhFbqAQR82eGfjc9j2CKNwk8kfexQQ2B5apmYkLyIK9OXyLxt8HWGLHoADIunBkGjGLvVUK6UEOJ6CpQqMGmHhJQNk4Q4OJOOlGbhIRM4bS6lG0RnKrz1cGdbJQ9z

function Force(props) {
	const [validated, setValidated] = useState(false);
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
	const [showPassword, setShowPassword] = useState(false);
	const [error, setError] = useState(false);
	const { forceNewPassword } = useContext(AuthenticationContext)

	console.log("FORCE");

	const handleSubmit = (event) => {
		const form = event.currentTarget;
		setError(false);
        event.preventDefault();
        event.stopPropagation();
		if (confirmPassword !== password) {
			setError({ message: "Passwords do not match"})
			return
		}
		if (form.checkValidity() === false) {
			return;
		}
        //console.log("Do change password")
		forceNewPassword({confirm:confirmPassword,force:props.force,password:password})
		.then(() => {
			window.location.hash = "";
		})
		.catch((err) => {
			console.error("ERROR",err)
			setError(err.errors[0])
		})

		setValidated(true);
	};

	const handleClose = () => {
		window.location.hash = "";
	};

	useEffect(() => {
		setError(false);
	},[password, confirmPassword])

	return (
		<Modal show={true} onHide={handleClose}>
			<Modal.Header closeButton closeVariant="white">
				<Modal.Title>Change Password</Modal.Title>
			</Modal.Header>

			<Form noValidate validated={validated} onSubmit={handleSubmit}>
				<Modal.Body>
					<Row className='mb-3'>
						<Form.Group as={Col} xs={12} controlId='formBasicEmail'>
							<InputGroup className="mb-3">
								<Form.Control
									placeholder="Password"
									aria-label="Password"
									aria-describedby="Password"
									type={showPassword ? "text" : "password"}
									value={password} 
									onChange={(ev) => { setPassword(ev.target.value) } } 
								/>
								<Button variant={ showPassword ? "outline-primary" : "outline-info"} id="button-addon2" onClick={() => {setShowPassword(!showPassword)}}>
									<EyeFill />
								</Button>
							</InputGroup>
						</Form.Group>
						<Form.Group>
							<Form.Control
								placeholder="Confirm Password"
								aria-label="Confirm Password"
								aria-describedby="Confirm Password"
								type={showPassword ? "text" : "password"}
								value={confirmPassword} 
								onChange={(ev) => { setConfirmPassword(ev.target.value) } } 
							/>
						</Form.Group>
					</Row>
					<Row>
						{error ? (
							<Alert variant="danger">
								<Alert.Heading>{error.message}</Alert.Heading>
							</Alert>
						) : null}
					</Row>
				</Modal.Body>

				<Modal.Footer>
					<Button variant='primary' type="submit">
						Change Password
					</Button>
				</Modal.Footer>
			</Form>
		</Modal>
	);
}

export default Force;
