
import { PieChart } from 'react-minimal-pie-chart';

const defaultLabelStyle = {
    fontSize: '5px',
    fontFamily: 'sans-serif',
  };

const pieChartDefaultProps = {
    radius: "40"
}  

const JDPieChart = (props) => {
    const shiftSize = 4;
    console.log("Pie chart props",props.data)
    return (
        <PieChart
          data={props.data}
          radius={pieChartDefaultProps.radius - shiftSize}
          segmentsShift={(index) => (index === props.selected ? shiftSize : 0.5)}
          label={({ dataEntry }) => `${dataEntry.title}  (${dataEntry.value})`}  
          labelStyle={{
            ...defaultLabelStyle,
          }}
          labelPosition={80}
        />)
}  

export default JDPieChart;