import { useState, useEffect, useContext } from "react";
import { MapContainer, TileLayer, Marker, Tooltip, useMapEvent, useMap } from "react-leaflet";
import { Pin } from "./pin";
import { renderToStaticMarkup } from "react-dom/server";
import { divIcon } from "leaflet";
import { Button } from "react-bootstrap";
import { AuthenticationContext } from "../context/authentication";
import { LocationsContext } from "../context/locations";
import PersonalMenu from "./personalMenu";
import DevMenu from "./devMenu";
import AdminMenu from "./adminMenu";
import { forceLog, landingURL, RoleColor, Shoe2 } from "./utils";
import ErrorBoundary from "./errorboundary";
import CartButton from "../pages/dev/store/cartbutton";

// import JDTour from "./tour";

const zoom = 13;
function ViewSearch({ clickMarker, setCenter }) {
	forceLog("ViewSearch","")
	const { locations, search } = useContext(LocationsContext);

	const getRequiredSVGPinByCategory = (category, myStyle) => {
		forceLog("getRequiredSVGPinByCategory","")
		let pin;
		switch (category) {
			default:
				pin = <Pin {...myStyle} />;
				break;
		}
		const iconMarkup = renderToStaticMarkup(pin);
		const customMarketIcon = divIcon({
			html: iconMarkup,
			className: "custom-icon",
			iconSize: [40, 40],
			iconAnchor: [20, 40],
		});
		return customMarketIcon;
	};

	const doSearch = (sw, ne) => {
		forceLog("doSearch","")
		search(sw, ne);
	};
	// includes ZOOMEND
	const map = useMapEvent("moveend", (e) => {
		forceLog("useMapEvent",e)
		let bnds = map.getBounds();
		doSearch(bnds._southWest, bnds._northEast);
	});

	useEffect(() => {
		let bnds = map.getBounds();
		forceLog("DisplayMap.ViewSearch",bnds);
		doSearch(bnds._southWest, bnds._northEast);
	});

	const getItemType = (level) => {
		forceLog("getItemType",level)
		// function componentToHex(c) {
		// 	let hex = c.toString(16);
		// 	return hex.length === 1 ? "0" + hex : hex;
		// }
		// function rgbToHex(r, g, b) {
		// 	return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
		// }
		switch (level) {
			case 5:
				return RoleColor("teacher");
			case 7:
				return RoleColor("event");
			case 6:
				return RoleColor("dj");
			case 8:
				return RoleColor("venue");
			case 9:
				return RoleColor("supplier")
			default:
				return "lightgreen";
		}
	};

	return (
		<>
			{locations &&
				locations.map((item, index) => {
					let itemType = getItemType(item.data.loctype);
					if (item.data.id === 319) {
						itemType = "green";
					}
					// console.log("DisplayMap.ViewSearch",item)
					return (
						<Marker
							key={index}
							id={item.data.id}
							position={item.position}
							icon={getRequiredSVGPinByCategory("", { fill: itemType })}
							eventHandlers={{
								click: (e) => {
									forceLog("DisplayMap.ClickMarker",item);
									clickMarker(e, item);
								},
								mouseover: (e) => {
									// console.log("Mouse Over");
								},
							}}>
							<Tooltip>{item.name}</Tooltip>
						</Marker>
					);
				})}
		</>
	);
}

function ChangeView({ center, zoom }) {
	const [done, setDone] = useState();
	
	forceLog("DisplayMap.ChangeView Before",{center,zoom});

	const map = useMap();
	if (map && !done && center !== [-26.20227, 28.04363]) {
		forceLog("DisplayMap.ChangeView During",{center,zoom});
		map.setView(center, zoom);
		setDone(true);
	}
	
	forceLog("DisplayMap.ChangeView After",{center,zoom});
	return null;
}

function DisplayMap({ clickMarker, map, setMap }) {
	let [center, setCenter] = useState([-26.20227, 28.04363]);

	const { user } = useContext(AuthenticationContext);

	useEffect(() => {
		forceLog("DisplayMap.user",user);
	}, [user]);

	// useEffect(() => {
	// 	forceLog("DisplayMap.start","");
	// 	console.log("Checking permissions for geolocation")
	// 	navigator.permissions.query({ name: 'geolocation' })
	// 	.then(res => {
	// 		console.log("GEOLOCATION PERMISSION"+JSON.stringify(res))
	// 		forceLog("DisplayMap.GEOLOCATION PERMISSION",JSON.stringify(res));
	// 	})
	// 	.catch(err => {
	// 		console.log("Permissions error"+JSON.stringify(err))
	// 		forceLog("DisplayMap.Permissions error",err);
	// 	})
	//   },[])

	useEffect(() => {
		forceLog("DisplayMap.Center",center);
		if (!center || center === [-26.20227, 28.04363]) {
			if ("geolocation" in navigator) {
				
				forceLog("DisplayMap.Center before request position",center);
				navigator.geolocation.getCurrentPosition((position) => {
					// console.log("Setting Location Center"+JSON.stringify(position))
					forceLog("DisplayMap.setCenter",position.coords);
					
					setCenter([position.coords.latitude, position.coords.longitude]);
				});
			} else {
				forceLog("DisplayMap.Center in Error",center);
				setCenter([-26.20227, 28.04363]);
			}
		}		
		forceLog("DisplayMap.Center After",center);
	}, [center]);

	const displayMapContainer = () => {
		forceLog("displayMapcontainer","")
		// return <div>Map goes Here</div>;
		return (<MapContainer center={center} zoom={zoom} scrollWheelZoom={true} ref={setMap}>
			{/* <JDTour user={user} /> */}
			<TileLayer
				attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
				url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
			/>
			<ErrorBoundary user={user} page='displayMap'>
				<ViewSearch clickMarker={clickMarker} />
				<ChangeView center={center} zoom={zoom} />

				<ErrorBoundary user={user} page='displayMap.login'>
					{!user ? (
						<>
							<Button
								id="mapLogin"
								name='login'
								data-tut='tourLogin'
								style={{ position: "absolute", right: "30px", bottom: "120px", zIndex: "1000" }}
								className='rounded-circle'
								onClick={(ev) => {
									window.location.hash = "login";
								}}>
								Login
							</Button>
						</>
					) : (
						<PersonalMenu user={user} />
					)}
				</ErrorBoundary>

				<ErrorBoundary user={user} page='displayMap.about'>
					{!user ? (
						<Button
							id="mapAbout"
							style={{ position: "absolute", right: "30px", bottom: "180px", zIndex: "1025" }}
							className='rounded-circle'
							onClick={(ev) => {
								window.location.href = landingURL;
							}}>
							About
						</Button>
					) : null}
				</ErrorBoundary>

<CartButton />
				<ErrorBoundary user={user} page='displayMap.dev'>
					{user && user.admin === 10 ? <DevMenu map={map} user={user} /> : null}
				</ErrorBoundary>
					{user && user.admin > 0 ? <AdminMenu map={map} user={user} /> : null}
				<Shoe2
					id="mapShoe"
					style={{
						position: "absolute",
						right: "30px",
						bottom: "120px",
						zIndex: "999",
						fill: "purple",
					}}
				/>
			</ErrorBoundary>
		</MapContainer>)
	}
	forceLog("DisplayMap.render",center)
	return (
		<>
			{center ? 
				displayMapContainer()
			: null}
		</>
	);
}

export default DisplayMap;
