import { useState, useEffect, useContext } from "react";
import { Button, Collapse, Row, Col, Form } from "react-bootstrap";
import { ConfigContext } from "../context/config";
import { AuthenticationContext } from "../context/authentication";
import { Plus, Dash } from "react-bootstrap-icons";
import { Spinner } from "../components/spinner";
import Tracker from "../components/tracker";

const LevelItem = ({ id, level, checked, name, update, xs = 6, md = 3 }) => {
  const [saving, setSaving] = useState(0);
  const save = (id, checked, level) => {
    setSaving(1);
    update(id, checked, level);
    setTimeout(() => {
      setSaving(0);
    }, 1500);
  };
  return (
    <Col xs={xs} md={md}>
      <Form.Check
        type="checkbox"
        label={saving ? <Spinner /> : name}
        value={checked}
        onChange={(ev) => {
          save(id, ev.target.checked, level);
        }}
      />
    </Col>
  );
};

const CollapseItem = ({ item, update, dances }) => {
  const [saving, setSaving] = useState(0);
  const save = (id, checked) => {
    setSaving(1);
    update(id, checked, 1);
    setTimeout(() => {
      setSaving(0);
    }, 1500);
  };
  return (
    <div className="ms-4 mb-2">
      <Row>
        <Form.Check
          type="checkbox"
          label={saving ? <Spinner /> : item.name}
          value={
            dances && dances.filter((i) => i.genreid === item.id).length > 0
          }
          checked={
            dances && dances.filter((i) => i.genreid === item.id).length > 0
          }
          onChange={(ev) => {
            save(item.id, ev.target.checked, 1);
          }}
        />
      </Row>
    </div>
  );
};
const TypeCollapse = ({ type, items, dances, update }) => {
  const [open, setOpen] = useState(false);
  return (
    <div className="mb-2">
      <Button
        variant="outline-primary"
        size="sm"
        id={`genre${type.name}`}
        onClick={() => setOpen(!open)}
        aria-controls="example-collapse-text"
        aria-expanded={open}
      >
        {open ? <Dash /> : <Plus />}
      </Button>{" "}
      {type.name}
      <Collapse in={open}>
        <div id="example-collapse-text">
          {items ? (
            <>
              {items.map((item) => (
                <CollapseItem
                  key={item.id}
                  id={type.id + "-" + item.id}
                  item={item}
                  update={update}
                  dances={dances}
                />
              ))}
            </>
          ) : null}
        </div>
      </Collapse>
    </div>
  );
};

const TypeSimple = ({ genreitem, items, dances, update }) => {
  return (
    <div className="mb-2 simple">
        {items ? (
          <>
            {items.map((item) => (
              <LevelItem
                key={item.id}
                xs="12"
                md="12"
                update={update}
                id={item.id}
                checked={
                  dances.filter((i) => i.genreid === item.id && i.level === 1)
                    .length > 0
                }
                level={1}
                name={item.name}
              />
            ))}
          </>
        ) : null}
    </div>
  );
};

const TeacherOfferings = () => {
  const {
    offerType,
    setFilterType,
    getGenresForType,
    teacherDances,
    changeTeacherDance,
  } = useContext(ConfigContext);
  const { user } = useContext(AuthenticationContext);

  useEffect(() => {
    if (!user) {
      window.location.hash = "login";
    }
  }, [user]);

  useEffect(() => {
    if (user && user.accountlevel) {
      setFilterType(user.accountlevel);
    }
  }, [user, setFilterType]);

  const onSave = () => {
    window.location.hash = "#profile/subs";
  };

  return (
    <Tracker itemtype="teacherOfferings" itemid={user.id}>
    <div>
      <div>
        <Button style={{ float: "right" }} onClick={onSave}>
          Save
        </Button>
      </div>
      {offerType.map((item) => {
        switch (item.forlevel) {
          case 5:
            return (
              <TypeCollapse
                key={item.id}
                type={item}
                items={getGenresForType(item.id === 0 ? 5 : item.id)}
                dances={teacherDances}
                update={changeTeacherDance}
              />
            );
          case 6:
            return (
              <TypeSimple
                key={item.id}
                type={item}
                items={getGenresForType(item.id)}
                dances={teacherDances}
                update={changeTeacherDance}
              />
            );
          case 7:
            return (
              <TypeSimple
                key={item.id}
                type={item}
                items={getGenresForType(item.id)}
                dances={teacherDances}
                update={changeTeacherDance}
              />
            );
          case 8:
            return (
              <TypeSimple
                key={item.id}
                type={item}
                items={getGenresForType(item.id)}
                dances={teacherDances}
                update={changeTeacherDance}
              />
            );
          case 9:
            return (
              <TypeSimple
                key={item.id}
                type={item}
                items={getGenresForType(item.id)}
                dances={teacherDances}
                update={changeTeacherDance}
              />
            );
          default:
            return <></>;
        }
      })}
    </div>
    </Tracker>
  );
};

export default TeacherOfferings;
