import { useContext } from "react"
import { Offcanvas, Button, CloseButton } from "react-bootstrap"
import Stars from "../components/stars";
import { forceLog } from "../components/utils";
import { LocationsContext } from "../context/locations"

function LocationListView({map, show, handleClose}) {
	if (show) {
		forceLog("LocationListView")
	}

    const { locations } = useContext(LocationsContext)

	return (
		<Offcanvas id="locationListView" placement={"bottom"} className='locationList location' show={show} style={{height:"90vh"}}>
				<Offcanvas.Header>
					<Offcanvas.Title>List view</Offcanvas.Title>
					<CloseButton onClick={handleClose}/> 
				</Offcanvas.Header>
				<Offcanvas.Body>
				{locations.map((item,index) => {
					if (!item.data) { return null }
					let position = [item.data.teacherlat, item.data.teacherlng]
					return (<div key={item.data.id} className="locationListItem listViewItem" style={{ borderRadius: "1em", border: "1px solid lightgray", padding: "1em", marginBottom:"1em" }}>
							<Offcanvas.Title>
								{item.name}
								
								<div style={{"float":"right"}}><Stars number={item.data.stars}/></div>
							</Offcanvas.Title>
							{item.data.teacherbio}<br/>
							<Button id={`btn${index}`} size="sm" onClick={() => { map.flyTo(position); handleClose() }}>Show On Map</Button>
						</div>)
					})}
				</Offcanvas.Body>
			</Offcanvas>
	);
}

export default LocationListView;
