import React, { useContext, useRef } from "react";
import { Row, Col, Image, Button, ButtonGroup } from "react-bootstrap";
import { AuthenticationContext } from "../../context/authentication";
import { JuztDance, rootURL } from "../../components/utils";
import LoveFestBooking from "./lovefestbooking";

const LoveFest = ({ event }) => {
  const { user } = useContext(AuthenticationContext);
  const bookSection = useRef();

  return (
    <Row>
      <Row>
        <Col xs={9}><h1>Love Fest</h1>
      <p>
        Sponsored by <span className="juztdance">Juzt.Dance</span>
      </p></Col>
        <Col xs={3}><Button
        onClick={() =>
          bookSection.current.scrollIntoView({
            behavior: "smooth",
            block: "start",
          })
        }
      >
        Book Now
      </Button></Col>
      </Row>
      
      
      <Image
        src={`${rootURL}files/lovefestlogo.jpg`}
        style={{ width: "100%" }}
      />
      <h3>About the weekend</h3>
      <h4>Amphitheater</h4>
      <p>
        The <span className="juztdance">Juzt.Dance</span> Love Fest 2024 will be
        held in the Drakensberg's spectacular Amphitheater. With easy access
        from JHB or DBN.
      </p>
      <h4>Workshops</h4>
      <p>
        Workshops will be on Saturday morning and Sunday morning. Each will be
        about 90 mins with never before seen content, designed to fit your level
      </p>
      <h4>Parties</h4>
      <p>Parties on Friday night, Saturday night and Sunday afternoon.</p>
      <Image
        src={`${rootURL}files/lovefesttransport.jpg`}
        style={{ width: "100%" }}
      />
      <h3>Need Transport</h3>
      <p>
        KES rentals is a <span className="juztdance">JUZT.DANCE</span> partner
        and a fellow dancer! Costs include pick up and drop off from Jozi or
        Durban and includes all insurance. Click here to connect with Tumi and
        secure your spot
      </p>
      <div className="mb-3">
        <Button
          variant="event"
          href="https://wa.me/+27840651539?text=Love Fest Transport Booking"
          target="_blank"
          rel="noreferrer"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="22"
            fill="currentColor"
            viewBox="0 0 16 16"
          >
            <path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z" />
          </svg>
          <span className="ms-3">Book Transport</span>
        </Button>
      </div>
      <Image
        src={`${rootURL}files/lovefestlocation.jpg`}
        style={{ width: "100%" }}
      />
      <h3>Accomodation</h3>
      <p>
        The venue has 8 villas available. Each villa sleeps 6-8 people Package
        includes breakfast and dinner The venue also has a restaurant.
      </p>
      <p>Payment can be made in 3 parts:</p>
      <ol style={{ marginLeft: "2rem" }}>
        <li>R1000 Deposit secures your spot now</li>
        <li>R1000 part payment by 15th January </li>
        <li>Balance of R1600 by 3 February </li>
      </ol>
      <p>Bank Details</p>
      <p style={{ marginLeft: "2rem" }}>
        Atum Creat11ons
        <br />
        Standard Bank
        <br />
        Account number: 00200280589
        <br />
        Reference: Love24 [your name]
      </p>
      <Image
        src={`${rootURL}/files/lovefestface.jpg`}
        style={{ width: "100%" }}
      />
      <h3>The Incredible Leigh Nathan</h3>
      <p>
        Leigh Nathan is recognized as one of the leading Bachata instructors in
        South Africa today
      </p>
      <p>
        Leigh will be offering new techniques musicality insights and dedicated
        support
      </p>
      <p> South Africa is officially a Bachata dance destination</p>
      <p>
        <strong>Vamos!!!</strong>
      </p>
      <h3 ref={bookSection}>Booking</h3>
      {user ? (
        <LoveFestBooking event={event} />
      ) : (
        <div
          className="p-3 m-3"
          style={{
            width: "95%",
            borderRadius: "10px",
            border: "2px solid purple",
          }}
        >
          <p>
            You need to be a logged <JuztDance /> user to register for Love Fest
            2024.
          </p>
          <ButtonGroup>
            <Button variant="primary" href="#login">
              Login
            </Button>
            <Button variant="outline-primary" href="#register">
              Register on Juzt.Dance
            </Button>
          </ButtonGroup>
        </div>
      )}
    </Row>
  );
};

export default LoveFest;
