import React, { useState, useContext, useEffect } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import { AuthenticationContext } from "../../context/authentication";
import { serverURL } from "../../components/utils";
import Tracker from "../../components/tracker";

const LoveFestBooking = ({ event }) => {
  const { user } = useContext(AuthenticationContext);
  const [lead, setlead] = useState(false);
  const [follow, setfollow] = useState(false);
  const [leadLevel, setleadLevel] = useState(0);
  const [followLevel, setfollowLevel] = useState(0);
  const [booked, setBooked] = useState(false);

  useEffect(() => {
    if (!user) {
      setBooked(false);
      return
    }
    fetch(
      `${serverURL}/api/booking/getbooking.php?eventid=${1049}&profileid=${user.id}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.errors) {
          throw res;
        }
        if (res.data.length > 0) {
          setBooked(true);
        }
      })
      .catch((err) => {
        setBooked(false);
        console.error("ERROR Admin Teacher Load", err);
      });
  }, [event, user]);

  const book = () => {
    const data = {
      eventid: 1049, // event.id,
      profileid: user.id,
      status: 1,
      data: `{"lead":${lead},"leadlevel":${leadLevel}, "follow":${follow}, "followlevel": ${followLevel}}`,
    };
    fetch(serverURL + "/api/booking/api.php/booking", {
      method: "POST",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((res) => {
        setBooked(true);
      })
      .catch((err) => {
        console.error("Upload error", err);
      });
  };

  if (booked) {
    return (
      
      <Tracker itemtype="eventbooking-booked" itemid={event.locid ?? event.id}>
      <div
        className="p-3 m-3"
        style={{
          width: "95%",
          borderRadius: "10px",
          border: "2px solid purple",
        }}
      >
        <h1>Thank you for booking</h1>
        <p>
          We will be in touch with you soon to confirm your booking and payment
          details.
        </p>
      </div>
      </Tracker>
    );
  }

  return (
    
    <Tracker itemtype="eventbooking" itemid={event.locid ?? event.id}>
    <Form
      className="p-3 m-3"
      style={{ width: "95%", borderRadius: "10px", border: "2px solid purple" }}
    >
      <Form.Group as={Row} className="mb-3" controlId="UserName">
        <Form.Label column sm="2">
          Full Name
        </Form.Label>
        <Col sm="9">
          <Form.Control
            plaintext
            readOnly
            defaultValue={user.firstname + " " + user.lastname}
          />
        </Col>
        <Form.Group as={Row} className="mb-1" controlId="UserName">
          <Form.Label column sm="4">
            Do you Lead
          </Form.Label>
          <Col sm="4">
            <Form.Check
              inline
              type="radio"
              label={`Yes`}
              id={`disabled-default-lead`}
              checked={lead}
              onChange={(e) => setlead(e.target.checked)}
            />
            <Form.Check
              inline
              type="radio"
              label={`No`}
              id={`disabled-default-lead`}
              checked={!lead}
              onChange={(e) => setlead(e.target.checked)}
            />
          </Col>
          <Col sm="4">
            <Form.Select
              aria-label="Default select example"
              value={leadLevel}
              onChange={(e) => {
                setleadLevel(e.target.value);
              }}
            >
              <option>Your Level</option>
              <option value="1">Beginner</option>
              <option value="2">Improver</option>
              <option value="3">Intermediate</option>
            </Form.Select>
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-1" controlId="UserName">
          <Form.Label column sm="4">
            Do you Follow
          </Form.Label>
          <Col sm="4">
            <Form.Check
              inline
              type="radio"
              label={`Yes`}
              id={`disabled-default-lead`}
              checked={follow}
              onChange={(e) => setfollow(e.target.checked)}
            />
            <Form.Check
              inline
              type="radio"
              label={`No`}
              id={`disabled-default-lead`}
              checked={!follow}
              onChange={(e) => setfollow(e.target.checked)}
            />
          </Col>
          <Col sm="4">
            <Form.Select
              aria-label="Default select example"
              value={followLevel}
              onChange={(e) => {
                setfollowLevel(e.target.value);
              }}
            >
              <option>Your Level</option>
              <option value="1">Beginner</option>
              <option value="2">Improver</option>
              <option value="3">Intermediate</option>
            </Form.Select>
          </Col>
        </Form.Group>
        <Row>
          <Col sm={4}></Col>
          <Col>
            <small>You can select both Lead and Follow</small>
          </Col>
        </Row>
        <Row>
          <Col>
            <Button
              variant="primary"
              onClick={() => {
                book();
              }}
            >
              Book
            </Button>
          </Col>
        </Row>
      </Form.Group>
    </Form>
    </Tracker>
  );
};

export default LoveFestBooking;
