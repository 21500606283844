import { useState, useContext, useEffect } from "react";
import { Button, Form, Row, Col, InputGroup } from "react-bootstrap";
import { AuthenticationContext } from "../context/authentication";
import { serverURL, imageURL } from "../components/utils";
import { Facebook, Instagram, Tiktok, Twitter, Youtube } from "react-bootstrap-icons";
import Tracker from "../components/tracker";

function Profile({ map, show }) {

  const { user, updateProfile } = useContext(AuthenticationContext);

  useEffect(() => {
    if (!user) {
      window.location.hash = "login";
    }
  }, [user]);

  const [phone, setPhone] = useState("");
  const [first, setFirst] = useState("");
  const [last, setLast] = useState("");
  const [pic, setPic] = useState("");
  const [bio, setBio] = useState("");
  const [level, setLevel] = useState("");
  const [facebook, setFacebook] = useState("");
  const [tiktok, setTiktok] = useState("");
  const [twitter, setTwitter] = useState("");
  const [instagram, setInstagram] = useState("");
  const [youtube, setYoutube] = useState("");
  const [booking, setBooking] = useState("");


  const [selectedFile, setSelectedFile] = useState("");
  const [preview, setPreview] = useState("");

  useEffect(() => {
    if (user) {
      setFirst(user.firstname);
      setLast(user.lastname);
      setPhone(user.phonenumber);
      setBio(user.teacherbio);
      setPic(user.avatar);
      setPreview(user.avatar);
      setLevel(user.accountlevel);
      setFacebook(user.facebook);
      setTiktok(user.tiktok);
      setTwitter(user.twitter);
      setInstagram(user.instagram);
      setYoutube(user.youtube);
      setBooking(user.booking);
	  // console.log("Level",user.accountlevel)
    }
  }, [user]);

  const [validated, setValidated] = useState(false);
  const [fileData, setFileData] = useState(null);

  const encodeImageFileAsURL = (file) => {
    let reader = new FileReader();
    reader.onloadend = function () {
      setFileData(reader.result);
      // console.log('AsDataURL RESULT', reader.result)
    };
    reader.readAsDataURL(file);
  };

  const uploadProfileImage = (event) => {
    //console.log(imageData);
    return new Promise((resolve, reject) => {
      let data = { userid: user.id, img: fileData.substring(fileData.indexOf("base64,")+7) };
      // console.log("Profile Upload image",data)
      fetch(serverURL + "/api/uploadimage.php?userid=" + user.id, {
        method: "POST",
        headers: {
          "Accept": "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then(async (response) => {
          // console.log("Profile Image uploaded", response)
          resolve();
        })
        .catch((err) => {
          console.error("Profile Upload error", err);
          reject();
        });
    });
  };

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    if (form.checkValidity() === false) {
    } else {
      let params = {
        firstname: first,
        lastname: last,
        phonenumber: phone,
        level: level,
        bio: bio,
        facebook: facebook,
        tiktok: tiktok,
        twitter: twitter,
        instagram: instagram,
        youtube: youtube,
        booking: booking,
      };
      if (selectedFile) {
        uploadProfileImage(event, preview).then(() => {
          updateProfile(params);
        });
      } else {
        updateProfile(params);
      }
      handleClose();
    }

    setValidated(true);
  };

  const handleClose = () => {
    window.location.hash = "";
  };

  // create a preview as a side effect, whenever selected file is changed
  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);
    encodeImageFileAsURL(selectedFile);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  const onSelectFile = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined);
      return;
    }

    // I've kept this example simple by using the first image instead of multiple
    setSelectedFile(e.target.files[0]);
  };

  return (
    <Tracker itemtype={"profile"} id={user.id}>
    <Form noValidate validated={validated} onSubmit={handleSubmit}>
      <Row className="mb-3">
        <Form.Group as={Col} md="12" controlId="validationCustom01">
          <Form.Label>Profile Type</Form.Label>
          <Form.Select
            aria-label="profile type"
            value={level}
            onChange={(ev) => {
              setLevel(ev.target.value);
            }}
          >
            <option value="0">Dancer</option>
            <option value="5">Teacher</option>
            <option value="6">DJ</option>
            <option value="7">Event</option>
            <option value="8">Venue</option>
            <option value="9">Supplier</option>
          </Form.Select>
        </Form.Group>
      </Row>
      <Row className="mb-3">
        { user.accountlevel < 7 ?
        <>
        <Form.Group as={Col} md="6" controlId="validationCustom01">
          <Form.Label>First name</Form.Label>
          <Form.Control
            required
            type="text"
            placeholder="First name"
            value={first}
            onChange={(ev) => setFirst(ev.target.value)}
          />
          <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="6" controlId="validationCustom02">
          <Form.Label>Last name</Form.Label>
          <Form.Control
            required
            type="text"
            placeholder="Last name"
            value={last}
            onChange={(ev) => setLast(ev.target.value)}
          />
          <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
        </Form.Group>
        </> : 
        <Form.Group as={Col} md="12" controlId="validationCustom01">
        <Form.Label>Name</Form.Label>
        <Form.Control
          required
          type="text"
          placeholder="Name"
          value={first}
          onChange={(ev) => setFirst(ev.target.value)}
        />
        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
      </Form.Group> }
        <Form.Group as={Col} md="6" controlId="validationCustomUsername">
          <Form.Label>Phone Number</Form.Label>
          {user.accountlevel >= 5 ? (
            <Form.Control
              type="tel"
              placeholder="Phone number"
              aria-describedby="inputGroupPrepend"
              required
              value={phone}
              onChange={(ev) => setPhone(ev.target.value)}
            />
          ) : (
            <Form.Control
              type="tel"
              placeholder="Phone number"
              aria-describedby="inputGroupPrepend"
              value={phone}
              onChange={(ev) => setPhone(ev.target.value)}
            />
          )}
          <Form.Control.Feedback type="invalid">
            Please enter a phone number so that people can contact you.
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group
          as={Col}
          md="6"
          controlId="validationBio"
          style={{ marginTop: "1rem" }}
        >
          <InputGroup>
            <InputGroup.Text>Bookings</InputGroup.Text>
            <Form.Control
              type="text"
              aria-label="Facebook link"
              placeholder="Link to where people can make bookings"
              value={booking}
              onChange={(ev) => {
                setBooking(ev.target.value);
              }}
            />
          </InputGroup>
        </Form.Group>
        <Form.Group
          as={Col}
          md="6"
          controlId="validationBio"
          style={{ marginTop: "1rem" }}
        >
          <InputGroup>
            <InputGroup.Text>About you</InputGroup.Text>
            <Form.Control
              as="textarea"
              aria-label="With textarea"
              value={bio}
              onChange={(ev) => {
                setBio(ev.target.value);
              }}
            />
          </InputGroup>
        </Form.Group>
        <Form.Group
          as={Col}
          md="6"
          controlId="validationBio"
          style={{ marginTop: "1rem" }}
        >
          <InputGroup>
            <InputGroup.Text><Facebook className="me-2"/> Facebook Handle</InputGroup.Text>
            <Form.Control
              type="text"
              aria-label="Facebook link"
              placeholder="yourname"
              value={facebook}
              onChange={(ev) => {
                setFacebook(ev.target.value);
              }}
            />
          </InputGroup>
        </Form.Group>
        <Form.Group
          as={Col}
          md="6"
          controlId="validationBio"
          style={{ marginTop: "1rem" }}
        >
          <InputGroup>
            <InputGroup.Text><Twitter className="me-2"/> Twitter Handle</InputGroup.Text>
            <Form.Control
              type="text"
              aria-label="Twitter link"
              placeholder="yourname"
              value={twitter}
              onChange={(ev) => {
                setTwitter(ev.target.value);
              }}
            />
          </InputGroup>
        </Form.Group>
        <Form.Group
          as={Col}
          md="6"
          controlId="validationBio"
          style={{ marginTop: "1rem" }}
        >
          <InputGroup>
            <InputGroup.Text><Tiktok className="me-2"/> Tiktok Handle</InputGroup.Text>
            <Form.Control
              type="text"
              aria-label="Tiktok link"
              placeholder="yourname"
              value={tiktok}
              onChange={(ev) => {
                setTiktok(ev.target.value);
              }}
            />
          </InputGroup>
        </Form.Group>
        <Form.Group
          as={Col}
          md="6"
          controlId="validationBio"
          style={{ marginTop: "1rem" }}
        >
          <InputGroup>
            <InputGroup.Text><Youtube className="me-2"/> Youtube Handle</InputGroup.Text>
            <Form.Control
              type="text"
              aria-label="Youtube link"
              placeholder="yourname"
              value={youtube}
              onChange={(ev) => {
                setYoutube(ev.target.value);
              }}
            />
          </InputGroup>
        </Form.Group>
        <Form.Group
          as={Col}
          md="6"
          controlId="validationBio"
          style={{ marginTop: "1rem" }}
        >
          <InputGroup>
            <InputGroup.Text><Instagram className="me-2"/> Instagram Handle</InputGroup.Text>
            <Form.Control
              type="text"
              aria-label="Instagram link"
              placeholder="yourname"
              value={instagram}
              onChange={(ev) => {
                setInstagram(ev.target.value);
              }}
            />
          </InputGroup>
        </Form.Group>
      </Row>
      <Row>
        <Form.Group as={Col} md={6} controlId="formFile" className="mb-3">
          <Form.Label>Upload a image or logo</Form.Label>
          <Form.Control
            type="file"
            onChange={onSelectFile}
            accept="image/png,image/jpeg"
          />
        </Form.Group>
        <Form.Group as={Col} md={6} controlId="formFile" className="mb-3">
          {selectedFile ? (
            <>
              <Form.Label>Preview</Form.Label>
              <br />
              <Form.Label>
                <img
                  alt="User Profle Preview"
                  style={{ minWidth: "50%", maxWidth: "75%" }}
                  src={preview}
                />
              </Form.Label>
            </>
          ) : pic ? (
            <>
              <Form.Label>Preview</Form.Label>
              <br />
              <Form.Label>
                <img
                  alt="User Profile"
                  style={{ minWidth: "50%", maxWidth: "75%" }}
                  src={`${imageURL}${pic}`}
                />
              </Form.Label>
            </>
          ) : <div>No Image Available, {pic}</div>}
        </Form.Group>
      </Row>

      {/* <Form.Group className='mb-3'>
                <Form.Check
                    required
                    label='Agree to terms and conditions'
                    feedback='You must agree before submitting.'
                    feedbackType='invalid'
                />
            </Form.Group> */}
      <Button type="submit">Save</Button>
    </Form>
    </Tracker>
  );
}

export default Profile;
