import React from "react";
import { Button } from "react-bootstrap";
import { PlusSquare } from "react-bootstrap-icons";
import useIsMobile from "../../hooks/useismobile";

const AddButton = ({ onClick, right = "false", ...params }) => {
  const isMobile = useIsMobile();
  const style = {};
  if (right === "true") {
    style.float = "right";
  }
  return (
    <Button style={style} variant="outline-primary" onClick={onClick} {...params}>
      {isMobile ? <PlusSquare /> : <>Add</>}
    </Button>
  );
};

export default AddButton;
