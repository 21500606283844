import { Cart4 } from "react-bootstrap-icons";
import { Button } from "react-bootstrap";
import { useFeatureFlag } from "../../../context/usefeatureflags";

const CartButton = () => {
    const { isFeatureEnabled } = useFeatureFlag();

    if (!isFeatureEnabled("store")) {
        return null;
    }
  return (
    <Button
      variant="primary"
      style={{
        position: "absolute",
        right: "40px",
        height: "45px",
        bottom: "190px",
        zIndex: "999",
        width: "90px",
        borderRadius: "50px",
      }}
      onClick={()=>window.location.hash="#cart"}
    >
      <Cart4 size="24" />
      <div
        style={{
          backgroundColor: "white",
          color: "black",
          borderRadius: "10px",
          position: "relative",
          minWidth:"18px",
          display: "inline-block",
          fontSize:"12px",
          top:"-5px"
        }}
      >
        5
      </div>
    </Button>
  );
};

export default CartButton;
