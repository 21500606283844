import {
  Row,
  Col,
  Form,
  Table,
  Button,
  InputGroup,
  Spinner,
  Alert,
  Badge,
} from "react-bootstrap";
import { PencilSquare, PeopleFill } from "react-bootstrap-icons";
import ProfileBadge from "../../components/profilebadge";
import AddButton from "../../components/buttons/addbutton";
import { calculateAgeInDays } from "../../components/utils";
import { useUser } from "../../context/useuser";

const ListItems = (props) => {
  const { items, setItem, pagePath, count } = props;
  const { getUserProperty } = useUser();

  // Modify user property (properties table) to allow a user more items
  const maxItems = getUserProperty("maxLocations",3);

  if (!items) {
    return <Spinner animation="border" />;
  }

  const editRow = (row) => {
    setItem(row);
  };

  return (
    <div>
      <Row>
        <Col style={{ textAlign: "right" }}>
          <InputGroup>
            <InputGroup.Text id="inputGroup-sizing-default">
              Search
            </InputGroup.Text>
            <Form.Control
              value={items.filterValue}
              onChange={(ev) => items.setFilterValue(ev.target.value)}
            />
            <Button variant="outline-primary">X</Button>
          </InputGroup>
        </Col>
        <Col xs={3}>
          <AddButton right="true" disabled={count >= maxItems}
            onClick={() => (window.location.hash = pagePath + "/add")} />
        </Col>
      </Row>
      {count >= maxItems && (
        <Row>
          <Alert className="m-3" style={{width:"calc(100% - 32px)"}} variant={"warning"}>Maximum number of Locations has been reached.
          </Alert>          
        </Row>
      )}
      <div className="m mt-3" />
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>
              <Button
                variant=""
                onClick={() => {
                  items.setSortField("header");
                }}
              >
                Location Type
              </Button>
            </th>
            <th>
              <Button
                variant=""
                onClick={() => {
                  items.setSortField("header");
                }}
              >
                Alt Name
              </Button>
            </th>
            <th>
              <Button
                variant=""
                onClick={() => {
                  items.setSortField("date");
                }}
              >
                Expires
              </Button>
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {items.data &&
            items.data.map((row) => {
              return (
                <tr key={row.id}>
                  <td>
                    <ProfileBadge loctype={row.loctype} subtype={row.subtype} />
                  </td>
                  <td>
                    {row.altname ?? (
                      <span
                        style={{
                          fontWeight: "200",
                          fontStyle: "italic",
                          color: "lightgrey",
                        }}
                      >
                        Display actual name
                      </span>
                    )}
                  </td>
                  <td>
                    {row.expires} 
                    {calculateAgeInDays(row.expires) > 0 && (<Badge className="ms-3" bg="warning">Expired</Badge>)}
                    </td>
                  <td>
                    <Button
                      variant="outline-primary"
                      onClick={() => editRow(row)}
                    >
                      <PencilSquare />
                    </Button>
                    {row.managebookings === 1 && (
                      <Button
                        variant="outline-primary"
                        onClick={() => {
                          window.location.hash =
                            pagePath+"/attend?id=" + row.id;
                        }}
                      >
                        <PeopleFill />
                      </Button>
                    )}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </Table>
    </div>
  );
};

export default ListItems;
