import {  useContext, useState } from "react";
import { Container, Card, Button, Row, Col } from "react-bootstrap";
import {
  JuztDance,
  Dance2,
  RoleColor,
  RoleTextColor
} from "../../components/utils";
import { AuthenticationContext } from "../../context/authentication";
import Invoice from "./invoice";

// const danceimage = "/subimage.png";

const PricingHeader = () => (
  <div className="pricing-header px-3 py-3 pt-md-5 pd-md-4 mx-auto text-center">
    <h1 className="display-4">Pricing</h1>
    {/* <div className="lead center">
            <div className="row" style={{ "textAlign": "left" }}>
                
                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 col-ss-12 margbot30">
                    <h3>Be <b>shown</b> directly to dancers</h3>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 col-ss-12 margbot30">
                    <h3>Post your <b>news</b></h3>
                </div>
                
                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 col-ss-12 margbot30">
                    <h3>Get <b>affiliated</b> with events!<p></p></h3>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 col-ss-12 margbot30">
                    <h3>Share your <b>QR Code</b> and earn <b>rewards</b>!<p></p></h3>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 col-ss-12 margbot30">
                    <h3><b>Promote</b> and <b>grow</b> your dance school with in–app tools (Coming soon)</h3>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 col-ss-12 margbot30">
                    <h3><b>Calendar</b>-ised views showing your classes and availability for lessons – syncs with existing diary tools (Coming soon)</h3>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 col-ss-12 margbot30">
                    <h3><b>Mini</b>-app options so you never have to build your own (Comping Soon)</h3>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 col-ss-12 margbot30">
                    <h3><b>No extra costs</b> pay one monthly amount, only!<p></p></h3>
                </div>
            </div>
        </div> */}
  </div>
);

const planContents = [
  {
    accountlevel: 5,
    header: "Teacher",
    price: 6,
    price12: 60,
    features: [
      { name: "Locations", value: "1" },
      { name: "Map visibility", value: "Yes" },
      { name: "News Items", value: "4/month" },
      { name: "QR code (and rewards)", value: "Yes" },
      { name: "Event Affiliation", value: "Yes" },
    ],
    buttonLabel: "Sign up",
    buttonLabel2: "Sign up for 12 Months",
    outline: true,
  },
  {
    accountlevel: 6,
    header: "DJ/Musician",
    price: 6,
    price12: 60,
    features: [
      { name: "Locations", value: "1" },
      { name: "Map visibility", value: "Yes" },
      { name: "News Items", value: "1/month" },
      { name: "QR code (and rewards)", value: "Yes" },
      { name: "Event Affiliation", value: "Yes" },
    ],
    buttonLabel: "Sign up",
    buttonLabel2: "Sign up for 12 Months",
    outline: true,
  },
  {
    accountlevel: 7,
    header: "Event",
    price: 12,
    price12: 120,
    period: "event",
    features: [
      { name: "Locations", value: "1" },
      { name: "Map visibility", value: "Yes" },
      { name: "News Items", value: "1/month" },
      { name: "QR code (and rewards)", value: "Yes" },
      { name: "Event Affiliation", value: "Yes" },
    ],
    buttonLabel: "Sign up",
    buttonLabel2: "Sign up for 12 Months",
    outline: true,
  },
  {
    accountlevel: 8,
    header: "Venue",
    price: 12,
    price12: 120,
    features: [
      { name: "Locations", value: "1" },
      { name: "Map visibility", value: "Yes" },
      { name: "News Items", value: "1/month" },
      { name: "QR code (and rewards)", value: "Yes" },
      { name: "Event Affiliation", value: "Yes" },
    ],
    buttonLabel: "Sign up",
    buttonLabel2: "Sign up for 12 Months",
    outline: true,
  },
  {
    accountlevel: 9,
    header: "Supplier",
    price: 12,
    price12: 120,
    features: [
      { name: "Locations", value: "1" },
      { name: "Map visibility", value: "Yes" },
      { name: "News Items", value: "1/month" },
      { name: "QR code (and rewards)", value: "Yes" },
      { name: "Event Affiliation", value: "Yes" },
    ],
    buttonLabel: "Sign up",
    buttonLabel2: "Sign up for 12 Months",
    outline: true,
  },
];

const Plan = (props) => {
  console.log("Plan Props", props);
  return (
    <Col sm={12} md={6} lg={4}>
      <Card>
        <Card.Header
          style={{
            backgroundColor: RoleColor(props.accountlevel),
            color: RoleTextColor(props.accountlevel),
          }}
        >
          <h4
            className="my-0 font-weight-normal"
            style={{ textAlign: "center" }}
          >
            {props.header}
          </h4>
        </Card.Header>
        <Card.Body>
          <h1
            className="card-title pricing-card-title"
            style={{ textAlign: "center" }}
          >
            {`$${props.price}`}
            {props.period ? (
              <small className="text-muted">/ {props.period}</small>
            ) : (
              <small className="text-muted">
                / mo{" "}
                <span style={{ fontSize: "12px" }}>
                  {" "}
                  ({`$${props.price12} for 12 months)`}
                </span>
              </small>
            )}
          </h1>
          <ul
            className="list-unstyled mt-3 mb-4"
            style={{ textAlign: "center" }}
          >
            {props.features.map((feature, i) => {
              if (feature.name) {
                return (
                  <li key={i}>
                    <Row>
                      <Col style={{ textAlign: "right" }}>{feature.name}</Col>
                      <Col style={{ textAlign: "left" }}> {feature.value}</Col>
                    </Row>
                  </li>
                );
              } else {
                return <li key={i}>{feature}</li>;
              }
            })}
          </ul>

          <div className="col-md-12 text-center mt-1">
            <Button
              variant={"primary"}
              type="button"
              onClick={() => {
                props.onSelect(props.header, props.price, 1);
              }}
            >
              {props.buttonLabel}
            </Button>
            {props.price12 ? (
              <Button
                variant={"outline-primary"}
                type="button"
                className="ms-2"
                onClick={() => {
                  props.onSelect(props.header, props.price, 12);
                }}
              >
                {props.buttonLabel2}
              </Button>
            ) : null}
          </div>
        </Card.Body>
      </Card>
    </Col>
  );
};

const Plans = ({ role, onSelect }) => {
  const plans = planContents
    .filter((item) => item.accountlevel === role)
    .map((obj, i) => {
      return (
        <Plan
          key={obj.header}
          accountlevel={obj.accountlevel}
          header={obj.header}
          price={obj.price}
          price12={obj.price12}
          period={obj.period}
          features={obj.features}
          buttonLabel={obj.buttonLabel}
          buttonLabel2={obj.buttonLabel2}
          outline={obj.outline}
          onSelect={onSelect}
        />
      );
    });

  return (
    <Row>
      <Col sm={12} md={4} lg={4}></Col>
      {plans}
      <Col sm={12} md={4} lg={4}>
        {/* <Image style={{width:"100%"}} src={danceimage} alt="dancers" /> */}
      </Col>
    </Row>
  );
};



const ThankYou = (props) => {
  return (
    <Container>
      <Row className="align-items-center">
        <Col md={3} />
        <Col md={6} className="justify-content-md-center">
          <Dance2 color="purple" />
        </Col>
        <Col md={3} />
      </Row>
      <Row className="align-items-center">
        <Col md={5} xs={3} />
        <Col md={2} xs={6}>
          <div
            style={{ margin: "0 auto", textAlign: "center", fontSize: "2rem" }}
          >
            <JuztDance />
          </div>
        </Col>
        <Col md={5} xs={3} />
      </Row>
      <Row className="align-items-center">
        <Col md={3} xs={2} />
        <Col md={6} xs={8}>
          <div
            style={{
              margin: "0 auto",
              textAlign: "center",
              fontSize: "1.5rem",
            }}
          >
            Thank you for your payment.
          </div>
        </Col>
        <Col md={3} xs={2} />
      </Row>
      <Row className="align-items-center">
        <Col md={3} xs={2} />
        <Col md={6} xs={8}>
          <div
            style={{
              margin: "0 auto",
              textAlign: "center",
              fontSize: "1.5rem",
            }}
          >
            Never be without your dance fix again!
          </div>
        </Col>
        <Col md={3} xs={2} />
      </Row>
      <Row>
        <Col>
          <Button variant="primary" onclick={props.onClose}>
            Close
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

const TeacherSubs = () => {
  const { user } = useContext(AuthenticationContext);
  const [page, setPage] = useState("");
  const [price, setPrice] = useState(100);
  const [duration, setDuration] = useState(1);
  const [level, setLevel] = useState("Bronze");

  const onSelect = (inlevel, inprice, induration) => {
    console.log("PAY", inprice, induration);
    setPrice(inprice);
    setDuration(induration);
    setLevel(inlevel);
    setPage("pay");
  };
  console.log("Admin Profile");
  return (
    <div>
      {page === "pay" ? (
        <Invoice
          level={level}
          price={price}
          duration={duration}
          onPaymentSuccessful={() => setPage("paid")}
        />
      ) : page === "paid" ? (
        <ThankYou
          onClick={() => {
            setPage("");
            window.location.hash = "";
          }}
        />
      ) : (
        <>
          <PricingHeader />
          <Plans role={user.accountlevel} onSelect={onSelect} />
        </>
      )}
    </div>
  );
};

export default TeacherSubs;
