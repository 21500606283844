const localURL = "http://localhost/juztdance-php/";
const serverURL = "https://juzt.dance/php";
const imageURL = "https://juzt.dance/";
const landingURL = "https://juzt.dance/landing";
const rootURL = "https://juzt.dance/";

const JuztDance = () => {
  return <span className="juztdance">Juzt.Dance</span>;
};

const LogError = (user, page, err, info) => {
  let body = {
    user: user,
    page: page,
    error: err,
    errorinfo: info,
  };
  fetch(serverURL + "/gapi/api.php/errorboundary", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  })
    .then((res) => res.text())
    .then((res) => {
      console.log("Error saved successfully", res);
    })
    .catch((err) => {
      console.log("ERROR", err);
    });
};

const redirectWithParams = (hash, params) => {
  if (params.page === "refer") {
    hash = "register";
  }
  hash = "#" + hash;
  let p2 = "";
  Object.keys(params).forEach((item, index) => {
    if (p2.length > 0) {
      p2 += "&";
    }
    p2 += item + "=" + params[item];
  });
  window.location.href = "/" + hash + "?" + p2;
};

function Shoe2(props) {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width={props.width}
      height={props.height}
      viewBox="0 0 380.000000 378.000000"
      preserveAspectRatio="xMidYMid meet"
      id={props.id ? props.id : "shoe"}
    >
      <g
        transform="translate(0.000000,378.000000) scale(0.100000,-0.100000)"
        fill={props.color ? props.color : "white"}
        stroke="none"
      >
        <path
          d="M518 3630 c-69 -54 -78 -292 -18 -475 40 -122 108 -261 188 -380 89
  -134 144 -227 138 -233 -3 -3 1 -10 9 -16 8 -6 34 -51 58 -101 103 -211 157
  -386 178 -584 6 -58 13 -108 15 -110 21 -23 31 142 15 248 -18 113 -66 254
  -144 414 -59 124 -69 150 -57 158 12 8 11 9 -5 5 -16 -4 -30 11 -75 82 -30 48
  -79 120 -108 161 -180 252 -264 530 -221 730 22 102 85 133 138 69 14 -17 139
  -147 277 -287 138 -141 270 -282 294 -315 23 -32 67 -106 97 -165 30 -58 51
  -95 47 -81 -11 41 -73 160 -122 235 -28 42 -131 157 -261 290 -119 121 -248
  255 -286 298 -78 85 -108 96 -157 57z"
        />
        <path
          d="M356 3333 c-100 -200 -177 -448 -195 -633 -19 -183 22 -340 103 -393
  50 -33 61 -14 22 37 -37 49 -72 126 -85 184 -17 81 -13 235 8 340 23 107 102
  343 154 455 45 95 40 102 -7 10z"
        />
        <path
          d="M2110 3250 c-76 -13 -252 -87 -349 -148 -156 -96 -341 -264 -341
  -308 0 -21 22 -7 110 71 154 138 304 237 452 301 159 68 257 68 299 1 53 -85
  -48 -257 -205 -351 -142 -85 -309 -129 -580 -151 -159 -13 -458 -63 -509 -86
  -21 -9 -21 -9 3 -5 273 52 318 59 542 76 408 32 667 157 767 370 50 109 22
  197 -73 225 -27 8 -54 14 -60 14 -6 -1 -31 -5 -56 -9z"
        />
        <path
          d="M1000 2515 c0 -2 37 -32 83 -67 112 -86 246 -223 322 -332 71 -102
  167 -290 211 -411 58 -163 168 -353 261 -451 26 -28 33 -33 15 -12 -100 119
  -188 285 -288 543 -88 229 -203 420 -344 569 -94 99 -188 166 -232 166 -15 0
  -28 -2 -28 -5z"
        />
        <path
          d="M495 1920 c35 -153 37 -178 45 -560 5 -217 9 -441 9 -497 l1 -101 36
  -12 c32 -11 103 -7 140 7 12 4 14 71 13 382 0 207 -6 463 -12 570 -11 174 -14
  192 -27 176 -23 -28 -23 -175 -1 -660 15 -340 17 -441 8 -447 -20 -13 -127 -9
  -132 5 -2 6 -11 230 -19 497 -15 474 -23 565 -56 640 l-11 25 6 -25z"
        />
        <path
          d="M895 1710 c5 -8 64 -94 131 -190 67 -96 140 -209 162 -250 53 -99
  108 -248 143 -389 34 -138 50 -179 92 -240 208 -304 572 -413 1327 -398 524
  11 794 62 903 171 32 31 37 43 37 82 0 40 -5 51 -46 94 -70 75 -256 164 -449
  216 -93 25 -157 32 -139 15 5 -5 72 -33 149 -61 279 -102 438 -198 438 -263 0
  -56 -79 -106 -238 -152 -211 -61 -848 -96 -1180 -65 -399 38 -646 158 -797
  387 -39 58 -44 74 -92 256 -32 119 -94 278 -143 367 -20 36 -92 144 -161 240
  -115 162 -158 218 -137 180z"
        />
        <path
          d="M1120 1627 c0 -8 14 -34 31 -58 114 -164 173 -273 225 -418 13 -37
  21 -51 18 -31 -19 104 -120 341 -187 439 -50 73 -87 102 -87 68z"
        />
        <path
          d="M2466 1339 c-38 -5 -83 -14 -100 -19 -29 -9 -29 -9 9 -5 22 3 104 8
  182 11 149 7 233 -5 233 -32 0 -32 -121 -98 -299 -163 -143 -53 -224 -70 -576
  -121 -333 -48 -463 -74 -484 -95 -14 -13 -10 -15 34 -15 112 0 796 127 928
  172 165 56 345 141 392 184 34 31 32 50 -7 70 -40 21 -206 28 -312 13z"
        />
        <path
          d="M2809 1172 c-161 -91 -346 -232 -500 -383 -83 -80 -119 -131 -119
  -167 0 -19 3 -21 19 -12 11 5 48 35 83 65 35 31 158 122 273 202 116 80 215
  151 220 156 50 48 -372 -235 -479 -321 -155 -125 -77 -24 88 115 131 109 509
  363 542 363 9 0 14 6 12 13 -9 26 -55 16 -139 -31z"
        />
        <path
          d="M3015 1128 c-117 -42 -265 -238 -306 -408 -20 -85 -17 -227 7 -275
  10 -21 25 -41 33 -43 11 -4 13 5 7 49 -25 204 -14 308 49 436 40 81 138 189
  197 216 76 36 143 13 200 -68 17 -24 34 -43 36 -40 12 12 -68 102 -108 123
  -48 24 -69 26 -115 10z"
        />
        <path
          d="M3470 527 c0 -84 -150 -144 -420 -167 -80 -7 -147 -14 -149 -16 -19
  -18 328 10 444 36 67 15 146 59 164 92 18 32 6 81 -22 86 -13 3 -17 -4 -17
  -31z"
        />
      </g>
    </svg>
  );
}

function CopyToClipboard(props) {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width={props.width}
      height={props.height}
      fill={props.color ? props.color : "currentcolor"}
      viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        stroke="none"
      >
        <path
          d="M763 4895 c-81 -18 -151 -56 -208 -114 -62 -63 -82 -95 -105 -170
  -20 -62 -20 -95 -20 -1592 l0 -1529 210 0 210 0 0 1495 0 1495 1280 0 1280 0
  0 215 0 215 -1292 -1 c-1049 0 -1305 -3 -1355 -14z"
        />
        <path
          d="M1578 4034 c-112 -34 -224 -140 -271 -254 l-22 -55 -3 -1569 c-2
  -1416 -1 -1575 14 -1625 47 -164 173 -280 338 -310 83 -15 2409 -15 2492 0
  167 31 296 150 339 314 13 52 15 248 15 1600 0 1701 4 1602 -63 1714 -42 69
  -106 126 -187 165 l-65 31 -1270 2 c-1083 1 -1277 0 -1317 -13z m2472 -1899
  l0 -1495 -1170 0 -1170 0 0 1495 0 1495 1170 0 1170 0 0 -1495z"
        />
      </g>
    </svg>
  );
}

function Dance2(props) {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width={props.width}
      height={props.height}
      viewBox="0 0 767.000000 581.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,581.000000) scale(0.100000,-0.100000)"
        fill={props.color ? props.color : "white"}
        stroke="none"
      >
        <path
          d="M1820 5298 c-19 -5 -46 -12 -60 -15 -14 -3 -27 -9 -30 -14 -3 -4 -12
  -9 -20 -11 -25 -5 -119 -109 -126 -138 0 -3 -7 -18 -14 -35 -16 -34 -23 -186
  -10 -218 4 -12 14 -42 21 -67 7 -25 24 -66 38 -93 19 -36 27 -44 33 -32 5 8 5
  23 -1 32 -5 10 -9 20 -9 23 0 9 -14 124 -18 150 -7 47 17 144 49 198 34 57 73
  87 149 113 114 41 184 31 250 -33 49 -48 62 -49 53 -2 -7 37 -13 47 -55 90
  -55 56 -157 78 -250 52z"
        />
        <path
          d="M5509 5146 c-2 -3 -26 -6 -52 -7 -26 -2 -47 -6 -47 -11 0 -4 -9 -8
  -20 -8 -21 0 -35 -6 -85 -34 -16 -10 -38 -20 -47 -23 -10 -3 -18 -9 -18 -14 0
  -5 -7 -9 -16 -9 -8 0 -12 -4 -9 -10 3 -5 1 -10 -5 -10 -16 0 -93 -81 -109
  -115 -7 -17 -17 -38 -22 -47 -13 -26 -11 -58 4 -58 8 0 7 -4 -3 -10 -10 -6
  -11 -10 -2 -10 7 0 12 -13 12 -30 0 -16 5 -30 12 -30 6 0 9 -3 5 -6 -8 -8 18
  -34 35 -34 10 0 9 3 -2 10 -13 9 -13 10 0 10 12 0 13 2 1 9 -11 7 -12 25 -8
  77 4 38 11 70 17 72 5 2 10 10 10 17 0 8 8 16 19 20 16 5 32 16 79 55 6 6 12
  8 12 6 0 -3 15 3 34 14 19 10 43 20 53 22 10 2 21 7 25 13 11 15 161 12 166
  -3 2 -7 13 -12 24 -12 11 0 22 -8 25 -20 3 -11 9 -18 13 -15 4 2 13 -7 20 -20
  7 -14 17 -25 22 -25 4 0 8 -7 8 -15 0 -8 4 -15 8 -15 5 0 17 -27 28 -60 10
  -33 22 -60 26 -60 5 0 8 -11 8 -24 0 -14 4 -27 9 -30 9 -6 27 -83 23 -98 -1
  -5 2 -8 6 -8 5 0 10 -31 11 -70 2 -77 -18 -135 -50 -145 -10 -4 -19 -11 -19
  -16 0 -5 -9 -9 -20 -9 -24 0 -26 -16 -5 -36 12 -12 15 -12 15 -2 0 8 3 9 8 3
  10 -14 32 -17 32 -5 0 6 7 10 15 10 39 0 102 87 107 147 2 13 7 23 13 23 5 0
  3 5 -5 10 -8 5 -10 10 -5 10 6 0 9 26 7 65 -2 36 -7 65 -12 65 -7 0 -16 46
  -22 115 -2 17 -9 36 -16 43 -7 8 -10 18 -7 23 4 5 1 9 -4 9 -6 0 -11 14 -11
  30 0 17 -4 30 -10 30 -5 0 -10 9 -10 20 0 11 -5 20 -11 20 -5 0 -7 5 -4 10 3
  6 1 10 -4 10 -6 0 -11 6 -11 14 0 7 -18 32 -40 55 -22 22 -40 46 -40 52 0 5
  -4 8 -9 5 -5 -3 -14 0 -20 8 -11 14 -58 31 -73 28 -5 -1 -8 2 -8 7 0 5 -8 8
  -17 7 -10 -2 -21 1 -24 6 -3 4 -7 6 -10 4z"
        />
        <path
          d="M1420 4454 c-61 -10 -143 -37 -160 -54 -3 -3 -18 -12 -34 -20 -29
  -15 -146 -127 -146 -140 0 -4 -9 -17 -20 -30 -11 -13 -20 -29 -20 -37 0 -7 -4
  -13 -9 -13 -5 0 -13 -10 -17 -22 -20 -65 -260 -228 -335 -228 -11 0 -28 -4
  -37 -9 -34 -17 -120 -24 -278 -22 -109 2 -164 -1 -168 -8 -4 -6 -16 -11 -27
  -11 -10 0 -33 -13 -49 -29 -25 -23 -30 -36 -30 -75 0 -52 12 -60 29 -17 19 44
  72 56 166 36 82 -17 329 -21 379 -6 17 5 58 17 91 27 70 20 150 60 185 92 14
  12 28 22 32 22 24 0 158 162 158 190 0 5 3 10 8 10 4 0 15 17 24 39 22 51 121
  153 182 186 61 34 151 65 188 65 20 0 28 5 28 18 0 13 -13 21 -45 30 -46 12
  -50 12 -95 6z"
        />
        <path
          d="M2062 4385 c-11 -13 -9 -17 13 -25 15 -6 33 -10 40 -10 48 0 248
  -132 261 -172 3 -10 11 -18 17 -18 7 -1 32 -26 57 -57 25 -31 52 -63 60 -72 9
  -9 29 -34 44 -56 47 -65 153 -162 231 -211 74 -45 224 -114 251 -114 8 0 14
  -4 14 -9 0 -5 17 -12 38 -15 90 -16 102 -19 102 -26 0 -7 28 -12 155 -26 28
  -4 52 -10 53 -15 2 -5 10 -9 18 -9 8 0 14 -4 14 -10 0 -5 9 -10 20 -10 11 0
  20 -4 20 -10 0 -5 7 -10 15 -10 8 0 15 -4 15 -9 0 -4 35 -9 79 -10 60 -2 82 2
  94 14 9 8 20 15 26 15 6 0 11 9 11 20 0 11 5 20 10 20 6 0 10 3 10 8 0 11 -94
  1 -102 -12 -6 -8 -8 -7 -8 2 0 6 -9 12 -21 12 -20 0 -52 14 -73 31 -73 61
  -105 78 -160 89 -34 6 -69 16 -78 21 -10 5 -28 9 -41 9 -13 0 -27 3 -31 7 -9
  10 -78 33 -98 33 -10 0 -18 5 -18 10 0 6 -11 10 -25 10 -14 0 -25 5 -25 10 0
  6 -9 10 -20 10 -11 0 -20 5 -20 10 0 6 -12 10 -26 10 -14 0 -23 4 -19 10 4 6
  -5 10 -19 10 -14 0 -26 5 -26 10 0 6 -7 10 -15 10 -8 0 -15 5 -15 10 0 6 -5
  10 -11 10 -33 0 -183 126 -239 201 -14 18 -31 37 -38 43 -7 6 -42 39 -78 73
  -67 64 -169 140 -214 160 -70 30 -91 35 -160 40 -60 3 -78 1 -88 -12z"
        />
        <path
          d="M5793 4162 c-43 -4 -50 -28 -13 -47 16 -8 41 -16 55 -17 14 -1 25 -6
  25 -10 0 -4 9 -8 20 -8 11 0 20 -4 20 -10 0 -5 10 -10 23 -10 12 0 29 -9 37
  -20 8 -11 20 -20 28 -20 11 0 54 -41 132 -127 11 -12 20 -27 20 -33 0 -6 11
  -22 25 -36 14 -14 25 -28 25 -32 0 -16 212 -222 228 -222 5 0 17 -8 28 -18 10
  -10 34 -25 52 -32 17 -8 32 -18 32 -22 0 -5 10 -8 23 -8 13 0 27 -4 33 -9 19
  -19 294 -39 374 -29 30 5 98 14 151 21 94 13 215 14 274 2 105 -21 146 -46
  174 -105 26 -54 42 -50 39 10 -2 27 -7 50 -12 50 -5 0 -6 3 -3 6 4 4 -3 15
  -15 25 -11 11 -18 19 -14 19 4 0 -6 11 -22 25 -17 14 -35 25 -41 25 -6 0 -11
  5 -11 10 0 6 -9 10 -20 10 -11 0 -20 5 -20 10 0 6 -13 10 -29 10 -16 0 -31 4
  -33 9 -4 13 -368 26 -368 14 0 -15 -183 -16 -245 -3 -27 6 -70 15 -95 19 -54
  10 -80 20 -80 32 0 5 -9 9 -20 9 -11 0 -20 5 -20 10 0 6 -7 10 -16 10 -8 0
  -12 5 -9 10 3 6 -3 10 -14 10 -12 0 -21 5 -21 10 0 6 -7 10 -16 10 -8 0 -12 5
  -9 10 3 6 0 10 -7 10 -8 0 -20 9 -28 20 -8 11 -21 20 -29 20 -8 0 -11 3 -8 6
  4 4 -3 15 -16 26 -56 48 -107 103 -107 116 0 9 -3 13 -6 9 -7 -7 -34 17 -34
  31 0 6 -7 13 -16 16 -8 3 -12 10 -9 16 3 5 1 10 -5 10 -6 0 -29 18 -50 40 -21
  22 -44 40 -50 40 -6 0 -8 5 -5 10 4 6 -5 10 -20 10 -15 0 -24 4 -20 10 4 6 -5
  10 -20 10 -15 0 -24 4 -20 10 4 6 -9 10 -32 10 -28 0 -34 3 -23 10 11 7 1 10
  -35 11 -27 0 -61 1 -75 2 -14 1 -39 1 -57 -1z"
        />
        <path
          d="M5210 4100 c-8 -5 -18 -6 -22 -4 -4 3 -8 0 -8 -5 0 -6 -9 -11 -20
  -11 -11 0 -20 -4 -20 -10 0 -5 -8 -10 -18 -10 -10 0 -24 -9 -32 -20 -8 -11
  -19 -20 -25 -20 -18 0 -175 -145 -175 -162 0 -13 -141 -160 -148 -153 -2 2
  -14 -6 -27 -19 -12 -12 -36 -28 -54 -35 -17 -8 -31 -17 -31 -22 0 -5 -16 -9
  -35 -9 -19 0 -35 -5 -35 -11 0 -17 -209 3 -220 22 -3 4 -25 9 -48 10 -23 2
  -42 7 -42 13 0 5 -3 7 -6 3 -3 -3 -18 -1 -33 5 -28 11 -40 13 -96 22 -20 4
  -40 8 -44 10 -3 3 -27 7 -51 11 -25 3 -59 10 -76 14 -47 13 -231 14 -239 2 -3
  -6 -15 -11 -27 -11 -23 0 -71 -34 -61 -44 3 -3 2 -6 -4 -6 -12 0 -5 -49 10
  -67 10 -14 38 -18 36 -5 -6 38 1 42 71 42 49 0 70 -4 70 -12 0 -9 4 -8 11 2 8
  11 12 12 16 3 2 -8 22 -13 44 -13 21 0 39 -4 39 -10 0 -5 20 -10 45 -10 25 0
  45 -4 45 -10 0 -5 16 -10 35 -10 19 0 35 -4 35 -10 0 -5 14 -10 30 -10 17 0
  30 -4 30 -10 0 -5 16 -10 35 -10 19 0 35 -4 35 -10 0 -5 11 -10 25 -10 14 0
  25 -4 25 -10 0 -5 14 -10 30 -10 17 0 30 -5 30 -12 0 -9 4 -8 10 2 6 10 10 11
  10 3 0 -9 16 -13 45 -13 27 0 45 -5 45 -11 0 -8 26 -10 88 -5 112 8 112 8 112
  18 0 4 14 8 30 8 17 0 30 5 30 10 0 6 7 10 15 10 8 0 15 3 15 8 0 4 17 16 38
  27 43 23 188 166 197 195 4 11 10 20 15 20 5 0 11 8 14 18 3 9 16 29 28 44 13
  14 30 36 38 48 14 21 88 95 140 140 14 12 45 34 70 48 58 33 55 31 44 42 -14
  14 -68 20 -84 10z"
        />
        <path
          d="M1269 3924 c-2 -33 -2 -46 2 -124 1 -30 6 -61 12 -67 5 -7 7 -13 5
  -13 -5 0 9 -89 27 -175 8 -41 26 -106 35 -130 4 -11 16 -48 26 -82 11 -35 22
  -63 26 -63 5 0 8 -9 8 -19 0 -11 5 -23 10 -26 6 -3 10 -14 10 -24 0 -17 166
  -353 208 -421 12 -19 29 -48 37 -65 9 -16 33 -57 55 -90 21 -33 47 -76 57 -95
  9 -19 20 -37 24 -40 3 -3 16 -24 28 -47 11 -23 25 -45 31 -48 6 -3 19 -23 30
  -43 39 -71 62 -107 73 -117 7 -5 19 -23 27 -40 8 -16 17 -32 20 -35 3 -3 30
  -43 60 -90 30 -47 57 -87 60 -90 3 -3 50 -68 104 -144 l98 -139 -6 -101 c-10
  -187 -18 -271 -26 -276 -6 -4 -10 -49 -14 -167 -1 -13 -4 -23 -7 -23 -3 0 -6
  -6 -6 -12 -1 -43 -16 -163 -29 -233 -3 -16 -7 -50 -9 -75 -4 -41 -11 -86 -30
  -175 -4 -16 -19 -56 -33 -87 -22 -47 -33 -60 -62 -70 -19 -6 -45 -11 -57 -9
  -49 6 -8 -49 49 -66 34 -10 41 -8 80 17 40 26 78 63 78 76 0 3 9 19 21 35 22
  32 64 153 72 209 3 19 7 42 10 50 7 18 18 88 18 113 0 9 4 17 10 17 5 0 8 3 7
  8 -2 6 17 140 27 194 3 12 7 49 10 82 4 33 8 63 11 68 5 8 12 58 18 134 2 22
  8 49 14 60 12 21 6 27 122 -105 160 -183 293 -315 304 -304 3 4 6 2 6 -4 0 -6
  14 -25 30 -43 17 -18 30 -29 30 -26 0 4 6 2 13 -3 21 -18 101 -75 130 -94 21
  -14 27 -25 27 -52 0 -19 -4 -35 -10 -35 -5 0 -10 -5 -10 -11 0 -7 -8 -19 -18
  -28 -22 -19 -52 -92 -52 -125 0 -28 -24 -78 -33 -69 -4 3 -7 -1 -7 -9 0 -9 9
  -18 20 -21 11 -3 20 -1 20 5 0 5 5 6 11 3 15 -10 48 29 55 65 4 16 10 30 14
  30 4 0 11 11 14 25 4 14 11 25 17 25 16 0 69 61 85 99 31 74 10 138 -70 210
  -16 14 -35 32 -42 38 -7 7 -16 13 -19 13 -4 0 -20 14 -35 30 -15 17 -31 30
  -34 30 -6 0 -124 118 -196 196 -41 44 -86 98 -175 209 -16 21 -38 47 -48 59
  -42 49 -53 63 -81 101 -17 22 -34 43 -39 46 -5 4 -10 150 -11 325 -2 176 -5
  330 -8 344 -4 14 -9 59 -12 100 -4 41 -9 89 -12 105 -3 17 -10 53 -15 80 -5
  28 -12 64 -15 80 -8 43 -53 206 -63 230 -5 11 -17 38 -26 60 -19 44 -71 151
  -85 175 -41 72 -86 260 -94 395 -6 108 -7 112 -16 60 -8 -44 -2 -283 7 -298 3
  -5 7 -26 9 -46 7 -57 23 -109 69 -216 24 -55 47 -113 51 -130 4 -16 9 -34 11
  -40 1 -5 7 -30 12 -55 4 -25 11 -52 14 -60 23 -57 38 -250 43 -531 4 -274 -1
  -339 -29 -339 -7 0 -27 21 -44 48 -18 26 -44 60 -58 76 -14 16 -25 32 -25 36
  0 3 -8 15 -18 26 -21 22 -81 106 -151 210 -26 39 -53 77 -59 85 -30 37 -110
  148 -132 184 -13 22 -28 45 -33 50 -5 6 -40 57 -77 115 -38 58 -74 110 -79
  117 -6 7 -28 43 -48 80 -20 37 -42 73 -48 79 -23 24 -178 355 -178 381 0 7 -5
  13 -10 13 -6 0 -8 3 -4 6 3 3 -3 26 -13 50 -11 24 -17 44 -14 44 2 0 0 9 -6
  20 -10 19 -44 142 -55 200 -3 14 -8 57 -11 95 -7 76 -13 96 -15 49z"
        />
        <path
          d="M5091 3538 c5 -18 9 -45 9 -60 0 -14 5 -28 11 -30 5 -2 13 -36 16
  -76 5 -65 4 -74 -16 -91 -11 -10 -21 -22 -21 -27 -1 -10 -66 -54 -81 -54 -6 0
  -7 -4 -4 -10 3 -5 0 -10 -8 -10 -17 0 -117 -108 -117 -126 0 -4 -9 -15 -21
  -25 -11 -9 -20 -20 -20 -23 3 -24 -1 -36 -9 -31 -6 4 -10 -5 -10 -19 0 -14 -4
  -26 -10 -26 -5 0 -10 -15 -10 -32 0 -25 -3 -29 -10 -18 -7 11 -10 5 -10 -22 0
  -21 -4 -38 -10 -38 -6 0 -9 -3 -9 -7 5 -29 -3 -73 -11 -68 -6 4 -10 -10 -10
  -34 0 -23 -4 -41 -9 -41 -7 0 -26 -137 -25 -182 0 -10 -4 -18 -9 -18 -9 0 -2
  -653 7 -669 3 -4 6 -35 7 -69 1 -34 5 -62 9 -62 4 0 10 -37 14 -82 3 -46 9
  -94 13 -108 10 -41 15 -150 6 -150 -4 0 -27 -17 -50 -38 -23 -21 -58 -50 -77
  -63 -20 -13 -36 -28 -36 -32 0 -12 -22 -32 -42 -40 -10 -3 -18 -11 -18 -16 0
  -5 -9 -12 -19 -16 -16 -5 -49 -27 -106 -73 -5 -4 -28 -19 -50 -32 -22 -13 -45
  -29 -51 -35 -52 -51 -50 -160 7 -267 5 -9 11 -31 14 -48 7 -39 44 -120 55
  -120 5 0 16 -7 25 -17 14 -14 18 -14 26 -2 12 19 11 25 -5 42 -16 16 -35 78
  -42 140 -3 22 -11 54 -17 71 -12 29 -13 41 -9 104 1 12 7 22 14 22 6 0 46 23
  89 50 43 28 82 50 87 50 6 0 13 7 16 16 4 9 9 14 12 11 4 -3 12 -1 19 6 7 7
  23 19 35 27 12 8 28 20 34 26 6 5 19 13 29 17 9 3 17 11 17 17 0 5 6 10 13 10
  7 0 19 9 27 20 8 11 24 20 35 20 22 0 37 -40 65 -170 7 -30 16 -65 21 -77 4
  -12 11 -45 15 -73 4 -27 10 -50 14 -50 4 0 10 -12 13 -27 10 -52 13 -62 20
  -69 4 -4 7 -18 7 -31 0 -13 4 -31 9 -41 15 -31 41 -119 41 -141 0 -11 3 -21 8
  -21 7 0 27 -51 39 -97 7 -31 -40 -109 -80 -130 -18 -9 -35 -16 -39 -15 -5 1
  -8 -3 -8 -9 0 -5 -4 -7 -10 -4 -5 3 -10 0 -10 -8 0 -11 12 -14 53 -12 67 3 59
  1 98 35 19 16 37 30 40 30 10 0 33 61 32 82 -1 10 2 18 6 18 4 0 8 7 8 15 0 8
  -3 15 -6 15 -4 0 -8 11 -9 26 -1 14 -6 33 -11 42 -5 9 -12 40 -16 67 -8 61
  -23 123 -35 150 -8 17 -51 198 -65 275 -3 14 -8 36 -12 50 -3 14 -6 28 -5 33
  1 4 -2 7 -8 7 -5 0 -10 15 -10 33 0 17 -5 50 -10 72 -5 22 -12 65 -16 95 l-6
  55 49 45 c55 53 185 198 225 252 39 53 74 94 90 106 10 7 18 20 18 28 0 8 5
  12 10 9 6 -3 10 1 10 9 0 9 5 16 10 16 6 0 10 9 10 20 0 18 17 57 30 70 16 16
  54 77 81 132 18 35 37 66 43 70 6 4 7 8 3 8 -4 0 6 26 23 58 16 31 30 62 30
  69 0 6 3 13 8 15 6 3 69 155 75 183 2 6 5 15 8 20 18 30 88 273 105 365 2 14
  9 45 14 70 5 25 12 65 14 90 3 25 7 59 10 75 3 17 8 66 11 110 4 79 12 153 19
  187 2 9 -2 19 -10 22 -8 3 -15 -1 -15 -9 -2 -17 -1 -16 -17 -70 -6 -22 -12
  -65 -13 -95 -1 -30 -5 -59 -8 -65 -3 -5 -6 -19 -7 -30 -2 -33 -15 -109 -20
  -116 -2 -4 -6 -24 -10 -45 -3 -22 -10 -43 -15 -48 -5 -6 -9 -24 -9 -41 0 -17
  -4 -35 -9 -40 -5 -6 -11 -21 -13 -35 -1 -14 -6 -27 -9 -30 -4 -3 -9 -18 -10
  -35 -2 -16 -7 -33 -12 -36 -4 -3 -10 -18 -13 -34 -3 -15 -12 -44 -21 -64 -9
  -20 -17 -38 -17 -41 -3 -14 -31 -76 -36 -80 -3 -3 -12 -24 -20 -47 -7 -24 -17
  -43 -22 -43 -4 0 -8 -9 -8 -20 0 -11 -4 -20 -10 -20 -5 0 -10 -9 -10 -20 0
  -11 -4 -20 -10 -20 -5 0 -10 -7 -10 -15 0 -14 -18 -53 -30 -65 -3 -3 -11 -17
  -19 -32 -8 -16 -18 -28 -23 -28 -4 0 -8 -9 -8 -20 0 -11 -4 -20 -8 -20 -5 0
  -14 -16 -22 -35 -8 -19 -17 -35 -22 -35 -4 0 -8 -7 -8 -15 0 -8 -4 -15 -10
  -15 -5 0 -10 -7 -10 -15 0 -8 -4 -15 -10 -15 -5 0 -10 -7 -10 -15 0 -8 -4 -15
  -10 -15 -5 0 -10 -5 -10 -10 0 -6 -13 -24 -30 -42 -16 -17 -30 -35 -30 -40 0
  -5 -6 -15 -12 -23 -7 -7 -29 -32 -47 -55 -19 -23 -41 -48 -48 -56 -7 -7 -13
  -15 -13 -18 0 -2 -29 -34 -65 -71 -36 -36 -65 -70 -65 -76 0 -6 -4 -7 -10 -4
  -5 3 -10 1 -10 -5 0 -20 -24 -38 -35 -26 -13 14 -32 94 -40 165 -4 30 -9 63
  -11 75 -2 12 -9 55 -14 96 -6 41 -15 111 -21 155 -14 104 -20 502 -9 580 5 33
  12 83 15 110 4 28 13 75 22 105 8 30 17 67 20 83 3 15 9 27 14 27 5 0 9 9 9
  21 0 40 79 172 131 219 60 53 118 110 139 136 18 22 19 156 1 162 -6 2 -11 12
  -11 22 0 10 -5 22 -11 26 -6 3 -9 15 -6 25 3 10 1 19 -4 21 -5 2 -10 15 -11
  31 -1 15 -8 27 -14 27 -9 0 -10 -9 -3 -32z"
        />
      </g>
    </svg>
  );
}

// let cnt = 0;
// let group = new Date().getTime();

const forceLog = (item, data) => {
  // if (typeof data !== "string") {
  //   data = JSON.stringify(data);
  // }
  // console.log("ForceLog",item)
  // fetch(serverURL + "/gapi/api.php/forcelog", {
  //   method: "POST",
  //   headers: {
  //     "Accept": "application/json, text/plain, */*",
  //     "Content-Type": "application/json",
  //   },
  //   body: JSON.stringify({item: item, data:data, cnt:++cnt, group:group}),
  // })
};

const RoleVariant = (role) => {
  if (role === 0) return "dancer";
  if (role === 5) return "teacher";
  if (role === 6) return "dj";
  if (role === 7) return "event";
  if (role === 8) return "venue";
  if (role === 9) return "supplier";
  return "Dancer";
};
const RoleLevel = (role) => {
  if (role === 0) return "Dancer";
  if (role === 5) return "Teacher";
  if (role === 6) return "DJ";
  if (role === 7) return "Event";
  if (role === 8) return "Venue";
  if (role === 9) return "Supplier";
  return "Dancer";
};
const RoleColor = (role) => {
  if (role === 5 || role === "teacher" || role === "blue")
    return "rgb(46,117,182)";
  if (role === 6 || role === "dj" || role === "gold" || role === "yellow")
    return "rgb(255,207,0)";
  if (role === 7 || role === "event" || role === "green")
    return "rgb(0, 153, 132)";
  if (role === 8 || role === "venue" || role === "red")
    return "rgb(233, 64, 87)";
  if (role === 9 || role === "supplier" || role === "purple")
    return "rgb(142,0,125)";
  if (role === "pink") return "rgb(236, 0, 140)";
  if (role === "orange") return "rgb(233, 64, 87)";
  if (role === 10) return "red";
  return "purple";
};
const Color = (color) => {
  return RoleColor(color);
};
const RoleTextColor = (role) => {
  if (role === 5) return "white";
  if (role === 6) return "black";
  if (role === 7) return "black";
  if (role === 8) return "black";
  if (role === 9) return "black";
  if (role === 10) return "white";
  return "white";
};

const Clickable = ({ children, onClick, style, className }) => {
  return (
    <span
      className={className}
      style={{ cursor: "pointer", ...style }}
      onClick={onClick}
    >
      {children}
    </span>
  );
};

function isLocalHost(url) {
  // console.log("URL",url)
  return url.indexOf("localhost") !== -1 || url.indexOf("127.0.0.1") !== -1;
}

const copyToClipboard = (str) => {
  const el = document.createElement("textarea");
  el.value = str;
  document.body.appendChild(el);
  el.select();
  document.execCommand("copy");
  document.body.removeChild(el);
};

const calculateAgeInDays = (startDateStr, endDateStr = new Date()) => {
  // Parse the date strings into Date objects
  const startDate = new Date(startDateStr);
  const endDate = new Date(endDateStr);

  // Calculate the time difference in milliseconds
  const timeDifference = endDate - startDate;

  // Convert milliseconds to days and round down
  const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

  return daysDifference;
};

const createLink = (baseUrl, pathOrFullUrl) => {
  // Remove any trailing slashes from the base URL
  baseUrl = baseUrl.replace(/\/+$/, "");

  // Check if the second variable is a full URL
  if (
    pathOrFullUrl.startsWith("http://") ||
    pathOrFullUrl.startsWith("https://")
  ) {
    return pathOrFullUrl;
  }

  // Remove leading slashes from the path
  pathOrFullUrl = pathOrFullUrl.replace(/^\/+/, "");

  // Combine the base URL and path
  return baseUrl + "/" + pathOrFullUrl;
};

const FormatFreeText = ({ text }) => {
  if (!text) return "";
  text = text.replace(/\\'/g, "'");
  return (
    <>
      {text.split("\\n").map((item, idx) => {
        return (
          <span key={idx}>
            {item}
            <br />
          </span>
        );
      })}
    </>
  );
};



export {
  forceLog,
  JuztDance,
  redirectWithParams,
  Shoe2,
  Dance2,
  CopyToClipboard,
  copyToClipboard,
  serverURL,
  imageURL,
  landingURL,
  rootURL,
  localURL,
  LogError,
  RoleLevel,
  RoleColor,
  RoleVariant,
  Color,
  RoleTextColor,
  Clickable,
  isLocalHost,
  calculateAgeInDays,
  createLink,
  FormatFreeText
};
