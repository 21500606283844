import React from "react";
import { Button } from "react-bootstrap";
import "./pullouttab.css";

const PullOutTab = ({ Icon, style, variant, className, onClick }) => {
  return (
    <Button
      variant={variant ?? "secondary"}
      className={"pullouttab" + (className ? " " + className : "")}
      style={style}
      onClick={onClick}
    >
      {Icon}
    </Button>
  );
};

export default PullOutTab;
