import { useState, useEffect, useContext } from "react";
import { Modal, Button, Form, Row, Col, Alert } from "react-bootstrap";
import { redirectWithParams } from "../components/utils";
import { AuthenticationContext } from "../context/authentication";

function Register(props) {
  let refer = props.refer;
  const [validated, setValidated] = useState(false);
  const [first, setFirst] = useState("");
  const [last, setLast] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);
  const [confirm, setConfirm] = useState("");
  const [promo, setPromo] = useState("");
  const [regType, setRegType] = useState("");

  const { registration } = useContext(AuthenticationContext);

  if (!refer) {
    refer = { refer: "", t: undefined };
  }
  const refertype = refer ? refer.t || 0 : 0;

  useEffect(() => {
    setError(false);
  }, [email, password, first, last, confirm, regType]);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    if (form.checkValidity()) {
      // console.log("Do Registration")
      if (password !== confirm) {
        setError({ message: "Password and Confirm Password must match" });
        return "Error";
      }

      registration({
        email: email,
        password: password,
        confirm: confirm,
        first: first,
        last: last,
        refer: refer.refer,
        rtype: refertype,
        accountlevel: regType,
        promo: promo
      })
        .then(() => {
          if (refertype === "10") {
            window.location.hash = "view?id=" + refer.refer;
          } else {
            window.location.hash = "";
          }
        })
        .catch((err) => {
          console.error("ERROR", err);
          setError(err);
        });
    }

    setValidated(true);
  };

  const handleClose = () => {
    window.location.hash = "";
  };

  return (
    <Modal
      id="registrationForm"
      show={true}
      onHide={handleClose}
      data-tut="tourRegisterForm"
    >
      <Modal.Header closeButton closeVariant="white">
        <Modal.Title>Registration</Modal.Title>
        {/* <PatchQuestionFill
          id="registrationhelp"
          style={{
            width: "1.5em",
            height: "1.5em",
            marginLeft: "1em",
            float: "right",
            position: "absolute",
            right: "50px",
            cursor: "pointer",
          }}
          onClick={() => openTour()}
        /> */}
      </Modal.Header>

      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Modal.Body>
          <Row className="mb-3">
            <Form.Group as={Col} xs={12} data-tut="tourRegisterEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                required
                id="registerFormEmail"
                type="email"
                placeholder="Enter email"
                value={email}
                autoComplete="email"
                onChange={(ev) => {
                  setEmail(ev.target.value);
                }}
              />
              <Form.Text className="text-muted">
                We'll never share your email with anyone else.
              </Form.Text>
              <Form.Control.Feedback type="invalid">
                Please enter your email address.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="6" data-tut="tourRegisterFirstName">
              <Form.Label>First name</Form.Label>
              <Form.Control
                id="registerFormFirstName"
                required
                type="text"
                placeholder="First name"
                value={first}
                onChange={(ev) => {
                  setFirst(ev.target.value);
                }}
              />
              {/* <Form.Control.Feedback>Looks good!</Form.Control.Feedback> */}
              <Form.Control.Feedback type="invalid">
                First name is required.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="6" data-tut="tourRegisterLastName">
              <Form.Label>Last name</Form.Label>
              <Form.Control
                required
                id="registerFormLastName"
                type="text"
                placeholder="Last name"
                value={last}
                onChange={(ev) => {
                  setLast(ev.target.value);
                }}
              />
              {/* <Form.Control.Feedback>Looks good!</Form.Control.Feedback> */}
              <Form.Control.Feedback type="invalid">
                Last name is required.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" data-tut="tourRegisterPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                id="registerFormPassword"
                required
                placeholder="Password"
                 autoComplete="new-password"
                value={password}
                onChange={(ev) => {
                  setPassword(ev.target.value);
                }}
              />
              <Form.Control.Feedback type="invalid">
                Password is required.
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3" data-tut="tourRegisterConfirm">
              <Form.Label>Confirm Password</Form.Label>
              <Form.Control
                required
                id="registerFormConfirm"
                type="password" 
                autoComplete="confirm-password"
                placeholder="Confirm Password"
                value={confirm}
                onChange={(ev) => {
                  setConfirm(ev.target.value);
                }}
              />
              <Form.Control.Feedback type="invalid">
                Confirmation Password is required.
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Promo Code</Form.Label>
              <Form.Control
                id="registerFormPromo"
                type="text"
                placeholder="Promo Code"
                value={promo}
                onChange={(ev) => {
                  setPromo(ev.target.value);
                }}
              />
            </Form.Group>

            <Form.Group
              className="mb-3"
              data-tut="tourRegisterRegistrationType"
            >
              <Form.Label>Registration Type</Form.Label>
              <Form.Select
                id="registerFormRegistrationType"
                required
                aria-label="Default select example"
                value={regType}
                onChange={(ev) => {
                  setRegType(ev.target.value);
                }}
              >
                <option value="0">Dancer</option>
                <option value="5">Teacher</option>
                <option value="6">DJ</option>
                <option value="7">Event</option>
                <option value="8">Venue</option>
                <option value="9">Supplier</option>
              </Form.Select>
            </Form.Group>
          </Row>
          <Row>
            {error ? (
              <Alert variant="danger">
                <Alert.Heading>{error.message}</Alert.Heading>
              </Alert>
            ) : null}
          </Row>
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="outline-secondary"
            id="registrationIAmAlreadyRegistered"
            onClick={() => {
              redirectWithParams("login", props);
            }}
            data-tut="tourRegisterBtnLogin"
          >
            I am already registered
          </Button>
          <Button
            id="registrationSubmit"
            type="submit"
            variant="primary"
            data-tut="tourRegisterBtnRegister"
          >
            Submit
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default Register;
